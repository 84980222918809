import PoliciesErrors from 'context/policies/PoliciesErrors'
import { PolicyItemPrimitives } from 'context/policies/PoliciesConfigurationEnum'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'

const id = PolicyAttributeEnum.Identity_Certificate

export const authoritySettingsMap = {
  'identity-certificate.authority.ms-standalone': [
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.ms-authority-server`,
      label: 'ms-authority-server',
      hint: 'ms-authority-server_hint',
      help: 'ms-authority-server_help',
      errors: PoliciesErrors.errorsNotSelected
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.ms-authority-name`,
      label: 'ms-authority-name',
      hint: 'ms-authority-name_hint',
      help: 'ms-authority-name_help',
      errors: PoliciesErrors.errorsNotSelected
    }
  ],
  'identity-certificate.authority.ms-enterprise': [
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.ms-authority-server`,
      label: 'ms-authority-server',
      hint: 'ms-authority-server_hint',
      help: 'ms-authority-server_help',
      errors: PoliciesErrors.errorsNotSelected
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.ms-authority-name`,
      label: 'ms-authority-name',
      hint: 'ms-authority-name_hint',
      help: 'ms-authority-name_help',
      errors: PoliciesErrors.errorsNotSelected
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.ms-template-name`,
      label: 'ms-template-name',
      hint: 'ms-template-name_hint',
      help: 'ms-template-name_help',
      errors: PoliciesErrors.errorsNotSelected
    }
  ],
  'identity-certificate.authority.est-connector': [
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.est-url`,
      label: 'est-url',
      hint: 'est-url_hint',
      errors: PoliciesErrors.errorsNotSelected
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.est-port`,
      label: 'est-port',
      hint: 'est-port_hint',
      errors: PoliciesErrors.errorsPortNumber
    },
    {
      type: PolicyItemPrimitives.Toggle,
      attribute: `${id}.est-use-credentials`,
      label: 'est-use-credentials',
      help: 'est-use-credentials_help',
      indent: 1,
      update: (value, enableCallback) => {
        enableCallback([`${id}.est-user`, `${id}.est-password`], value)
        enableCallback([`${id}.est-serial-number`], !value)
      }
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.est-serial-number`,
      label: 'est-serial-number',
      hint: 'est-serial-number_hint',
      required: false,
      indent: 2
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.est-user`,
      label: 'est-user',
      hint: 'est-user_hint',
      errors: PoliciesErrors.errorsNotSelected,
      indent: 2
    },
    {
      type: PolicyItemPrimitives.Password,
      attribute: `${id}.est-password`,
      label: 'est-password',
      hint: 'est-password_hint',
      confirmationHint: 'est-password-confirm_hint',
      confirmationLabel: 'est-password-confirm',
      errors: PoliciesErrors.passwordLength(1, 8),
      indent: 2
    }
  ],
  'identity-certificate.authority.opentrust': [
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.opentrust-subject`,
      label: 'opentrust-subject',
      hint: 'opentrust-subject_hint',
      errors: PoliciesErrors.errorsNotSelected
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.opentrust-profile`,
      label: 'opentrust-profile',
      hint: 'opentrust-profile_hint',
      errors: PoliciesErrors.errorsNotSelected
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.opentrust-endpoint`,
      label: 'opentrust-endpoint',
      hint: 'opentrust-endpoint_hint',
      errors: PoliciesErrors.errorsNotSelected
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.opentrust-email`,
      label: 'opentrust-email',
      hint: 'opentrust-email_hint',
      errors: PoliciesErrors.errorsNotSelected
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.opentrust-zone`,
      label: 'opentrust-zone',
      hint: 'opentrust-zone_hint',
      errors: PoliciesErrors.errorsNotSelected
    }
  ],
  'identity-certificate.authority.scep-connector': [
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.scep-url`,
      label: 'scep-url',
      hint: 'scep-url_hint',
      errors: PoliciesErrors.errorsNotSelected
    },
    {
      type: PolicyItemPrimitives.Toggle,
      attribute: `${id}.scep-enable-challenge-password`,
      label: 'scep-enable-challenge-password',
      update: (value, enableCallback) => {
        enableCallback(
          [
            `${id}.scep-challenge-password-url`,
            `${id}.scep-user`,
            `${id}.scep-password`
          ],
          !value
        )
        enableCallback([`${id}.scep-challenge-password`], value)
      }
    },
    {
      type: PolicyItemPrimitives.Password,
      attribute: `${id}.scep-challenge-password`,
      label: 'scep-challenge-password',
      hint: 'scep-challenge-password_hint',
      confirmationHint: 'scep-challenge-password-confirm_hint',
      confirmationLabel: 'scep-challenge-password-confirm',
      errors: PoliciesErrors.passwordLength(1, 8),
      indent: 1
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.scep-challenge-password-url`,
      label: 'scep-challenge-password-url',
      hint: 'scep-challenge-password-url_hint',
      errors: PoliciesErrors.errorsNotSelected,
      indent: 1
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.scep-user`,
      label: 'scep-user',
      hint: 'scep-user_hint',
      errors: PoliciesErrors.errorsNotSelected,
      indent: 1
    },
    {
      type: PolicyItemPrimitives.Password,
      attribute: `${id}.scep-password`,
      label: 'scep-password',
      hint: 'scep-password_hint',
      confirmationHint: 'scep-password-confirm_hint',
      confirmationLabel: 'scep-password-confirm',
      errors: PoliciesErrors.passwordLength(1, 8),
      indent: 1
    }
  ],
  'identity-certificate.authority.digicert': [
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.digicert-subject`,
      label: 'digicert-subject',
      hint: 'digicert-subject_hint',
      errors: PoliciesErrors.errorsNotSelected
    },
    {
      type: PolicyItemPrimitives.Textbox,
      attribute: `${id}.digicert-profile`,
      label: 'digicert-profile',
      hint: 'digicert-profile_hint',
      help: 'digicert-profile_help',
      errors: PoliciesErrors.errorsNotSelected
    }
  ]
}

import React, { useContext, useEffect, useState, memo } from 'react'
import configContext from 'context/config/configContext'
import settingsContext from 'context/settings/settingsContext'
import { Checkbox } from '@veneer/core'
import 'styles/global.scss'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { ToggleDescription } from 'context/policies/PoliciesConfigurationEnum'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import WarningMessage from './WarningMessage'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'
import { FlexRow } from 'styles/styles'

const ToggleControl = (props) => {
  const description: ToggleDescription = props.description
  const {
    id,
    attributes,
    data: { deviceSettings }
  } = props
  const { attribute, help, disabledHelp } = description

  const { t } = useContext(configContext)
  const { isEnabled, addDisabled, removeDisabled, addWarning, removeWarning } =
    useContext(settingsContext)

  const getLocalized = (key: string): string => {
    return t(`${props.localizationPath}.${key}`)
  }

  const enabled = isEnabled(attribute)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [warning, setWarning] = useState(
    PoliciesHelper.getWarning(description, value)
  )

  useEffect(() => {
    setValue(PoliciesHelper.getData(description, deviceSettings))
  }, [deviceSettings])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const val = PoliciesHelper.getData(description, deviceSettings)
    if (val === value) {
      const warn = PoliciesHelper.getWarning(description, value)
      setWarning(warn)
      warn?.type == MessageTypesEnum.WARNING
        ? addWarning(id, attribute)
        : removeWarning(id, attribute)

      if (attributes) {
        PoliciesHelper.update(
          description,
          value && enabled,
          (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
          props.onSettingsChanges,
          attributes
        )
      }
    }
  }, [value, enabled, attributes])

  const onChange = (val) => {
    setValue(val)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, val)
    props.onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const disHelp = !enabled && !!disabledHelp
  return (
    <>
      <FlexRow className={'alignCenter'}>
        <Checkbox
          id={attribute}
          label={getLocalized(description.label)}
          disabled={!enabled}
          checked={value}
          onChange={({ target }) => onChange(target.checked)}
        />
        <FlexRow className={'paddingLeft4 lineBreak'}>
          {(help || disHelp) && (
            <HelpButton
              enabled={enabled || disHelp}
              description={getLocalized(disHelp ? disabledHelp : help)}
            />
          )}
        </FlexRow>
      </FlexRow>
      {warning && (
        <WarningMessage
          id={attribute + '.warning'}
          message={getLocalized(warning.message)}
          type={warning.type}
        />
      )}
    </>
  )
}

export default memo(ToggleControl)

import MocDocAll from './MocDocAll'
import MocUtils, { MOC } from './MocUtils'

class MocParser {
  constructor(xml, language = null, frames = null) {
    //Save xmlns attribute
    const middles = MocUtils.extractMiddles(xml, ' ' + MOC.XMLNS + '="', '"')

    //Remove xmlns attribute
    xml = xml.replace(/\sxmlns=".*?"/, '') //to allow non-namespace XPath syntax

    //Construct appropriate XML parser
    this.xmlDoc = new MocDocAll(xml)

    //Restore xmlns attribute
    if (this.xmlDoc.xmlDoc.documentElement && middles.length) {
      this.xmlDoc.xmlDoc.documentElement.setAttribute(MOC.XMLNS, middles[0])
    }

    if (language && frames) {
      this.getFrames(language, frames)
    } else {
      this.config = {}
    }
  }

  //Return XML
  getMoc() {
    return this.xmlDoc.getXml()
  }

  //Return JSON: Frames/Fidgets/Sets with all Items to construct UI
  getFrames(language, frames = null) {
    this.config = {}
    this.hasErrors = false

    if (frames === null) {
      frames = []
    }

    const errors = []

    //Frames
    const resFrames = this.xmlDoc.evaluate(MOC.FRAME_FULL_PATH + '/@id')
    if (resFrames) {
      for (let attr of resFrames) {
        let frame = {}
        let xPathFrame = MOC.FRAME_FULL_PATH + "[@id='" + attr.nodeValue + "']"
        frame[MOC.ID] = attr.nodeValue //frame id
        frame[MOC.LABEL] = this.getLocale(xPathFrame, language)

        //Fidgets
        let fidgets = []
        const resFidgets = this.xmlDoc.evaluate(
          xPathFrame + MOC.FIDGET_REL_PATH + '/@id'
        )
        if (resFidgets) {
          resFidgets.forEach((attr) => {
            let fidget = {}
            const fidgetId = attr.nodeValue
            fidget[MOC.ID] = fidgetId //fidget id

            let xPathFidget =
              xPathFrame + MOC.FIDGET_REL_PATH + "[@id='" + fidgetId + "']"
            fidget[MOC.LABEL] = this.getLocale(xPathFidget, language)

            //Read sets
            let sets = [],
              setsCfg = {},
              node
            let result = this.xmlDoc.evaluate(xPathFidget + MOC.SET_REL_PATH)
            if (result) {
              for (node of result) {
                const key = node.getAttribute(MOC.KEY)
                if (!key) {
                  continue
                }
                const xPathSet =
                  xPathFidget + MOC.SET_REL_PATH + "[@key='" + key + "']"
                let options = this.getOptions(
                  xPathSet + MOC.ITEM_REL_PATH,
                  language
                )

                //handle various set types
                let set = {},
                  setCfg = {},
                  values = []
                if (node.getAttribute(MOC.REQUIRED) === MOC.TRUE) {
                  setCfg[MOC.REQUIRED] = set[MOC.REQUIRED] = true
                }
                let type = node.getAttribute(MOC.TYPE)
                if (type === MOC.BOOL) {
                  //BOOL is checkbox
                  type = MOC.TYPES.CHECK
                  const res = this.xmlDoc.evaluate(
                    xPathSet + MOC.ITEM_PROP_REL_PATH + "[@key='selected']"
                  )
                  if (res && res.length > 0) {
                    values.push({
                      [MocUtils.parentNode2x(res[0]).getAttribute(MOC.KEY)]:
                        res[0].getAttribute(MOC.VALUE) === MOC.TRUE
                    })
                  }
                } else if (type === MOC.FILELIST) {
                  //FILELIST is FILELIST
                  type = MOC.TYPES.FILELIST
                  const res = this.xmlDoc.evaluate(
                    xPathSet + MOC.ITEM_PROP_REL_PATH + "[@key='value']"
                  )
                  if (res) {
                    res.forEach((node) => {
                      const value = node.getAttribute(MOC.VALUE)
                      if (value && value !== '') {
                        const key = MocUtils.parentNode2x(node).getAttribute(
                          MOC.KEY
                        )
                        options = options.filter(
                          (opt) => Object.keys(opt)[0] !== key
                        )
                        values.push({ [key]: value })
                      }
                    })
                  }
                } else if (type === MOC.STR && options.length > 1) {
                  //more than one item - dropdown
                  type = MOC.TYPES.LIST
                  const res = this.xmlDoc.evaluate(
                    xPathSet +
                      MOC.ITEM_PROP_REL_PATH +
                      "[@key='selected' and @value='true']"
                  )
                  if (res && res.length > 0) {
                    values.push({
                      [MocUtils.parentNode2x(res[0]).getAttribute(
                        MOC.KEY
                      )]: true
                    })
                  }
                } else {
                  //one item - input of some kind
                  switch (type) {
                    case MOC.INT:
                      type = MOC.TYPES.NUMBER
                      break
                    case MOC.PSTR:
                      type = MOC.TYPES.PASSWORD
                      break
                    default:
                      type = MOC.TYPES.INPUT
                      break
                  }
                  const res = this.xmlDoc.evaluate(
                    xPathSet + MOC.ITEM_PROP_REL_PATH + "[@key='value']"
                  )
                  if (res && res.length > 0) {
                    //read default value which could be locale-specific
                    const key = MocUtils.parentNode2x(res[0]).getAttribute(
                      MOC.KEY
                    )
                    const val = this.getLocale(
                      xPathSet +
                        MOC.ITEM_REL_PATH +
                        "[@key='" +
                        key +
                        "']" +
                        MOC.PROP_REL_PATH +
                        "[@key='value']",
                      language,
                      null
                    )
                    if (val !== null) {
                      res[0].setAttribute(MOC.VALUE, val) //update "value" attribute in XML (with locale-specific value)
                      values.push({ [key]: val })
                    }
                  }
                }

                setCfg[MOC.TYPE] = set[MOC.TYPE] = type
                set[MOC.NAME] = key
                set[MOC.LABEL] = this.getLocale(xPathSet, language)
                set[MOC.OPTIONS] = options
                sets.push(set) //collect sets

                //set's constraint
                const res = this.xmlDoc.evaluate(
                  xPathSet + MOC.PROP_REL_PATH + "[@type='XBOOL']"
                )
                if (res) {
                  res.forEach((node) => {
                    const k = node.getAttribute(MOC.KEY)
                    const v = node.getAttribute(MOC.VALUE)
                    if (k && v) {
                      setCfg[k] = v
                    }
                  })
                }
                setCfg[MOC.SAVED] =
                  setCfg[MOC.DEFAULTS] =
                  setCfg[MOC.VALUES] =
                    values
                setsCfg[key] = setCfg
              }
            }

            fidget[MOC.SETS] = sets //fidget's sets
            fidgets.push(fidget) //collect fidgets

            this.config[fidgetId] = setsCfg //fidget config

            //Errors
            result = this.xmlDoc.evaluate(
              xPathFidget + MOC.ERROR_REL_PATH + '/@id'
            )
            if (result) {
              result.forEach((attr) => {
                let error = {}
                let xPathErr =
                  xPathFidget +
                  MOC.ERROR_REL_PATH +
                  "[@id='" +
                  attr.nodeValue +
                  "']"
                error[MOC.LABEL] = this.getLocale(xPathErr, language)
                xPathErr += MOC.PROP_REL_PATH

                //expression
                let res = this.xmlDoc.evaluate(
                  xPathErr + "[@key='expression' and @type='XBOOL']/@value"
                )
                if (res && res.length > 0) {
                  error[MOC.EXPRESSION] = res[0].nodeValue

                  //ref items
                  res = this.xmlDoc.evaluate(
                    xPathErr +
                      "[@key='referenceditems' and @type='XPATH']/@value"
                  )
                  if (res && res.length > 0) {
                    res = this.xmlDoc.evaluate(res[0].nodeValue)
                    if (res) {
                      const refs = []
                      for (node of res) {
                        const s = node.getAttribute(MOC.KEY)
                        const f = MocUtils.parentNode2x(node).getAttribute(
                          MOC.ID
                        )
                        refs.push({ [MOC.FIDGET]: f, [MOC.SET]: s })
                      }
                      errors.push({
                        [MOC.ERROR]: error,
                        [MOC.REFERENCEDITEMS]: refs
                      }) //save errors
                    }
                  }
                }
              })
            }
          })
        }

        frame[MOC.FIDGETS] = fidgets
        frames.push(frame) //collect frames
      }
    }

    //assign saved errors
    errors.forEach((error) => {
      error[MOC.REFERENCEDITEMS].forEach((item) => {
        const { [MOC.FIDGET]: f, [MOC.SET]: s } = item
        if (
          Object.prototype.hasOwnProperty.call(this.config, f) &&
          Object.prototype.hasOwnProperty.call(this.config[f], s)
        ) {
          let set = this.config[f][s]
          if (!Object.prototype.hasOwnProperty.call(set, MOC.ISSUES)) {
            set[MOC.ISSUES] = []
          }
          set[MOC.ISSUES].push(error[MOC.ERROR])
        }
      })
    })

    return frames
  }

  //Calculate total FILELIST items size
  getFileListSizes() {
    let fileListSizes = 0
    for (const fidget in this.config) {
      for (const set in this.config[fidget]) {
        if (this.config[fidget][set][MOC.TYPE] === MOC.TYPES.FILELIST) {
          for (const value of this.config[fidget][set][MOC.VALUES]) {
            fileListSizes += MocUtils.getFileSize(Object.values(value)[0])
          }
        }
      }
    }
    return fileListSizes
  }

  //Return current config - JSON format for UI (incl. values, errors, editable and visible state)
  getConfig(disabled = false) {
    let config = this.getValues(MOC.VALUES)
    MocUtils.mergeObjects(config, this.getStates(disabled))
    return config
  }

  //Apply current config - JSON format from UI
  setConfig(config) {
    this.setValues(config)
    let changes = {}
    this.checkConstraints(changes)
    return changes
  }

  //Return current config - JSON format for backend (CFG)
  getLeanConfig() {
    let fidgets = []
    for (const f in this.config) {
      let sets = []
      for (const s in this.config[f]) {
        let set = {},
          items = []
        for (const value of this.config[f][s][MOC.VALUES]) {
          let val = Object.values(value)[0]
          // Booleans should be written as strings
          if (typeof val === 'boolean') {
            val = val ? MOC.TRUE : MOC.FALSE
          }
          items.push({ [MOC.KEY]: Object.keys(value)[0], [MOC.VALUE]: val })
        }
        set[MOC.KEY] = s
        set[MOC.ITEMS] = items
        sets.push(set)
      }

      if (sets.length > 0) {
        let fidget = {}
        fidget[MOC.ID] = f
        fidget[MOC.SETS] = sets
        fidgets.push(fidget)
      }
    }
    return { [MOC.FIDGETS]: fidgets }
  }

  //Apply current config - JSON format from backend (CFG)
  setLeanConfig(lean) {
    if (lean && Object.prototype.hasOwnProperty.call(lean, MOC.FIDGETS)) {
      let config = {}
      for (const fidget of lean[MOC.FIDGETS]) {
        if (
          Object.prototype.hasOwnProperty.call(fidget, MOC.ID) &&
          Object.prototype.hasOwnProperty.call(fidget, MOC.SETS)
        ) {
          let cfg = {}
          const id = fidget[MOC.ID]
          for (const set of fidget[MOC.SETS]) {
            if (
              Object.prototype.hasOwnProperty.call(set, MOC.KEY) &&
              Object.prototype.hasOwnProperty.call(set, MOC.ITEMS)
            ) {
              const key = set[MOC.KEY],
                items = set[MOC.ITEMS]
              if (
                Object.prototype.hasOwnProperty.call(this.config, id) &&
                Object.prototype.hasOwnProperty.call(this.config[id], key)
              ) {
                let values = []
                for (const item of items) {
                  if (
                    Object.prototype.hasOwnProperty.call(item, MOC.KEY) &&
                    Object.prototype.hasOwnProperty.call(item, MOC.VALUE)
                  ) {
                    const type = this.config[id][key][MOC.TYPE]
                    let value
                    //accept both boolean and string for check
                    if (type === MOC.TYPES.LIST) {
                      value = true
                    } else if (
                      type === MOC.TYPES.CHECK &&
                      typeof item[MOC.VALUE] === 'string'
                    ) {
                      value = item[MOC.VALUE] === MOC.TRUE
                    } else {
                      value = item[MOC.VALUE]
                    }
                    values.push({ [item[MOC.KEY]]: value })
                  }
                }
                cfg[key] = { [MOC.VALUES]: values }
              }
            }
          }
          if (Object.keys(cfg).length !== 0) {
            config[id] = cfg
          }
        }
      }
      if (Object.keys(config).length !== 0) {
        let changes = this.setValues(config)
        this.checkConstraints(changes)
        return changes
      }
    }
    return {}
  }

  //Reset config to defaults
  setDefaults() {
    const defaults = this.getValues(MOC.DEFAULTS)
    let changes = this.setValues(defaults)
    this.checkConstraints(changes)
    return changes
  }

  //Save config snapshot
  saveConfig() {
    for (const fidget in this.config) {
      for (const set in this.config[fidget]) {
        this.config[fidget][set][MOC.SAVED] =
          this.config[fidget][set][MOC.VALUES]
      }
    }
  }

  //Compare current config to default
  isDefault() {
    return this.compareValues(MOC.DEFAULTS)
  }

  //Compare current config to saved snapshot
  isSaved() {
    return this.compareValues(MOC.SAVED)
  }

  //Are there any errors
  hasIssues() {
    return this.hasErrors
  }

  //Store url (for needs of UI only)
  setFileUploadUrl(url) {
    this.fileUploadUrl = url
  }

  //Get url stored before (for needs of UI only)
  getFileUploadUrl() {
    return this.fileUploadUrl
  }

  //Helpers for getFrames()

  //Retrieve most appropriate localization string
  getLocale(xPath, language, notFoundValue = '') {
    let res = this.xmlDoc.evaluate(
      xPath + MOC.LOCALE_REL_PATH + "[@key='" + language + "']"
    ) //as specified exactly
    if (!res || res.length === 0) {
      res = this.xmlDoc.evaluate(
        xPath +
          MOC.LOCALE_REL_PATH +
          "[starts-with(@key,'" +
          language.substr(0, 2) +
          "')]"
      ) //ignore country
      if (!res || res.length === 0) {
        res = this.xmlDoc.evaluate(
          xPath + MOC.LOCALE_REL_PATH + "[@key='" + MOC.DEF_LANG + "']"
        ) //English
        if (!res || res.length === 0) {
          res = this.xmlDoc.evaluate(xPath)
          if (res && res.length > 0) {
            const str = res[0].getAttribute(MOC.VALUE) //value
            if (str !== null) {
              return str
            }
          }
          return notFoundValue //not found
        }
      }
    }
    return res[0].firstChild ? res[0].firstChild.nodeValue : '' //label
  }

  //Read items {key: loc string} array
  getOptions(xPath, language) {
    let options = [],
      node
    const resItems = this.xmlDoc.evaluate(xPath)
    if (resItems) {
      for (node of resItems) {
        let item = {}
        const key = node.getAttribute(MOC.KEY)
        item[key] = this.getLocale(xPath + "[@key='" + key + "']", language)
        options.push(item)
      }
    }
    return options
  }

  //Helper for getConfig()

  //Fill editable / visible / errors info for all sets
  getStates(disabled) {
    this.hasErrors = false
    let states = {}
    for (const fidget in this.config) {
      let state = {}
      for (const set in this.config[fidget]) {
        let values = this.config[fidget][set]

        //Editable
        let editable = false
        if (!disabled) {
          editable = !(
            Object.prototype.hasOwnProperty.call(values, MOC.EDITABLE) &&
            !this.xmlDoc.evaluateBool(values[MOC.EDITABLE])
          )
          values[MOC.LASTEDITABLE] = editable
        }

        //Visible
        let visible = !(
          Object.prototype.hasOwnProperty.call(values, MOC.VISIBLE) &&
          !this.xmlDoc.evaluateBool(values[MOC.VISIBLE])
        )
        values[MOC.LASTVISIBLE] = visible

        //Editable and Visible
        state[set] = { [MOC.EDITABLE]: editable, [MOC.VISIBLE]: visible }

        //Errors
        if (Object.prototype.hasOwnProperty.call(values, MOC.ISSUES)) {
          let errors = [],
            hash = 0
          if (!disabled && visible) {
            const errRes = this.getSetErrors(values[MOC.ISSUES])
            hash = errRes[MOC.LASTISSUES]
            if (hash !== 0) {
              errors = errRes[MOC.ISSUES]
              this.hasErrors = true
            }
          }
          values[MOC.LASTISSUES] = hash
          MocUtils.mergeObjects(state, { [set]: { [MOC.ISSUES]: errors } })
        }
      }
      states[fidget] = state
    }
    return states
  }

  //Common helpers

  //Compare (default or current) config with saved snapshot
  compareValues(what) {
    for (const fidget in this.config) {
      for (const set in this.config[fidget]) {
        if (
          !MocUtils.arraysEqual(
            this.config[fidget][set][what],
            this.config[fidget][set][MOC.VALUES]
          )
        ) {
          return false
        }
      }
    }
    return true
  }

  //Get (default or current) values from config
  getValues(what) {
    let config = {}
    for (const fidget in this.config) {
      let cfg = {}
      for (const set in this.config[fidget]) {
        let values = this.config[fidget][set]
        cfg[set] = { [MOC.VALUES]: values[what] }
      }
      config[fidget] = cfg
    }
    return config
  }

  //Evaluate errors
  getSetErrors(setErrors) {
    let errors = [],
      hash = 0
    for (const error of setErrors) {
      if (this.xmlDoc.evaluateBool(error[MOC.EXPRESSION])) {
        errors.push(error[MOC.LABEL])
        hash = MocUtils.hashCode(error[MOC.LABEL], hash)
      }
    }
    return { [MOC.ISSUES]: errors, [MOC.LASTISSUES]: hash }
  }

  //Store config changes and apply them to Moc XML DOM
  setValues(config) {
    let changes = {}
    for (let fidget in config) {
      const xPathFidget = MOC.FIDGET_FULL_PATH + "[@id='" + fidget + "']"
      for (const set in config[fidget]) {
        const values = config[fidget][set][MOC.VALUES]
        if (
          !MocUtils.arraysEqual(this.config[fidget][set][MOC.VALUES], values)
        ) {
          //Update XML
          let done = false
          const xPathSet =
            xPathFidget + MOC.SET_REL_PATH + "[@key='" + set + "']"
          switch (this.config[fidget][set][MOC.TYPE]) {
            case MOC.TYPES.CHECK:
              if (values.length) {
                const res = this.xmlDoc.evaluate(
                  xPathSet + MOC.ITEM_PROP_REL_PATH + "[@key='selected']"
                )
                if (
                  res &&
                  res.length &&
                  MocUtils.parentNode2x(res[0]).getAttribute(MOC.KEY) ===
                    Object.keys(values[0])[0]
                ) {
                  res[0].setAttribute(
                    MOC.VALUE,
                    Object.values(values[0])[0] ? MOC.TRUE : MOC.FALSE
                  )
                  done = true
                }
              }
              break
            case MOC.TYPES.LIST:
              if (values.length) {
                const res = this.xmlDoc.evaluate(
                  xPathSet + MOC.ITEM_PROP_REL_PATH + "[@key='selected']"
                )
                if (res) {
                  for (const node of res) {
                    const selected =
                      MocUtils.parentNode2x(node).getAttribute(MOC.KEY) ===
                      Object.keys(values[0])[0]
                    node.setAttribute(
                      MOC.VALUE,
                      selected ? MOC.TRUE : MOC.FALSE
                    )
                    done |= selected
                  }
                }
              }
              break
            case MOC.TYPES.FILELIST: {
              //remove existing
              let nodes = []
              let res = this.xmlDoc.evaluate(
                xPathSet +
                  MOC.ITEM_PROP_REL_PATH +
                  "[@key='value' and @value!='']"
              )
              if (res && res.length) {
                done = true
                for (const node of res) {
                  nodes.push(MocUtils.parentNode2x(node))
                }
                for (const node of nodes) {
                  node.parentNode.removeChild(node)
                }
              }
              //add new
              res = this.xmlDoc.evaluate(xPathSet + '/items')
              if (res && res.length) {
                done = true
                for (const value of values) {
                  let item = this.xmlDoc.xmlDoc.createElement(MOC.ITEM)
                  item.setAttribute(MOC.KEY, Object.keys(value)[0])
                  let props = this.xmlDoc.xmlDoc.createElement(MOC.PROPS)
                  let prop = this.xmlDoc.xmlDoc.createElement(MOC.PROP)
                  prop.setAttribute(MOC.KEY, MOC.VALUE)
                  prop.setAttribute(MOC.TYPE, MOC.STR)
                  prop.setAttribute(MOC.VALUE, Object.values(value)[0])
                  props.appendChild(prop)
                  item.appendChild(props)
                  res[0].appendChild(item)
                }
              }
              break
            }
            default:
              if (values.length) {
                const res = this.xmlDoc.evaluate(
                  xPathSet + MOC.ITEM_PROP_REL_PATH + "[@key='value']"
                )
                if (
                  res &&
                  res.length &&
                  MocUtils.parentNode2x(res[0]).getAttribute(MOC.KEY) ===
                    Object.keys(values[0])[0]
                ) {
                  res[0].setAttribute(MOC.VALUE, Object.values(values[0])[0])
                  done = true
                }
              }
              break
          }

          if (done) {
            this.config[fidget][set][MOC.VALUES] = values //Store new value
            MocUtils.mergeObjects(changes, {
              [fidget]: { [set]: { [MOC.VALUES]: values } }
            }) //Log changes
          }
        }
      }
    }
    return changes
  }

  //Process dependencies: make necessary updates and return the changes
  checkConstraints(changes) {
    //Update the values first
    let tries = 0,
      changed = false
    do {
      changed = false
      for (const fidget in this.config) {
        for (const set in this.config[fidget]) {
          let config = this.config[fidget][set]

          //Editable
          if (Object.prototype.hasOwnProperty.call(config, MOC.EDITABLE)) {
            const editable = !!this.xmlDoc.evaluateBool(config[MOC.EDITABLE])
            if (
              !editable &&
              !MocUtils.arraysEqual(config[MOC.VALUES], config[MOC.DEFAULTS])
            ) {
              //need to reset to default
              MocUtils.mergeObjects(
                changes,
                this.setValues({
                  [fidget]: { [set]: { [MOC.VALUES]: config[MOC.DEFAULTS] } }
                })
              )
              changed = true
            }

            if (
              !Object.prototype.hasOwnProperty.call(config, MOC.LASTEDITABLE) ||
              config[MOC.LASTEDITABLE] !== editable
            ) {
              //status changed
              config[MOC.LASTEDITABLE] = editable
              MocUtils.mergeObjects(changes, {
                [fidget]: { [set]: { [MOC.EDITABLE]: editable } }
              })
            }
          }
        }
      }
    } while (changed && tries++ < 10) //repeat several times if needed

    //Re-evaluate visibility and errors
    this.hasErrors = false
    for (const fidget in this.config) {
      for (const set in this.config[fidget]) {
        let config = this.config[fidget][set]

        //Visible
        let visible = true
        if (Object.prototype.hasOwnProperty.call(config, MOC.VISIBLE)) {
          visible = !!this.xmlDoc.evaluateBool(config[MOC.VISIBLE])
          if (
            !Object.prototype.hasOwnProperty.call(config, MOC.LASTVISIBLE) ||
            config[MOC.LASTVISIBLE] !== visible
          ) {
            //status changed
            config[MOC.LASTVISIBLE] = visible
            MocUtils.mergeObjects(changes, {
              [fidget]: { [set]: { [MOC.VISIBLE]: visible } }
            })
          }
        }

        //Errors
        if (Object.prototype.hasOwnProperty.call(config, MOC.ISSUES)) {
          let errors = [],
            hash = 0
          if (visible) {
            const errRes = this.getSetErrors(config[MOC.ISSUES])
            hash = errRes[MOC.LASTISSUES]
            if (hash !== 0) {
              errors = errRes[MOC.ISSUES]
              this.hasErrors = true
            }
          }
          if (
            !Object.prototype.hasOwnProperty.call(config, MOC.LASTISSUES) ||
            config[MOC.LASTISSUES] !== hash
          ) {
            //errors changed
            config[MOC.LASTISSUES] = hash
            MocUtils.mergeObjects(changes, {
              [fidget]: { [set]: { [MOC.ISSUES]: errors } }
            })
          }
        }
      }
    }
  }
}

export default MocParser

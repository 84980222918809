import { Item } from 'context/policies/PoliciesConfigurationEnum'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'

export default class QuickSetsItems {
  static startOptions: Item[] = [
    {
      value: `${PolicyAttributeEnum.Quick_Sets}.sets.start.enter`,
      label: 'start-enter'
    },
    {
      value: `${PolicyAttributeEnum.Quick_Sets}.sets.start.instantly`,
      label: 'start-instantly'
    }
  ]

  static locationOptions: Item[] = [
    {
      value: `${PolicyAttributeEnum.Quick_Sets}.sets.location.home`,
      label: 'location-home'
    },
    {
      value: `${PolicyAttributeEnum.Quick_Sets}.sets.location.folder`,
      label: 'location-folder'
    }
  ]
}

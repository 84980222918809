import { Item } from 'context/policies/PoliciesConfigurationEnum'

const stampPosition = (position) => {
  return { value: position, label: 'position-' + position }
}

const stampContent = (content) => {
  return { value: content, label: 'content-' + content }
}

const stampTextSize = (size) => {
  return { value: size, label: 'text-size-' + size }
}

const stampTextFont = (value, font) => {
  return { value, label: font }
}

export default class copyStampItems {
  static stampPositions: Item[] = [
    stampPosition('top-left'),
    stampPosition('top-center'),
    stampPosition('top-right'),
    stampPosition('bottom-left'),
    stampPosition('bottom-center'),
    stampPosition('bottom-right')
  ]
  static stampContents: Item[] = [
    stampContent('ip-address'),
    stampContent('product-info'),
    stampContent('user-name'),
    stampContent('date-time'),
    stampContent('page-number')
  ]

  static textFonts: Item[] = [
    stampTextFont('Letter Gothic', 'text-font-letter-gothic'),
    stampTextFont('Antique Olive', 'text-font-antique-olive'),
    stampTextFont(
      'New Century Schoolbook Roman',
      'text-font-new-century-schoolbook-roman'
    ),
    stampTextFont('Garamond Antiqua', 'text-font-garamond-antiqua')
  ]

  static textSizes: Item[] = [
    stampTextSize('8'),
    stampTextSize('12'),
    stampTextSize('20')
  ]
}

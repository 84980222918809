import { Item } from '../PoliciesConfigurationEnum'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

const mediaTray = (x, opt?) => {
  return {
    value: 'media-tray.' + x,
    label: 'policy.settings.media-trays.' + x,
    option: opt || x
  }
}

export default class MediaTrayItems {
  static allTrays: Item[] = [
    mediaTray('auto'), // copyTray, faxReceive
    mediaTray('alternate'),
    mediaTray('alternate-roll'),
    mediaTray('bottom'),
    mediaTray('by-pass-tray'),
    mediaTray('center'),
    mediaTray('disc'),
    mediaTray('duplexer'),
    mediaTray('envelope'), // trayAdmin
    mediaTray('flat-bed'),
    mediaTray('hagaki'),
    mediaTray('large-capacity'),
    mediaTray('left'),
    mediaTray('main'),
    mediaTray('main-roll'),
    mediaTray('manual'), // copyTray
    mediaTray('middle'),
    mediaTray('photo'),
    mediaTray('rear'),
    mediaTray('right'),
    mediaTray('roll'),
    mediaTray('roll-1'),
    mediaTray('roll-2'),
    mediaTray('roll-3'),
    mediaTray('roll-4'),
    mediaTray('roll-5'),
    mediaTray('roll-6'),
    mediaTray('roll-7'),
    mediaTray('roll-8'),
    mediaTray('roll-9'),
    mediaTray('roll-10'),
    mediaTray('roll-11'),
    mediaTray('side'),
    mediaTray('textile'),
    mediaTray('top'),
    mediaTray('tray-1'), // trayAdmin, copyTray, faxReceive
    mediaTray('tray-2'), // trayAdmin, copyTray, faxReceive
    mediaTray('tray-3'), // trayAdmin, copyTray, faxReceive
    mediaTray('tray-4'), // trayAdmin, copyTray, faxReceive
    mediaTray('tray-5'), // trayAdmin, copyTray, faxReceive
    mediaTray('tray-6'), // trayAdmin, faxReceive
    mediaTray('tray-7'), // faxReceive
    mediaTray('tray-8'), // faxReceive
    mediaTray('tray-9'), // faxReceive
    mediaTray('tray-10'), // faxReceive
    mediaTray('tray-11'), // faxReceive
    mediaTray('tray-12'), // faxReceive
    mediaTray('tray-13') // faxReceive
  ]

  static copyTrays = [
    MediaTrayItems.allTrays[0], // auto
    MediaTrayItems.allTrays[15], // manual
    ...MediaTrayItems.allTrays.slice(35, 40) // tray-1..5
  ]

  static faxReceiveTrayItems = [
    MediaTrayItems.allTrays[0], // auto
    ...MediaTrayItems.allTrays.slice(35) // tray-1..13
  ]

  static trayAdminTrays = [
    ...MediaTrayItems.allTrays.slice(35, 41), // tray-1..6
    MediaTrayItems.allTrays[8] // envelope
  ]

  static faxReceiveTrays = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? MediaTrayItems.faxReceiveTrayItems
      : constraints.filterSettingOptions(
          MediaTrayItems.faxReceiveTrayItems,
          ConstraintsResourceEnum.jobTicketCfgReceiveFax,
          ConstraintsSettingEnum.imageOutputMedia
        )
}

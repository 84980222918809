import devicePluginModelNumber from '@manageability/constraints/plugInNameToModelNumber.json'
import HPOfficeJetPro_MF_DI from '@manageability/constraints/HPOfficeJetPro_MF-DI.json'
import HPEnterprise_MF_EN from '@manageability/constraints/HPEnterprise_MF-EN.json'
import HPEnterprise_SF_EN from '@manageability/constraints/HPEnterprise_SF-EN.json'
import HPEnterprise_MF_EN_1 from '@manageability/constraints/HPEnterprise_MF-EN_1.json'
import HPEnterprise_SF_EN_1 from '@manageability/constraints/HPEnterprise_SF-EN_1.json'
import HPLaserJetPro_MF_DI from '@manageability/constraints/HPLaserJetPro_MF-DI.json'
import HPLaserJetPro_SF_DI from '@manageability/constraints/HPLaserJetPro_SF-DI.json'
import HPLaserJetPro_MF_DL from '@manageability/constraints/HPLaserJetPro_MF-DL.json'
import HPLaserJetPro_SF_DL from '@manageability/constraints/HPLaserJetPro_SF-DL.json'
import HP_DesignJet_MF_DI from '@manageability/constraints/HP_Unified_DesignJet_MF-DI.json'
import HP_DesignJet_SF_DI from '@manageability/constraints/HP_Unified_DesignJet_SF-DI.json'
import HP_PWP_OfficeJetPro_MF_DI from '@manageability/constraints/HP_Unified_PageWidePro_OfficeJetPro_MF-DI.json'
import HP_PWP_OfficeJetPro_SF_DI from '@manageability/constraints/HP_Unified_PageWidePro_OfficeJetPro_SF-DI.json'
import HPLaserJetPro_MF_DI_1 from '@manageability/constraints/HPLaserJetPro_MF-DI_1.json'
import HPLaserJetPro_SF_DI_1 from '@manageability/constraints/HPLaserJetPro_SF-DI_1.json'
import HP_LaserJet_MF_DI from '@manageability/constraints/HP_Unified_LaserJet_MF-DI.json'
import HP_LaserJet_SF_DI from '@manageability/constraints/HP_Unified_LaserJet_SF-DI.json'
import DefaultPolicyConstraints from '@manageability/constraints/policy.json'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from 'context/policies/PoliciesConfigurationEnum'

export interface ConstraintsSetting {
  readonly name: ConstraintsSettingEnum | string
  readonly excludeDeviceModelNumberList?: string[]
  readonly items?: { type; options?: { id; value }[] }
  readonly options?: { id; value }[]
  readonly maximum?: number
  readonly minimum?: number
}

export interface ConstraintsResource {
  readonly resource: ConstraintsResourceEnum | string
  readonly settings: ConstraintsSetting[]
}

//Constraints: resources
export enum ConstraintsResourceEnum {
  accountPolicy = 'accountPolicy',
  adapterConfigs = 'adapterConfigs',
  autoSendCfg = 'autosend_configuration',
  avatarRegistration = 'avatar_registration',
  bleCfg = 'ble_configuration',
  bootloaderPassword = 'bootloader_password',
  certificateCaCfg = 'certificate_CA_configuration',
  certificateIdCfg = 'certificate_ID_configuration',
  clockCfg = 'clock_configuration',
  controlPanelAccess = 'controlPanelAccess',
  controlPanelCfg = 'controlPanel_configuration',
  controlPanelHomeScreen = 'controlPanel_homeScreen',
  deviceAdminConfig = 'deviceAdminConfig',
  deviceAnnouncement = 'deviceAnnouncement',
  deviceCfg = 'device_configuration',
  discoveryServices = 'discoveryServices',
  dssConfig = 'dssConfig',
  ewsConfiguration = 'ews_configuration',
  // faxForward = 'faxForward',
  faxModemCfg = 'faxModem_configuration',
  faxReceiveCfg = 'faxReceive_configuration',
  faxSendCfg = 'faxSend_configuration',
  fleetProxy_legacyAttributes = 'fleetProxy_legacyAttributes',
  fwUpdateCfg = 'firmwareUpdate_configuration',
  fwLookupSupport = 'firmwareLookupSupport',
  jetDirectService = 'jetDirectService',
  jobTicketCfgDefCopy = 'jobTicketConfigurationDefaults_copy',
  jobTicketCfgDefEmail = 'jobTicketConfigurationDefaults_scanEmail',
  jobTicketCfgDefFax = 'jobTicketConfigurationDefaults_scanFax',
  jobTicketCfgReceiveFax = 'jobTicketConfigurationDefaults_receiveFax',
  jobTicketCfgDefNetworkFolder = 'jobTicketConfigurationDefaults_scanNetworkFolder',
  jobTicketCfgDefPrint = 'jobTicketConfigurationDefaults_print',
  jobTicketCreation = 'job_ticket_creation',
  ldapConfig = 'ldapConfig',
  mediaConfiguration = 'media_configuration',
  mediaHandlingCfg = 'mediaHandling_configuration',
  nameResolverServices = 'nameResolverServices',
  networkFolderCfg = 'networkFolderConfiguration',
  networkScanService = 'networkScanService',
  pdlCfg = 'pdl_configuration',
  powerCfg = 'power_configuration',
  printServices = 'printServices',
  printCfg = 'print_configuration',
  proxyCfg = 'proxy_config',
  rbacCfg = 'rbac_configuration',
  scanDestinationConfig = 'Scan_DestinationConfig',
  scanSupplies = 'scan_supplies',
  scanStampLocations = 'scanStampLocations',
  securityCapabilities = 'security_capabilities',
  serviceUserConfig = 'serviceUserConfig',
  sleepCfg = 'sleep_configuration',
  snmpConfig = 'snmpConfig',
  smtpServers = 'smtpServers',
  storageDevicesCfg = 'storageDevices_configuration',
  storeJobMgtCfg = 'storeJobManagement_configuration',
  supplyConfigPvt = 'supply_configPvt',
  systemConfig = 'systemConfig',
  systemStorage = 'systemStorage',
  tlsConfig = 'tlsConfig',
  usbHostCfg = 'usbHost_configuration',
  wifiDirectConfig = 'wifiDirectConfig',
  wirelessConfig = 'wirelessConfig',
  workPathSolutionMgrCfg = 'workPath_solutionManager_Configuration'
}

//Constraints: setting names
export enum ConstraintsSettingEnum {
  // accountPolicy
  accountLockoutEnabled = 'accountLockout.enabled',
  minPasswordLength = 'minPasswordLength',
  passwordComplexityEnabled = 'passwordComplexityEnabled',

  // adapterConfigs
  ipv4DhcpCompliance = 'eth.ipv4.address.dhcpFqdnRfc4702Compliance',
  ipv4Gateway = 'eth.ipv4.address.gateway', // + subnet, ip
  ipv4DnsPrimary = 'eth.ipv4.dnsServer.primary.address',
  ipv4MulticastEnabled = 'eth.ipv4.multicastEnabled',
  ipv4LinkConfig = 'eth.ethConfig.linkConfig',
  ipv6Enabled = 'eth.ipv6.enabled',
  configPrecedenceMethod = 'eth.ipConfigPrecedence.configPrecedenceMethod.method',
  usbEnabled = 'usb.enabled',
  ipv4ActiveConfigMethod = 'eth.ipv4.address.activeConfigMethod',
  ethDot1xAuthConfigBlockNetworkOnAuthFailure = 'eth.ethConfig.dot1xAuthConfig.blockNetworkOnAuthFailure',
  ethDot1xAuthConfigReauthenticate = 'eth.ethConfig.dot1xAuthConfig.reauthenticate',
  ethDot1xAuthConfigServerIdentity = 'eth.ethConfig.dot1xAuthConfig.serverIdentity',

  // autoSendCfg
  autoSendEnabled = 'autoSendEnabled',

  // avatarRegistration
  cloudServicesEnabled = 'cloudServicesEnabled',

  // bleCfg
  requestedBeaconingEnabled = 'requestedBeaconingEnabled',

  // bootloaderPassword
  currentPassword = 'currentPassword',

  // checkFirmware, ldapConfig, wifiDirectConfig
  enabled = 'enabled',

  // certificateCaCfg
  supportCaCertificate = 'supportCACertificate',

  // certificateIdCfg
  supportIdCertificate = 'supportIDCertificate',

  // clockCfg
  dateFormat = 'dateFormat', // + timeFormat
  systemTimeSync = 'systemTimeSync', // + more
  timeZone = 'timeZone', // + more

  // controlPanelAccess
  accessLevel = 'accessLevel',

  // controlPanelCfg
  currentLanguage = 'currentLanguage',
  defHomeScreenApplication = 'defaultHomeScreenApplication',
  languageDefKeyboardMappings = 'languageDefaultKeyboardMappings',
  showEventQrCode = 'showEventQrCode',
  restartAfterJobStart = 'restartAfterJobStart',
  displayDateAndTime = 'displayDateAndTime',

  // controlPanelHomeScreen
  pagesLaunchers = 'pages.launchers',

  // deviceAdminConfig
  credentialsCurrentPassword = 'credentials.currentPassword',

  // deviceAnnouncement
  announcementEnabled = 'announcementEnabled',

  // deviceCfg
  assetNumber = 'assetNumber',
  companyName = 'companyName',
  companyContact = 'companyContact',
  deviceLocation = 'deviceLocation',
  deviceDescription = 'deviceDescription',
  supportContact = 'supportContact',

  // discoveryServices
  bonjourEnabled = 'bonjour.enabled',
  slpEnabled = 'slp.enabled',
  wsDiscoveryEnabled = 'wsDiscovery.enabled',

  //dssConfig, serviceUserConfig
  password = 'password',

  // ewsConfiguration
  languageSource = 'languageSource',
  selectedLanguage = 'selectedLanguage',
  informationTabAccess = 'informationTabAccess', // + displayPrintPageOnInformationTab, displayJobLogOnInformationTab
  ewsEnabled = 'ewsEnabled',
  showLinksInEventLog = 'showLinksInEventLog', // + showSupportLinks

  // faxModemCfg
  analogFaxSetupCountry = 'analogFaxSetup.analogFaxCountry', // + more
  analogFaxOperationJbig = 'analogFaxOperation.compressionJBIGEnabled',
  analogFaxReceiveRings = 'analogFaxReceiveSettings.ringsToAnswer',

  // faxReceiveCfg
  faxReceiveEnabled = 'faxReceiveEnabled',
  faxReceiveMethod = 'faxReceiveMethod',

  // faxSendCfg
  ippFaxEnabled = 'ippFaxEnabled',
  faxSendEnabled = 'faxSendEnabled',
  faxSendMethod = 'faxSendMethod',
  pcFaxSendEnabled = 'pcFaxSendEnabled',

  // fleetProxy_legacyAttributes
  cloudPrint = 'webServices.cloudPrint',
  hpJetAdvantage = 'webServices.hpJetAdvantage', // + webServices.accountCreation
  restrictColor = 'security.colorAccessControl.restrictColor',
  allowTransfer = 'security.digitalSendingService.allowTransfer',

  // fwLookupSupport
  fwUpdateLookupSupport = 'firmwareUpdateLookupSupport',
  fwBundleSWLSSupport = 'firmwareBundleSWLSSupport',

  // fwUpdateCfg
  autoUpdateEnabled = 'autoUpdateEnabled',
  allowDowngrade = 'allowDowngrade',
  pjlUpdateEnabled = 'pjlUpdateEnabled',
  sha1ValidationEnabled = 'sha1ValidationEnabled',

  // jetDirectService
  telnetEnabled = 'telnetEnabled',
  tftpEnabled = 'tftpEnabled',
  xdmEnabled = 'xdmEnabled',
  systemContact = 'systemContact',
  systemLocation = 'systemLocation',

  // jobTicketCfgDefCopy
  destPrintMediaSource = 'dest.print.mediaSource',
  pipelineImgBgCleanup = 'pipelineOptions.imageModifications.backgroundCleanup',
  pipelineImgContrast = 'pipelineOptions.imageModifications.contrast',
  pipelineImgExposure = 'pipelineOptions.imageModifications.exposure',
  pipelineImgSharpness = 'pipelineOptions.imageModifications.sharpness',

  // jobTicketCfgDefEmail
  destEmailAddressFieldRestrictionsEnabled = 'dest.email.addressFieldRestrictionsEnabled',
  destEmailAllowInvalidEmailAddress = 'dest.email.allowInvalidEmailAddress',
  destEmailFromDisplayName = 'dest.email.from.displayName',
  destEmailIsFromEditable = 'dest.email.isFromEditable',
  destEmailCcListIsEditable = 'dest.email.ccListIsEditable',
  destEmailCcListSignInRequired = 'dest.email.ccListSignInRequired',
  destEmailBccListIsEditable = 'dest.email.bccListIsEditable',
  destEmailBccListSignInRequired = 'dest.email.bccListSignInRequired',
  destEmailSubject = 'dest.email.subject',
  destEmailIsSubjectEditable = 'dest.email.isSubjectEditable',
  destEmailBody = 'dest.email.body',
  destEmailIsBodyEditable = 'dest.email.isBodyEditable',
  destEmailSigningEnabled = 'dest.email.emailSigningEnabled',
  destEmailSigningIsEditable = 'dest.email.emailSigning.isEditable',
  destEmailEncryptionEnabled = 'dest.email.emailEncryptionEnabled',
  destEmailEncryptionIsEditable = 'dest.email.emailEncryption.isEditable',

  //jobTicketCfgDefNetworkFolder, jobTicketCfgReceiveFax, jobTicketCfgDefFax
  notificationCondition = 'pipelineOptions.notification.notificationCondition',
  notificationAddress = 'pipelineOptions.notification.notificationAddress',
  notificationMode = 'pipelineOptions.notification.notificationMode',

  // jobTicketCfgDefEmail, jobTicketCfgDefNetworkFolder
  sendFileCompressionColor = 'pipelineOptions.sendFileAttributes.colorGrayScaleTiffCompression',
  sendFileCompressPDF = 'pipelineOptions.sendFileAttributes.compressPDF',
  sendFileEncryptPDF = 'pipelineOptions.sendFileAttributes.encryptPDF',
  sendFileFileName = 'pipelineOptions.sendFileAttributes.fileName',
  sendFileFileNameIsEditable = 'pipelineOptions.sendFileAttributes.fileNameIsEditable',
  sendFileSuffix = 'pipelineOptions.sendFileAttributes.suffix',
  sendFileFileType = 'pipelineOptions.sendFileAttributes.fileType',
  sendFileCompressionBlack = 'pipelineOptions.sendFileAttributes.monoTiffCompression',
  sendFileQualityAndFileSize = 'pipelineOptions.sendFileAttributes.qualityAndFileSize',
  imageBlankPageSuppression = 'pipelineOptions.imageModifications.blankPageSuppressionEnabled',

  // jobTicketCfgDefCopy, jobTicketCfgDefEmail, jobTicketCfgDefNetworkFolder
  srcPageBinding = 'src.scan.pageBinding',
  srcScanMediaSize = 'src.scan.mediaSize',
  srcScanResolution = 'src.scan.resolution',
  srcScanContentType = 'src.scan.contentType',
  srcScanColorMode = 'src.scan.colorMode',

  // jobTicketCfgDefPrint
  destPrintDuplexBinding = 'dest.print.duplexBinding',
  destPrintMediaSize = 'dest.print.mediaSize',
  destPrintMediaType = 'dest.print.mediaType',

  // jobTicketCfgReceiveFax
  imageOutputMedia = 'imageModifications.outputCanvasMediaId',

  // jobTicketCreation
  promptForAdditionalPages = 'pipelineOptions.promptForAdditionalPages',

  // ldapConfig
  ldapBindRoots = 'ldapSignInConfig.ldapBindRoots',
  ldapBindPrefix = 'ldapSignInConfig.ldapBindPrefix',
  matchLdapNameAttribute = 'ldapSignInConfig.matchLdapNameAttribute',
  retrieveLdapGroupAttribute = 'ldapSignInConfig.retrieveLdapGroupAttribute',
  retrieveLdapNameAttribute = 'ldapSignInConfig.retrieveLdapNameAttribute',
  retrieveLdapEmailAttribute = 'ldapSignInConfig.retrieveLdapEmailAttribute',
  secureConnectionEnabled = 'ldapSignInConfig.secureConnectionEnabled',

  // mediaConfiguration
  inputs = 'inputs',

  // mediaHandlingCfg
  a4LetterOverrideEnabled = 'a4LetterOverrideEnabled',
  manualFeedEnable = 'manualFeedEnable',
  manualFeedPrompt = 'manualFeedPrompt',
  sizeTypeEnabled = 'sizeTypeEnabled',
  useRequestedTray = 'useRequestedTray',

  // nameResolverServices
  llmnrEnabled = 'llmnr.enabled',
  winsEnabled = 'wins.enabled',

  // networkFolderCfg
  smbVersion1Enabled = 'smbVersion1Enabled', // + smbVersion2Enabled, smbVersion3Enabled

  // networkScanService
  eSCL = 'scanServices.eSCL.eSCL',
  eSCLSecure = 'scanServices.eSCL.eSCLSecure',

  // pdlConfig
  postScriptSecurityEnabled = 'postScriptSecurityEnabled',

  // powerCfg
  sleepTimeout = 'sleepTimeout',
  inactivityTimeout = 'inactivityTimeout',
  shutdownTimeoutInMinutes = 'shutdownTimeoutInMinutes', // + shutdownPrevention

  // printServices
  airPrintEnabled = 'airPrint.enabled',
  ippIpp = 'ipp.ipp',
  ippIpps = 'ipp.ippSecure',
  ippEnableCertificateValidation = 'ipp.enableCertificateValidation',
  lpdPrintEnabled = 'lpdPrint.enabled',
  port9100Enabled = 'port9100.enabled',
  wsPrintEnabled = 'wsPrint.enabled',
  ftpPrintEnabled = 'ftpPrint.enabled',

  // printCfg
  pjlPasswordConfigured = 'pjlPasswordConfigured',
  pjlDeviceAccessCommandsEnabled = 'pjlDeviceAccessCommandsEnabled',
  pjlFileSystemAccessEnabled = 'pjlFileSystemAccessEnabled', // + psFileSystemAccessEnabled
  //autoJamRecovery

  // proxyCfg
  httpProxyAddress = 'httpProxy.address',
  httpProxyEnabled = 'httpProxy.enabled',

  // rbacCfg
  rolePermissionMap = 'rolePermissionMappings.rolePermissionMap.permissions',

  // scanDestinationConfig
  emailEnabled = 'emailEnabled',
  folderEnabled = 'folderEnabled',
  sharePointEnabled = 'sharePointEnabled',

  // scanSupplies
  suppliesListVeryLowAction = 'scanSuppliesList.suppliesList.veryLowAction',

  // securityCapabilities
  intrusionDetectionSupported = 'secureBootSettings.intrusionDetectionSupported',
  sureStartSupported = 'secureBootSettings.sureStartSupported',
  whiteListingSupported = 'secureBootSettings.whiteListingSupported',

  // sleepCfg
  sleepModeTimeout = 'sleepModeTimeout',
  sleepSchedules = 'sleepSchedules', // + holidaySchedules and more
  autoOnEvents = 'autoOnEvents',

  // snmpConfig
  snmpV1V2ConfigEnabled = 'snmpV1V2Config.enabled',
  snmpV3ConfigEnabled = 'snmpV3Config.enabled',
  snmpV3AccountLockout = 'snmpV3Config.accountLockout.enabled',
  snmpV3MinPassLength = 'snmpV3Config.minPasswordLength',
  snmpV3PassComplexity = 'snmpV3Config.passwordComplexityEnabled',

  // smtpServers
  serverAddress = 'serverAddress', // for deviceAnnouncement also
  credentialType = 'credential.credentialType',
  serverUsageAutomatedEmail = 'serverUsage.automatedEmail',
  serverUsageFax = 'serverUsage.fax',
  serverUsageEmail = 'serverUsage.email',
  serverUsageAutoSend = 'serverUsage.autoSend',

  //scanStampLocations
  stampLocations = 'scanStampLocations',

  // storageDevicesCfg
  fileEraseMode = 'fileEraseMode',

  // storeJobMgtCfg
  storeJobEnabled = 'storeJobEnabled',
  requireFourDigitPin = 'requireFourDigitPin',
  requirePrintJobPinProtection = 'requirePrintJobPinProtection',
  requireScanJobPinProtection = 'requireScanJobPinProtection',
  cancelJobsWithoutPinProtection = 'cancelJobsWithoutPinProtection',
  temporaryJobLimit = 'temporaryJobLimit',
  temporaryJobRetentionPolicy = 'temporaryJobRetentionPolicy',

  // supplyConfigPvt
  supplyPolicy = 'supplyPolicy',
  blackVeryLowAction = 'blackVeryLowAction',
  colorVeryLowAction = 'colorVeryLowAction',
  antiTheftEnabled = 'antiTheftEnabled',
  lowMessagingEnabled = 'lowMessagingEnabled',
  lowThresholdsPerSupply = 'lowThresholdsPerSupply',
  veryLowActionsPerSupply = 'veryLowActionsPerSupply',
  veryLowPageDelayEnabled = 'veryLowPageDelayEnabled',

  // systemConfig
  httpsRedirectionEnabled = 'httpsRedirectionEnabled',
  csrfPreventionEnabled = 'csrfPreventionEnabled',
  corsEnabled = 'corsEnabled', // + trustedSites

  // systemStorage
  fullDiskEncryptionStatus = 'systemDevice.storageDeviceStatus.fullDiskEncryptionStatus',

  // tlsConfig
  ciphers = 'ciphers',
  cipherStrength = 'cipherStrength',
  fipsMode = 'fipsMode',
  maxProtocolVersion = 'maxProtocolVersion',
  minProtocolVersion = 'minProtocolVersion',

  // usbHostCfg
  plugAndPlayEnabled = 'plugAndPlayEnabled',
  scanToUsbEnabled = 'scanToUsbEnabled',

  // wifiDirectConfig
  preferredChannel = 'preferredChannel',

  // wirelessConfig
  dot1xAuthConfigEapLeapEnabled = 'dot1xAuthConfig.eapLeapEnabled',
  dot1xAuthConfigEncryptionStrength = 'dot1xAuthConfig.encryptionStrength',
  dot1xAuthConfigServerIdentity = 'dot1xAuthConfig.serverIdentity',
  dot1xAuthConfigPassword = 'dot1xAuthConfig.password',
  dot1xAuthConfigUserName = 'dot1xAuthConfig.userName',

  // workPathSolutionMgrCfg
  workpathEnabledConfig = 'workpathEnabledConfig'
}

export default class PolicyConstraints {
  static attributeInfo: Record<
    string,
    {
      resource: ConstraintsResourceEnum
      settingName: ConstraintsSettingEnum
      deviceSettings?: Record<
        string,
        {
          resource: ConstraintsResourceEnum
          settingName: ConstraintsSettingEnum
        }
      >
    }
  > = {
    [PolicyAttributeEnum.Auto_FW_Update]: {
      resource: ConstraintsResourceEnum.fwUpdateCfg,
      settingName: ConstraintsSettingEnum.autoUpdateEnabled
    },
    [PolicyAttributeEnum.Legacy_Firmware_Upgrade]: {
      resource: ConstraintsResourceEnum.fwUpdateCfg,
      settingName: ConstraintsSettingEnum.sha1ValidationEnabled
    },
    [PolicyAttributeEnum.Remote_FW_Update]: {
      resource: ConstraintsResourceEnum.fwUpdateCfg,
      settingName: ConstraintsSettingEnum.pjlUpdateEnabled
    },
    [PolicyAttributeEnum.PJL_Password]: {
      resource: ConstraintsResourceEnum.printCfg,
      settingName: ConstraintsSettingEnum.pjlPasswordConfigured
    },
    [PolicyAttributeEnum.Remote_Cfg_Password]: {
      resource: ConstraintsResourceEnum.dssConfig,
      settingName: ConstraintsSettingEnum.password
    },
    [PolicyAttributeEnum.Svc_Access_Code]: {
      resource: ConstraintsResourceEnum.serviceUserConfig,
      settingName: ConstraintsSettingEnum.password
    },
    [PolicyAttributeEnum.PJL_Access_Commands]: {
      resource: ConstraintsResourceEnum.printCfg,
      settingName: ConstraintsSettingEnum.pjlDeviceAccessCommandsEnabled
    },
    [PolicyAttributeEnum.FS_Access_Protocols]: {
      resource: ConstraintsResourceEnum.printCfg,
      settingName: ConstraintsSettingEnum.pjlFileSystemAccessEnabled
    },
    [PolicyAttributeEnum.HTTPS_Redirect]: {
      resource: ConstraintsResourceEnum.systemConfig,
      settingName: ConstraintsSettingEnum.httpsRedirectionEnabled
    },
    // PolicyAttributeEnum.WiFi
    [PolicyAttributeEnum.SNMP_V1_V2]: {
      resource: ConstraintsResourceEnum.snmpConfig,
      settingName: ConstraintsSettingEnum.snmpV1V2ConfigEnabled
    },
    [PolicyAttributeEnum.SNMP_V3]: {
      resource: ConstraintsResourceEnum.snmpConfig,
      settingName: ConstraintsSettingEnum.snmpV3ConfigEnabled,
      deviceSettings: {
        [`${PolicyAttributeEnum.SNMP_V3}.min-length`]: {
          resource: ConstraintsResourceEnum.snmpConfig,
          settingName: ConstraintsSettingEnum.snmpV3MinPassLength
        },
        [`${PolicyAttributeEnum.SNMP_V3}.complexity`]: {
          resource: ConstraintsResourceEnum.snmpConfig,
          settingName: ConstraintsSettingEnum.snmpV3PassComplexity
        },
        [`${PolicyAttributeEnum.SNMP_V3}.lockout`]: {
          resource: ConstraintsResourceEnum.snmpConfig,
          settingName: ConstraintsSettingEnum.snmpV3AccountLockout
        },
        [`${PolicyAttributeEnum.SNMP_V3}.max-attempts`]: {
          resource: ConstraintsResourceEnum.snmpConfig,
          settingName: ConstraintsSettingEnum.snmpV3AccountLockout
        },
        [`${PolicyAttributeEnum.SNMP_V3}.reset-after`]: {
          resource: ConstraintsResourceEnum.snmpConfig,
          settingName: ConstraintsSettingEnum.snmpV3AccountLockout
        },
        [`${PolicyAttributeEnum.SNMP_V3}.lockout-duration`]: {
          resource: ConstraintsResourceEnum.snmpConfig,
          settingName: ConstraintsSettingEnum.snmpV3AccountLockout
        }
      }
    },
    [PolicyAttributeEnum.EWS_Admin_Password]: {
      resource: ConstraintsResourceEnum.deviceAdminConfig,
      settingName: ConstraintsSettingEnum.credentialsCurrentPassword,
      deviceSettings: {
        [`${PolicyAttributeEnum.EWS_Admin_Password}.min-length`]: {
          resource: ConstraintsResourceEnum.accountPolicy,
          settingName: ConstraintsSettingEnum.minPasswordLength
        },
        [`${PolicyAttributeEnum.EWS_Admin_Password}.complexity`]: {
          resource: ConstraintsResourceEnum.accountPolicy,
          settingName: ConstraintsSettingEnum.passwordComplexityEnabled
        },
        [`${PolicyAttributeEnum.EWS_Admin_Password}.lockout`]: {
          resource: ConstraintsResourceEnum.accountPolicy,
          settingName: ConstraintsSettingEnum.accountLockoutEnabled
        },
        [`${PolicyAttributeEnum.EWS_Admin_Password}.max-attempts`]: {
          resource: ConstraintsResourceEnum.accountPolicy,
          settingName: ConstraintsSettingEnum.accountLockoutEnabled
        },
        [`${PolicyAttributeEnum.EWS_Admin_Password}.reset-after`]: {
          resource: ConstraintsResourceEnum.accountPolicy,
          settingName: ConstraintsSettingEnum.accountLockoutEnabled
        },
        [`${PolicyAttributeEnum.EWS_Admin_Password}.lockout-duration`]: {
          resource: ConstraintsResourceEnum.accountPolicy,
          settingName: ConstraintsSettingEnum.accountLockoutEnabled
        }
      }
    },
    [PolicyAttributeEnum.File_Erase_Mode]: {
      resource: ConstraintsResourceEnum.storageDevicesCfg,
      settingName: ConstraintsSettingEnum.fileEraseMode
    },
    [PolicyAttributeEnum.Retain_Print_Jobs]: {
      resource: ConstraintsResourceEnum.storeJobMgtCfg,
      settingName: ConstraintsSettingEnum.storeJobEnabled
    },
    [PolicyAttributeEnum.Control_Panel_Lock]: {
      resource: ConstraintsResourceEnum.controlPanelAccess,
      settingName: ConstraintsSettingEnum.accessLevel
    },
    [PolicyAttributeEnum.Check_Latest_FW]: {
      resource: ConstraintsResourceEnum.fwLookupSupport,
      settingName: ConstraintsSettingEnum.fwBundleSWLSSupport
    },
    [PolicyAttributeEnum.Svc_Location_Protocol]: {
      resource: ConstraintsResourceEnum.discoveryServices,
      settingName: ConstraintsSettingEnum.slpEnabled
    },
    [PolicyAttributeEnum.IPv4_Multicast]: {
      resource: ConstraintsResourceEnum.adapterConfigs,
      settingName: ConstraintsSettingEnum.ipv4MulticastEnabled
    },
    [PolicyAttributeEnum.LLMNR]: {
      resource: ConstraintsResourceEnum.nameResolverServices,
      settingName: ConstraintsSettingEnum.llmnrEnabled
    },
    [PolicyAttributeEnum.Ws_Discovery]: {
      resource: ConstraintsResourceEnum.discoveryServices,
      settingName: ConstraintsSettingEnum.wsDiscoveryEnabled
    },
    [PolicyAttributeEnum.Bonjour]: {
      resource: ConstraintsResourceEnum.discoveryServices,
      settingName: ConstraintsSettingEnum.bonjourEnabled
    },
    [PolicyAttributeEnum.Tcpip_Print]: {
      resource: ConstraintsResourceEnum.printServices,
      settingName: ConstraintsSettingEnum.port9100Enabled
    },
    [PolicyAttributeEnum.Airprint]: {
      resource: ConstraintsResourceEnum.printServices,
      settingName: ConstraintsSettingEnum.airPrintEnabled
    },
    [PolicyAttributeEnum.Lpd_Lpr]: {
      resource: ConstraintsResourceEnum.printServices,
      settingName: ConstraintsSettingEnum.lpdPrintEnabled
    },
    [PolicyAttributeEnum.Ipp]: {
      resource: ConstraintsResourceEnum.printServices,
      settingName: ConstraintsSettingEnum.ippIpp
    },
    [PolicyAttributeEnum.Ipps]: {
      resource: ConstraintsResourceEnum.printServices,
      settingName: ConstraintsSettingEnum.ippIpps
    },
    [PolicyAttributeEnum.Ws_Print]: {
      resource: ConstraintsResourceEnum.printServices,
      settingName: ConstraintsSettingEnum.wsPrintEnabled
    },
    [PolicyAttributeEnum.PostScript_Security]: {
      resource: ConstraintsResourceEnum.pdlCfg,
      settingName: ConstraintsSettingEnum.postScriptSecurityEnabled
    },
    [PolicyAttributeEnum.Verify_Certificate]: {
      resource: ConstraintsResourceEnum.printServices,
      settingName: ConstraintsSettingEnum.ippEnableCertificateValidation
    },
    [PolicyAttributeEnum.Enable_WINS_Port]: {
      resource: ConstraintsResourceEnum.nameResolverServices,
      settingName: ConstraintsSettingEnum.winsEnabled
    },
    [PolicyAttributeEnum.WINS_Registration]: {
      resource: ConstraintsResourceEnum.nameResolverServices,
      settingName: ConstraintsSettingEnum.winsEnabled
    },
    [PolicyAttributeEnum.Csrf_Prevention]: {
      resource: ConstraintsResourceEnum.systemConfig,
      settingName: ConstraintsSettingEnum.csrfPreventionEnabled
    },
    [PolicyAttributeEnum.Dc_Ports]: {
      resource: ConstraintsResourceEnum.adapterConfigs,
      settingName: ConstraintsSettingEnum.usbEnabled
    },
    [PolicyAttributeEnum.Host_Usb_Pnp]: {
      resource: ConstraintsResourceEnum.usbHostCfg,
      settingName: ConstraintsSettingEnum.plugAndPlayEnabled,
      deviceSettings: {
        [`${PolicyAttributeEnum.Host_Usb_Pnp}.save`]: {
          resource: ConstraintsResourceEnum.usbHostCfg,
          settingName: ConstraintsSettingEnum.scanToUsbEnabled
        }
      }
    },
    [PolicyAttributeEnum.Secure_Boot_Presence]: {
      resource: ConstraintsResourceEnum.securityCapabilities,
      settingName: ConstraintsSettingEnum.sureStartSupported
    },
    [PolicyAttributeEnum.Intrusion_Detection_Presence]: {
      resource: ConstraintsResourceEnum.securityCapabilities,
      settingName: ConstraintsSettingEnum.intrusionDetectionSupported
    },
    [PolicyAttributeEnum.Whitelisting_Presence]: {
      resource: ConstraintsResourceEnum.securityCapabilities,
      settingName: ConstraintsSettingEnum.whiteListingSupported
    },
    [PolicyAttributeEnum.Fax_Receive]: {
      resource: ConstraintsResourceEnum.faxReceiveCfg,
      settingName: ConstraintsSettingEnum.faxReceiveEnabled,
      deviceSettings: {
        [`${PolicyAttributeEnum.Fax_Receive}.settings.method`]: {
          resource: ConstraintsResourceEnum.faxReceiveCfg,
          settingName: ConstraintsSettingEnum.faxReceiveMethod
        },
        [`${PolicyAttributeEnum.Fax_Receive}.settings.common-tray`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgReceiveFax,
          settingName: ConstraintsSettingEnum.imageOutputMedia
        },
        [`${PolicyAttributeEnum.Fax_Receive}.settings.common-notification-address`]:
          {
            resource: ConstraintsResourceEnum.jobTicketCfgReceiveFax,
            settingName: ConstraintsSettingEnum.notificationAddress
          },
        [`${PolicyAttributeEnum.Fax_Receive}.settings.common-notification-condition`]:
          {
            resource: ConstraintsResourceEnum.jobTicketCfgReceiveFax,
            settingName: ConstraintsSettingEnum.notificationCondition
          },
        [`${PolicyAttributeEnum.Fax_Receive}.settings.common-notification-method`]:
          {
            resource: ConstraintsResourceEnum.jobTicketCfgReceiveFax,
            settingName: ConstraintsSettingEnum.notificationMode
          }
      }
    },
    [PolicyAttributeEnum.Ews_Access]: {
      resource: ConstraintsResourceEnum.ewsConfiguration,
      settingName: ConstraintsSettingEnum.ewsEnabled
    },
    [PolicyAttributeEnum.Info_Tab]: {
      resource: ConstraintsResourceEnum.ewsConfiguration,
      settingName: ConstraintsSettingEnum.informationTabAccess
    },
    [PolicyAttributeEnum.Firmware_Downgrade]: {
      resource: ConstraintsResourceEnum.fwUpdateCfg,
      settingName: ConstraintsSettingEnum.allowDowngrade
    },
    [PolicyAttributeEnum.Disk_Encryption_Status]: {
      resource: ConstraintsResourceEnum.systemStorage,
      settingName: ConstraintsSettingEnum.fullDiskEncryptionStatus
    },
    [PolicyAttributeEnum.Firmware_Update]: {
      resource: ConstraintsResourceEnum.fwLookupSupport,
      settingName: ConstraintsSettingEnum.fwUpdateLookupSupport
    },
    [PolicyAttributeEnum.Control_Panel_Timeout]: {
      resource: ConstraintsResourceEnum.powerCfg,
      settingName: ConstraintsSettingEnum.inactivityTimeout
    },
    [PolicyAttributeEnum.Web_Encryption]: {
      resource: ConstraintsResourceEnum.tlsConfig,
      settingName: ConstraintsSettingEnum.ciphers,
      deviceSettings: {
        [`${PolicyAttributeEnum.Web_Encryption}.strength`]: {
          resource: ConstraintsResourceEnum.tlsConfig,
          settingName: ConstraintsSettingEnum.cipherStrength
        }
      }
    },
    [PolicyAttributeEnum.Asset_Number]: {
      resource: ConstraintsResourceEnum.deviceCfg,
      settingName: ConstraintsSettingEnum.assetNumber
    },
    [PolicyAttributeEnum.Company_Name]: {
      resource: ConstraintsResourceEnum.deviceCfg,
      settingName: ConstraintsSettingEnum.companyName
    },
    [PolicyAttributeEnum.Contact_Person]: {
      resource: ConstraintsResourceEnum.deviceCfg,
      settingName: ConstraintsSettingEnum.companyContact
    },
    [PolicyAttributeEnum.Device_Location]: {
      resource: ConstraintsResourceEnum.deviceCfg,
      settingName: ConstraintsSettingEnum.deviceLocation
    },
    [PolicyAttributeEnum.Device_Name]: {
      resource: ConstraintsResourceEnum.deviceCfg,
      settingName: ConstraintsSettingEnum.deviceDescription
    },
    [PolicyAttributeEnum.Duplex_Binding]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefPrint,
      settingName: ConstraintsSettingEnum.destPrintDuplexBinding
    },
    [PolicyAttributeEnum.Tray_Admin]: {
      resource: ConstraintsResourceEnum.mediaConfiguration,
      settingName: ConstraintsSettingEnum.inputs
    },
    [PolicyAttributeEnum.Control_Panel_Language]: {
      resource: ConstraintsResourceEnum.controlPanelCfg,
      settingName: ConstraintsSettingEnum.currentLanguage
    },
    [PolicyAttributeEnum.Home_Screen_App_Fs4]: {
      resource: ConstraintsResourceEnum.controlPanelHomeScreen,
      settingName: ConstraintsSettingEnum.pagesLaunchers
    },
    [PolicyAttributeEnum.Home_Screen_App_Pro]: {
      resource: ConstraintsResourceEnum.controlPanelHomeScreen,
      settingName: ConstraintsSettingEnum.pagesLaunchers
    },
    [PolicyAttributeEnum.Home_Screen_Custom]: {
      resource: ConstraintsResourceEnum.controlPanelCfg,
      settingName: ConstraintsSettingEnum.defHomeScreenApplication
    },
    [PolicyAttributeEnum.Home_Screen_Language]: {
      resource: ConstraintsResourceEnum.controlPanelCfg,
      settingName: ConstraintsSettingEnum.languageDefKeyboardMappings
    },
    // PolicyAttributeEnum.Home_Screen_Wallpaper
    [PolicyAttributeEnum.Date_Time_Format]: {
      resource: ConstraintsResourceEnum.clockCfg,
      settingName: ConstraintsSettingEnum.dateFormat
    },
    [PolicyAttributeEnum.Time_Zone]: {
      resource: ConstraintsResourceEnum.clockCfg,
      settingName: ConstraintsSettingEnum.timeZone
    },
    [PolicyAttributeEnum.Sleep_Delay]: {
      resource: ConstraintsResourceEnum.powerCfg,
      settingName: ConstraintsSettingEnum.sleepTimeout
    },
    [PolicyAttributeEnum.Def_Media_Size]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefPrint,
      settingName: ConstraintsSettingEnum.destPrintMediaSize
    },
    [PolicyAttributeEnum.Def_Media_Type]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefPrint,
      settingName: ConstraintsSettingEnum.destPrintMediaType
    },
    [PolicyAttributeEnum.Delay_Low_Supply_Msg]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.veryLowPageDelayEnabled
    },
    [PolicyAttributeEnum.Cartridge_Policy]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.supplyPolicy
    },
    [PolicyAttributeEnum.Bt_Low_Energy]: {
      resource: ConstraintsResourceEnum.bleCfg,
      settingName: ConstraintsSettingEnum.requestedBeaconingEnabled
    },
    [PolicyAttributeEnum.Dns_Server]: {
      resource: ConstraintsResourceEnum.adapterConfigs,
      settingName: ConstraintsSettingEnum.ipv4DnsPrimary
    },
    [PolicyAttributeEnum.Ipv4_Info]: {
      resource: ConstraintsResourceEnum.adapterConfigs,
      settingName: ConstraintsSettingEnum.ipv4Gateway
    },
    [PolicyAttributeEnum.Ipv6_Info]: {
      resource: ConstraintsResourceEnum.adapterConfigs,
      settingName: ConstraintsSettingEnum.ipv6Enabled
    },
    [PolicyAttributeEnum.Configuration_Precedence]: {
      resource: ConstraintsResourceEnum.adapterConfigs,
      settingName: ConstraintsSettingEnum.configPrecedenceMethod
    },
    [PolicyAttributeEnum.Link_Setting]: {
      resource: ConstraintsResourceEnum.adapterConfigs,
      settingName: ConstraintsSettingEnum.ipv4LinkConfig
    },
    [PolicyAttributeEnum.Airprint_Fax]: {
      resource: ConstraintsResourceEnum.faxSendCfg,
      settingName: ConstraintsSettingEnum.ippFaxEnabled
    },
    [PolicyAttributeEnum.Airprint_Scan]: {
      resource: ConstraintsResourceEnum.networkScanService,
      settingName: ConstraintsSettingEnum.eSCL
    },
    [PolicyAttributeEnum.Airprint_Secure_Scan]: {
      resource: ConstraintsResourceEnum.networkScanService,
      settingName: ConstraintsSettingEnum.eSCLSecure
    },
    [PolicyAttributeEnum.Airprint_Scan_Secure_Scan]: {
      resource: ConstraintsResourceEnum.networkScanService,
      settingName: ConstraintsSettingEnum.eSCL
    },
    [PolicyAttributeEnum.Web_Scan]: {
      resource: ConstraintsResourceEnum.networkScanService,
      settingName: ConstraintsSettingEnum.eSCL,
      deviceSettings: {
        [`${PolicyAttributeEnum.Web_Scan}.secure`]: {
          resource: ConstraintsResourceEnum.networkScanService,
          settingName: ConstraintsSettingEnum.eSCLSecure
        }
      }
    },
    [PolicyAttributeEnum.Dhcp_v4_Compliance]: {
      resource: ConstraintsResourceEnum.adapterConfigs,
      settingName: ConstraintsSettingEnum.ipv4DhcpCompliance
    },
    [PolicyAttributeEnum.Ews_Language]: {
      resource: ConstraintsResourceEnum.ewsConfiguration,
      settingName: ConstraintsSettingEnum.selectedLanguage,
      deviceSettings: {
        [`${PolicyAttributeEnum.Ews_Language}.mode`]: {
          resource: ConstraintsResourceEnum.ewsConfiguration,
          settingName: ConstraintsSettingEnum.languageSource
        }
      }
    },
    [PolicyAttributeEnum.Time_Services]: {
      resource: ConstraintsResourceEnum.clockCfg,
      settingName: ConstraintsSettingEnum.systemTimeSync
    },
    // Device_User_Accounts
    // Def_Print_Options
    // Input_Auto_Continue
    // Supplies_Status_Msgs_On_Ctrl_Panel
    // Input_Auto_Continue_Timeout
    [PolicyAttributeEnum.Save_to_SharePoint]: {
      resource: ConstraintsResourceEnum.scanDestinationConfig,
      settingName: ConstraintsSettingEnum.sharePointEnabled
    },
    [PolicyAttributeEnum.Save_to_Email]: {
      resource: ConstraintsResourceEnum.scanDestinationConfig,
      settingName: ConstraintsSettingEnum.emailEnabled
    },
    [PolicyAttributeEnum.Save_to_Network_Folder]: {
      resource: ConstraintsResourceEnum.scanDestinationConfig,
      settingName: ConstraintsSettingEnum.folderEnabled
    },
    [PolicyAttributeEnum.Supply_Low_Alerts]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowMessagingEnabled
    },
    [PolicyAttributeEnum.Cartridge_Threshold_Black]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowThresholdsPerSupply
    },
    [PolicyAttributeEnum.Cartridge_Threshold_Cyan]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowThresholdsPerSupply
    },
    [PolicyAttributeEnum.Cartridge_Threshold_Magenta]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowThresholdsPerSupply
    },
    [PolicyAttributeEnum.Cartridge_Threshold_Yellow]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowThresholdsPerSupply
    },
    [PolicyAttributeEnum.Drum_Threshold_Black]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowThresholdsPerSupply
    },
    [PolicyAttributeEnum.Drum_Threshold_Cyan]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowThresholdsPerSupply
    },
    [PolicyAttributeEnum.Drum_Threshold_Magenta]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowThresholdsPerSupply
    },
    [PolicyAttributeEnum.Drum_Threshold_Yellow]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowThresholdsPerSupply
    },
    // Novell_Cfg
    [PolicyAttributeEnum.Telnet]: {
      resource: ConstraintsResourceEnum.jetDirectService,
      settingName: ConstraintsSettingEnum.telnetEnabled
    },
    [PolicyAttributeEnum.Tftp_Cfg]: {
      resource: ConstraintsResourceEnum.jetDirectService,
      settingName: ConstraintsSettingEnum.tftpEnabled
    },
    [PolicyAttributeEnum.JetDirect_Xml_Cfg]: {
      resource: ConstraintsResourceEnum.jetDirectService,
      settingName: ConstraintsSettingEnum.xdmEnabled
    },
    [PolicyAttributeEnum.Ftp_Print]: {
      resource: ConstraintsResourceEnum.printServices,
      settingName: ConstraintsSettingEnum.ftpPrintEnabled
    },
    [PolicyAttributeEnum.Tcpip_Config]: {
      resource: ConstraintsResourceEnum.adapterConfigs,
      settingName: ConstraintsSettingEnum.ipv4ActiveConfigMethod
    },
    [PolicyAttributeEnum.Restrict_Color]: {
      resource: ConstraintsResourceEnum.fleetProxy_legacyAttributes,
      settingName: ConstraintsSettingEnum.restrictColor
    },
    // Color_Usage_Log
    [PolicyAttributeEnum.Digital_Sending]: {
      resource: ConstraintsResourceEnum.fleetProxy_legacyAttributes,
      settingName: ConstraintsSettingEnum.allowTransfer
    },
    [PolicyAttributeEnum.Ldap_Setup]: {
      resource: ConstraintsResourceEnum.ldapConfig,
      settingName: ConstraintsSettingEnum.enabled,
      deviceSettings: {
        [`${PolicyAttributeEnum.Ldap_Setup}.ssl`]: {
          resource: ConstraintsResourceEnum.ldapConfig,
          settingName: ConstraintsSettingEnum.secureConnectionEnabled
        },
        [`${PolicyAttributeEnum.Ldap_Setup}.retrieve-group`]: {
          resource: ConstraintsResourceEnum.ldapConfig,
          settingName: ConstraintsSettingEnum.retrieveLdapGroupAttribute
        }
      }
    },
    [PolicyAttributeEnum.Device_Announcement]: {
      resource: ConstraintsResourceEnum.deviceAnnouncement,
      settingName: ConstraintsSettingEnum.announcementEnabled
    },
    [PolicyAttributeEnum.Use_Requested_Tray]: {
      resource: ConstraintsResourceEnum.mediaHandlingCfg,
      settingName: ConstraintsSettingEnum.useRequestedTray
    },
    [PolicyAttributeEnum.Manual_Feed]: {
      resource: ConstraintsResourceEnum.mediaHandlingCfg,
      settingName: ConstraintsSettingEnum.manualFeedEnable
    },
    [PolicyAttributeEnum.Manual_Feed_Prompt]: {
      resource: ConstraintsResourceEnum.mediaHandlingCfg,
      settingName: ConstraintsSettingEnum.manualFeedPrompt
    },
    [PolicyAttributeEnum.Override_Letter_A4]: {
      resource: ConstraintsResourceEnum.mediaHandlingCfg,
      settingName: ConstraintsSettingEnum.a4LetterOverrideEnabled
    },
    [PolicyAttributeEnum.Size_Type_Prompt]: {
      resource: ConstraintsResourceEnum.mediaHandlingCfg,
      settingName: ConstraintsSettingEnum.sizeTypeEnabled
    },
    [PolicyAttributeEnum.Auto_Send]: {
      resource: ConstraintsResourceEnum.autoSendCfg,
      settingName: ConstraintsSettingEnum.autoSendEnabled
    },
    [PolicyAttributeEnum.Online_Solutions]: {
      resource: ConstraintsResourceEnum.ewsConfiguration,
      settingName: ConstraintsSettingEnum.showLinksInEventLog,
      deviceSettings: {
        [`${PolicyAttributeEnum.Online_Solutions}.show-qr-code`]: {
          resource: ConstraintsResourceEnum.controlPanelCfg,
          settingName: ConstraintsSettingEnum.showEventQrCode
        }
      }
    },
    [PolicyAttributeEnum.Outgoing_Servers]: {
      // same as Smtp_Gateway
      resource: ConstraintsResourceEnum.smtpServers,
      settingName: ConstraintsSettingEnum.serverAddress,
      deviceSettings: {
        [`${PolicyAttributeEnum.Outgoing_Servers}.usage.fax`]: {
          resource: ConstraintsResourceEnum.smtpServers,
          settingName: ConstraintsSettingEnum.serverUsageFax
        },
        [`${PolicyAttributeEnum.Outgoing_Servers}.usage.alert`]: {
          resource: ConstraintsResourceEnum.smtpServers,
          settingName: ConstraintsSettingEnum.serverUsageAutomatedEmail
        },
        [`${PolicyAttributeEnum.Outgoing_Servers}.usage.email`]: {
          resource: ConstraintsResourceEnum.smtpServers,
          settingName: ConstraintsSettingEnum.serverUsageEmail
        },
        [`${PolicyAttributeEnum.Outgoing_Servers}.usage.autosend`]: {
          resource: ConstraintsResourceEnum.smtpServers,
          settingName: ConstraintsSettingEnum.serverUsageAutoSend
        }
      }
    },
    [PolicyAttributeEnum.Show_Date_Time]: {
      resource: ConstraintsResourceEnum.controlPanelCfg,
      settingName: ConstraintsSettingEnum.displayDateAndTime
    },
    [PolicyAttributeEnum.Sleep_Settings]: {
      resource: ConstraintsResourceEnum.sleepCfg,
      settingName: ConstraintsSettingEnum.sleepModeTimeout
    },
    [PolicyAttributeEnum.Copy_Tray]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefCopy,
      settingName: ConstraintsSettingEnum.destPrintMediaSource
    },
    [PolicyAttributeEnum.Copy_Darkness]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefCopy,
      settingName: ConstraintsSettingEnum.pipelineImgExposure
    },
    [PolicyAttributeEnum.Copy_Contrast]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefCopy,
      settingName: ConstraintsSettingEnum.pipelineImgContrast
    },
    [PolicyAttributeEnum.Copy_Bg_Cleanup]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefCopy,
      settingName: ConstraintsSettingEnum.pipelineImgBgCleanup
    },
    [PolicyAttributeEnum.Copy_Sharpness]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefCopy,
      settingName: ConstraintsSettingEnum.pipelineImgSharpness
    },
    [PolicyAttributeEnum.Copy_Optimize]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefCopy,
      settingName: ConstraintsSettingEnum.srcScanContentType
    },
    [PolicyAttributeEnum.Enforce_Stamps]: {
      resource: ConstraintsResourceEnum.scanStampLocations,
      settingName: ConstraintsSettingEnum.stampLocations
    },
    [PolicyAttributeEnum.Smtp_Gateway]: {
      // same as Outgoing_Servers
      resource: ConstraintsResourceEnum.smtpServers,
      settingName: ConstraintsSettingEnum.serverAddress
    },
    [PolicyAttributeEnum.Email_Message]: {
      resource: ConstraintsResourceEnum.scanDestinationConfig,
      settingName: ConstraintsSettingEnum.emailEnabled,
      deviceSettings: {
        [`${PolicyAttributeEnum.Email_Message}.restrictions`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName:
            ConstraintsSettingEnum.destEmailAddressFieldRestrictionsEnabled
        },
        [`${PolicyAttributeEnum.Email_Message}.message.from-editable`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailIsFromEditable
        },
        [`${PolicyAttributeEnum.Email_Message}.message.cc`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailCcListSignInRequired
        },
        [`${PolicyAttributeEnum.Email_Message}.message.cc-addresses`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailCcListSignInRequired
        },
        [`${PolicyAttributeEnum.Email_Message}.message.cc-editable`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailCcListIsEditable
        },
        [`${PolicyAttributeEnum.Email_Message}.message.bcc`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailBccListSignInRequired
        },
        [`${PolicyAttributeEnum.Email_Message}.message.bcc-addresses`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailBccListSignInRequired
        },
        [`${PolicyAttributeEnum.Email_Message}.message.bcc-editable`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailBccListIsEditable
        },
        [`${PolicyAttributeEnum.Email_Message}.message.subject-editable`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailIsSubjectEditable
        },
        [`${PolicyAttributeEnum.Email_Message}.message.message-editable`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailIsBodyEditable
        },
        [`${PolicyAttributeEnum.Email_Message}.allow-invalid-email`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailAllowInvalidEmailAddress
        },
        [`${PolicyAttributeEnum.Email_Message}.sign`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailSigningEnabled
        },
        [`${PolicyAttributeEnum.Email_Message}.sign-editable`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailSigningIsEditable
        },
        [`${PolicyAttributeEnum.Email_Message}.encrypt`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailEncryptionEnabled
        },
        [`${PolicyAttributeEnum.Email_Message}.encrypt-editable`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.destEmailEncryptionIsEditable
        }
      }
    },
    [PolicyAttributeEnum.Email_File]: {
      resource: ConstraintsResourceEnum.scanDestinationConfig,
      settingName: ConstraintsSettingEnum.emailEnabled,
      deviceSettings: {
        [`${PolicyAttributeEnum.Email_File}.compression-color`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.sendFileCompressionColor
        },
        [`${PolicyAttributeEnum.Email_File}.compression-black`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.sendFileCompressionBlack
        },
        [`${PolicyAttributeEnum.Email_File}.compression`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.sendFileCompressPDF
        },
        [`${PolicyAttributeEnum.Email_File}.pdf-encryption`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.sendFileEncryptPDF
        },
        [`${PolicyAttributeEnum.Email_File}.name.name`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.sendFileFileName
        },
        [`${PolicyAttributeEnum.Email_File}.name.editable`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.sendFileFileNameIsEditable
        },
        [`${PolicyAttributeEnum.Email_File}.name.suffix`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.sendFileSuffix
        },
        [`${PolicyAttributeEnum.Email_File}.type`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.sendFileFileType
        },
        [`${PolicyAttributeEnum.Email_File}.quality`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.sendFileQualityAndFileSize
        },
        [`${PolicyAttributeEnum.Email_File}.blank-page-suppression`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.imageBlankPageSuppression
        },
        [`${PolicyAttributeEnum.Email_File}.scan-plex`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.srcPageBinding
        },
        [`${PolicyAttributeEnum.Email_File}.color-preference`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.srcScanColorMode
        },
        [`${PolicyAttributeEnum.Email_File}.media-size`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.srcScanMediaSize
        },
        [`${PolicyAttributeEnum.Email_File}.resolution`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
          settingName: ConstraintsSettingEnum.srcScanResolution
        }
      }
    },
    [PolicyAttributeEnum.Email_Scan]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefEmail,
      settingName: ConstraintsSettingEnum.srcScanContentType
    },
    [PolicyAttributeEnum.Email_Notification]: {
      resource: ConstraintsResourceEnum.scanDestinationConfig,
      settingName: ConstraintsSettingEnum.emailEnabled
    },
    [PolicyAttributeEnum.Network_Folder_File]: {
      resource: ConstraintsResourceEnum.scanDestinationConfig,
      settingName: ConstraintsSettingEnum.folderEnabled,
      deviceSettings: {
        [`${PolicyAttributeEnum.Network_Folder_File}.compression-color`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.sendFileCompressionColor
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.compression-black`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.sendFileCompressionBlack
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.compression`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.sendFileCompressPDF
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.pdf-encryption`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.sendFileEncryptPDF
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.name.name`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.sendFileFileName
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.name.editable`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.sendFileFileNameIsEditable
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.name.suffix`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.sendFileSuffix
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.type`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.sendFileFileType
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.quality`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.sendFileQualityAndFileSize
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.blank-page-suppression`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.imageBlankPageSuppression
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.scan-plex`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.srcPageBinding
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.color-preference`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.srcScanColorMode
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.media-size`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.srcScanMediaSize
        },
        [`${PolicyAttributeEnum.Network_Folder_File}.resolution`]: {
          resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
          settingName: ConstraintsSettingEnum.srcScanResolution
        }
      }
    },
    [PolicyAttributeEnum.Network_Folder_Notification]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder,
      settingName: ConstraintsSettingEnum.notificationCondition
    },
    [PolicyAttributeEnum.Fax_Header]: {
      resource: ConstraintsResourceEnum.faxModemCfg,
      settingName: ConstraintsSettingEnum.analogFaxSetupCountry
    },
    [PolicyAttributeEnum.Fax_Send]: {
      resource: ConstraintsResourceEnum.faxSendCfg,
      settingName: ConstraintsSettingEnum.faxSendEnabled,
      deviceSettings: {
        [`${PolicyAttributeEnum.Fax_Send}.settings.method`]: {
          resource: ConstraintsResourceEnum.faxSendCfg,
          settingName: ConstraintsSettingEnum.faxSendMethod
        },
        [`${PolicyAttributeEnum.Fax_Send}.settings.modem-jbig-compression`]: {
          resource: ConstraintsResourceEnum.faxModemCfg,
          settingName: ConstraintsSettingEnum.analogFaxOperationJbig
        },
        [`${PolicyAttributeEnum.Fax_Send}.settings.common-notification-address`]:
          {
            resource: ConstraintsResourceEnum.jobTicketCfgDefFax,
            settingName: ConstraintsSettingEnum.notificationAddress
          },
        [`${PolicyAttributeEnum.Fax_Send}.settings.common-notification-condition`]:
          {
            resource: ConstraintsResourceEnum.jobTicketCfgDefFax,
            settingName: ConstraintsSettingEnum.notificationCondition
          },
        [`${PolicyAttributeEnum.Fax_Send}.settings.common-notification-method`]:
          {
            resource: ConstraintsResourceEnum.jobTicketCfgDefFax,
            settingName: ConstraintsSettingEnum.notificationMode
          }
      }
    },
    [PolicyAttributeEnum.PC_Fax_Send]: {
      resource: ConstraintsResourceEnum.faxSendCfg,
      settingName: ConstraintsSettingEnum.pcFaxSendEnabled
    },
    [PolicyAttributeEnum.Very_Low_Action_Black]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.blackVeryLowAction
    },
    [PolicyAttributeEnum.Very_Low_Action_Color]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.colorVeryLowAction
    },
    [PolicyAttributeEnum.System_Contact]: {
      resource: ConstraintsResourceEnum.jetDirectService,
      settingName: ConstraintsSettingEnum.systemContact
    },
    [PolicyAttributeEnum.System_Location]: {
      resource: ConstraintsResourceEnum.jetDirectService,
      settingName: ConstraintsSettingEnum.systemLocation
    },
    [PolicyAttributeEnum.Hp_Web_Svc]: {
      resource: ConstraintsResourceEnum.avatarRegistration,
      settingName: ConstraintsSettingEnum.cloudServicesEnabled
    },
    [PolicyAttributeEnum.Hp_Jet_Adv]: {
      resource: ConstraintsResourceEnum.fleetProxy_legacyAttributes,
      settingName: ConstraintsSettingEnum.hpJetAdvantage
    },
    [PolicyAttributeEnum.Smart_Cloud_Print]: {
      resource: ConstraintsResourceEnum.fleetProxy_legacyAttributes,
      settingName: ConstraintsSettingEnum.cloudPrint
    },
    [PolicyAttributeEnum.Proxy_Server]: {
      resource: ConstraintsResourceEnum.proxyCfg,
      settingName: ConstraintsSettingEnum.httpProxyEnabled
    },
    [PolicyAttributeEnum.App_Deployment]: {
      resource: ConstraintsResourceEnum.workPathSolutionMgrCfg,
      settingName: ConstraintsSettingEnum.workpathEnabledConfig
    },
    [PolicyAttributeEnum.Energy_Settings]: {
      resource: ConstraintsResourceEnum.powerCfg,
      settingName: ConstraintsSettingEnum.shutdownTimeoutInMinutes
    },
    [PolicyAttributeEnum.Ca_Certificate]: {
      resource: ConstraintsResourceEnum.certificateCaCfg,
      settingName: ConstraintsSettingEnum.supportCaCertificate
    },
    [PolicyAttributeEnum.Id_Certificate]: {
      resource: ConstraintsResourceEnum.certificateIdCfg,
      settingName: ConstraintsSettingEnum.supportIdCertificate
    },
    // Print_Anywhere_Securely
    [PolicyAttributeEnum.CORS]: {
      resource: ConstraintsResourceEnum.systemConfig,
      settingName: ConstraintsSettingEnum.corsEnabled
    },
    [PolicyAttributeEnum.WiFi_Direct]: {
      resource: ConstraintsResourceEnum.wifiDirectConfig,
      settingName: ConstraintsSettingEnum.enabled,
      deviceSettings: {
        [`${PolicyAttributeEnum.WiFi_Direct}.channel`]: {
          resource: ConstraintsResourceEnum.wifiDirectConfig,
          settingName: ConstraintsSettingEnum.preferredChannel
        }
      }
    },
    [PolicyAttributeEnum.Sleep_Schedule]: {
      resource: ConstraintsResourceEnum.sleepCfg,
      settingName: ConstraintsSettingEnum.sleepSchedules
    },
    // Google_Cloud_Print
    // Quick_Sets
    [PolicyAttributeEnum.IP_Fax]: {
      resource: ConstraintsResourceEnum.faxSendCfg,
      settingName: ConstraintsSettingEnum.faxSendEnabled
    },
    [PolicyAttributeEnum.Support_Contact]: {
      resource: ConstraintsResourceEnum.deviceCfg,
      settingName: ConstraintsSettingEnum.supportContact
    },
    [PolicyAttributeEnum.Pin_Protection]: {
      resource: ConstraintsResourceEnum.storeJobMgtCfg,
      settingName: ConstraintsSettingEnum.cancelJobsWithoutPinProtection, // one of 4 settings
      deviceSettings: {
        [`${PolicyAttributeEnum.Pin_Protection}.four-digits`]: {
          resource: ConstraintsResourceEnum.storeJobMgtCfg,
          settingName: ConstraintsSettingEnum.requireFourDigitPin
        },
        [`${PolicyAttributeEnum.Pin_Protection}.scan-job`]: {
          resource: ConstraintsResourceEnum.storeJobMgtCfg,
          settingName: ConstraintsSettingEnum.requireScanJobPinProtection
        },
        [`${PolicyAttributeEnum.Pin_Protection}.print-job`]: {
          resource: ConstraintsResourceEnum.storeJobMgtCfg,
          settingName: ConstraintsSettingEnum.requirePrintJobPinProtection
        },
        [`${PolicyAttributeEnum.Pin_Protection}.allow-cancel`]: {
          resource: ConstraintsResourceEnum.storeJobMgtCfg,
          settingName: ConstraintsSettingEnum.cancelJobsWithoutPinProtection
        }
      }
    },
    [PolicyAttributeEnum.Copy_Job_Build]: {
      resource: ConstraintsResourceEnum.jobTicketCreation,
      settingName: ConstraintsSettingEnum.promptForAdditionalPages
    },
    [PolicyAttributeEnum.Copy_Color_Mode]: {
      resource: ConstraintsResourceEnum.jobTicketCfgDefCopy,
      settingName: ConstraintsSettingEnum.srcScanColorMode
    },
    [PolicyAttributeEnum.Cartridge_Protection]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.antiTheftEnabled
    },
    // Consumable_Access_Ctrl
    [PolicyAttributeEnum.Transfer_Kit_Threshold]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowThresholdsPerSupply
    },
    [PolicyAttributeEnum.Fuser_Kit_Threshold]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowThresholdsPerSupply
    },
    [PolicyAttributeEnum.Maintenance_Kit_Threshold]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.lowThresholdsPerSupply
    },
    [PolicyAttributeEnum.Very_Low_Action_Transfer_Kit]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.veryLowActionsPerSupply
    },
    [PolicyAttributeEnum.Very_Low_Action_Fuser_Kit]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.veryLowActionsPerSupply
    },
    [PolicyAttributeEnum.Very_Low_Action_Maintenance_Kit]: {
      resource: ConstraintsResourceEnum.supplyConfigPvt,
      settingName: ConstraintsSettingEnum.veryLowActionsPerSupply
    },
    [PolicyAttributeEnum.Very_Low_Action_Doc_Feeder_Kit]: {
      resource: ConstraintsResourceEnum.scanSupplies,
      settingName: ConstraintsSettingEnum.suppliesListVeryLowAction
    },
    [PolicyAttributeEnum.Device_Access_Ctrl]: {
      resource: ConstraintsResourceEnum.rbacCfg,
      settingName: ConstraintsSettingEnum.rolePermissionMap,
      deviceSettings: {
        [`${PolicyAttributeEnum.Device_Access_Ctrl}.auto-sign-out`]: {
          resource: ConstraintsResourceEnum.controlPanelCfg,
          settingName: ConstraintsSettingEnum.restartAfterJobStart
        },
        [`${PolicyAttributeEnum.Device_Access_Ctrl}.sign-out-after`]: {
          resource: ConstraintsResourceEnum.controlPanelCfg,
          settingName: ConstraintsSettingEnum.restartAfterJobStart
        }
      }
    },
    [PolicyAttributeEnum.Bootloader_Password]: {
      resource: ConstraintsResourceEnum.bootloaderPassword,
      settingName: ConstraintsSettingEnum.currentPassword
    },
    [PolicyAttributeEnum.Auth_802_1X_Wired]: {
      resource: ConstraintsResourceEnum.adapterConfigs,
      settingName: ConstraintsSettingEnum.ethDot1xAuthConfigServerIdentity,
      deviceSettings: {
        [`${PolicyAttributeEnum.Auth_802_1X_Wired}.reauthenticate`]: {
          resource: ConstraintsResourceEnum.adapterConfigs,
          settingName: ConstraintsSettingEnum.ethDot1xAuthConfigReauthenticate
        },
        [`${PolicyAttributeEnum.Auth_802_1X_Wired}.on-failure`]: {
          resource: ConstraintsResourceEnum.adapterConfigs,
          settingName:
            ConstraintsSettingEnum.ethDot1xAuthConfigBlockNetworkOnAuthFailure
        }
      }
    },
    [PolicyAttributeEnum.Auth_802_1X_Wireless]: {
      resource: ConstraintsResourceEnum.wirelessConfig,
      settingName: ConstraintsSettingEnum.dot1xAuthConfigServerIdentity,
      deviceSettings: {
        [`${PolicyAttributeEnum.Auth_802_1X_Wireless}.leap`]: {
          resource: ConstraintsResourceEnum.wirelessConfig,
          settingName: ConstraintsSettingEnum.dot1xAuthConfigEapLeapEnabled
        },
        [`${PolicyAttributeEnum.Auth_802_1X_Wireless}.encryption-strength`]: {
          resource: ConstraintsResourceEnum.wirelessConfig,
          settingName: ConstraintsSettingEnum.dot1xAuthConfigEncryptionStrength
        }
      }
    },

    // System_Logging
    // Guest_Access
    // Certificate_Management_Service
    // HTTPS
    // Cancel_Print_Jobs
    // Display_Job_Status
    // Erase_Data
    // Extended_Signature_Verification
    // NFC
    // Wireless_Radio_State
    // Ftp_Server
    // Group_One_Pin
    // Group_Two_Pin
    // Ram_Disc_Cfg
    // POP3
    // HP_Connection_Inspector
    // Sip_Server_Settings
    // Fax_Speed_Dial_Lock
    // Secure_Disk_Password
    // Email_Restriction
    // Job_Behavior
    // Control_Panel_Logout_Policy
    // Tpm_Status
    [PolicyAttributeEnum.Fips_140_Compliance]: {
      resource: ConstraintsResourceEnum.tlsConfig,
      settingName: ConstraintsSettingEnum.fipsMode
    },
    // IP_Security
    // WiFi_Direct_Print
    [PolicyAttributeEnum.Retain_Jobs_After_Reboot]: {
      resource: ConstraintsResourceEnum.storeJobMgtCfg,
      settingName: ConstraintsSettingEnum.temporaryJobRetentionPolicy
    },
    [PolicyAttributeEnum.Temp_Job_Storage_Limit]: {
      resource: ConstraintsResourceEnum.storeJobMgtCfg,
      settingName: ConstraintsSettingEnum.temporaryJobLimit
    },
    // Identity_Certificate
    [PolicyAttributeEnum.Smb_Cifs]: {
      resource: ConstraintsResourceEnum.networkFolderCfg,
      settingName: ConstraintsSettingEnum.smbVersion1Enabled
    }
  }

  modelName: string
  plugInName: string
  description: string
  futureSmart: boolean
  resources: ConstraintsResource[]

  constructor(modelName: string) {
    let constraints
    this.modelName = modelName
    switch (
      devicePluginModelNumber.find((x) =>
        x.deviceModelNumberList.includes(modelName)
      )?.plugInName
    ) {
      case 'HPOfficeJetPro_MF-DI':
        constraints = HPOfficeJetPro_MF_DI
        break
      case 'HPEnterprise_MF-EN':
        constraints = HPEnterprise_MF_EN
        break
      case 'HPEnterprise_SF-EN':
        constraints = HPEnterprise_SF_EN
        break
      case 'HPEnterprise_MF-EN_1':
        constraints = HPEnterprise_MF_EN_1
        break
      case 'HPEnterprise_SF-EN_1':
        constraints = HPEnterprise_SF_EN_1
        break
      case 'HPLaserJetPro_MF-DI':
        constraints = HPLaserJetPro_MF_DI
        break
      case 'HPLaserJetPro_SF-DI':
        constraints = HPLaserJetPro_SF_DI
        break
      case 'HP_Unified_DesignJet_MF-DI':
        constraints = HP_DesignJet_MF_DI
        break
      case 'HP_Unified_DesignJet_SF-DI':
        constraints = HP_DesignJet_SF_DI
        break
      case 'HP_Unified_PageWidePro_OfficeJetPro_MF-DI':
        constraints = HP_PWP_OfficeJetPro_MF_DI
        break
      case 'HP_Unified_PageWidePro_OfficeJetPro_SF-DI':
        constraints = HP_PWP_OfficeJetPro_SF_DI
        break
      case 'HPLaserJetPro_MF-DI_1':
        constraints = HPLaserJetPro_MF_DI_1
        break
      case 'HPLaserJetPro_SF-DI_1':
        constraints = HPLaserJetPro_SF_DI_1
        break
      case 'HP_Unified_LaserJet_MF-DI':
        constraints = HP_LaserJet_MF_DI
        break
      case 'HP_Unified_LaserJet_SF-DI':
        constraints = HP_LaserJet_SF_DI
        break
      case 'HPLaserJetPro_MF-DL':
        constraints = HPLaserJetPro_MF_DL
        break
      case 'HPLaserJetPro_SF-DL':
        constraints = HPLaserJetPro_SF_DL
        break

      default:
        this.plugInName = 'policy'
        this.description = 'DefaultPolicyConstraints'
        this.resources = DefaultPolicyConstraints
        return
    }
    this.plugInName = constraints.plugInName
    this.description = constraints.description
    this.futureSmart = this.plugInName.startsWith('HPEnterprise')
    this.resources = constraints.supportedDeviceResources
  }

  getSetting(
    resource: ConstraintsResourceEnum | string,
    settingName: ConstraintsSettingEnum | string
  ): ConstraintsSetting {
    const res = this.resources.find((x) => x.resource === resource)
    if (res) {
      const set = res.settings.find((x) => x.name === settingName)
      if (set && !set.excludeDeviceModelNumberList?.includes(this.modelName)) {
        return set
      }
    }
    return null
  }

  filterSettingOptions(
    optionItems: Item[],
    resource: ConstraintsResourceEnum | string,
    settingName: ConstraintsSettingEnum | string
  ): Item[] {
    const set = this.getSetting(resource, settingName)
    const items = set?.items || set
    if (!items?.options) {
      return []
    }
    const options = items.options.map((x) => x.value)
    return optionItems.filter((x) => options.includes(x.option))
  }

  getSettingMin(
    resource: ConstraintsResourceEnum | string,
    settingName: ConstraintsSettingEnum | string
  ): number {
    const set = this.getSetting(resource, settingName)
    return set?.minimum || 0
  }

  getSettingMax(
    resource: ConstraintsResourceEnum | string,
    settingName: ConstraintsSettingEnum | string
  ): number {
    const set = this.getSetting(resource, settingName)
    return set?.maximum || 0
  }

  isPolicyAttributeSupported(attr: PolicyAttributeEnum | string): boolean {
    const isAttributeSupported = (attr) => {
      const found = PolicyConstraints.attributeInfo[attr]
      return found ? !!this.getSetting(found.resource, found.settingName) : true
    }
    if (!isAttributeSupported(attr)) {
      return false
    }
    // some attributes require more checks
    switch (attr) {
      case PolicyAttributeEnum.Cartridge_Threshold_Cyan:
      case PolicyAttributeEnum.Cartridge_Threshold_Magenta:
      case PolicyAttributeEnum.Cartridge_Threshold_Yellow:
      case PolicyAttributeEnum.Drum_Threshold_Cyan:
      case PolicyAttributeEnum.Drum_Threshold_Magenta:
      case PolicyAttributeEnum.Drum_Threshold_Yellow:
        // color attributes are supported only if the device supports color
        return isAttributeSupported(PolicyAttributeEnum.Very_Low_Action_Color)
      case PolicyAttributeEnum.Home_Screen_App_Fs4:
        return this.futureSmart // FS only
      case PolicyAttributeEnum.Home_Screen_App_Pro:
        return !this.futureSmart // Non-FS only
    }
    return true
  }

  isPolicyAttributeSettingSupported(
    attr: PolicyAttributeEnum | string,
    deviceSettingId: string
  ): boolean {
    const found = PolicyConstraints.attributeInfo[attr]
    if (found?.deviceSettings) {
      const opt = found.deviceSettings[deviceSettingId]
      if (opt) {
        return !!this.getSetting(opt.resource, opt.settingName)
      }
    }
    return true
  }
}

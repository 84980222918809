import { Item } from '../PoliciesConfigurationEnum'

const sizeConversion = (key: string, byteValue: string) => {
  return {
    value: byteValue,
    label: 'policy.settings.size-array-labels.' + key
  }
}

export default class BytesOutputItems {
  static allByteItems: Item[] = [
    sizeConversion('no-limit', '0'),
    sizeConversion('512-kb', '524288'),
    sizeConversion('1-mb', '1048576'),
    sizeConversion('2-mb', '2097152'),
    sizeConversion('4-mb', '4194304'),
    sizeConversion('8-mb', '8388608'),
    sizeConversion('16-mb', '16777216'),
    sizeConversion('24-mb', '25165824'),
    sizeConversion('25-mb', '26214400'),
    sizeConversion('32-mb', '33554432')
  ]

  static smtpBytesSizeItems: Item[] = BytesOutputItems.allByteItems
    .slice(0, 8)
    .concat(BytesOutputItems.allByteItems[9])

  static bytesSizeItems: Item[] = BytesOutputItems.allByteItems
    .slice(0, 6)
    .concat(BytesOutputItems.allByteItems[8])
}

import React, { useContext, useEffect, useState, useMemo, memo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Button } from '@veneer/core'
import { MultiSelectCheckboxDescription } from 'context/policies/PoliciesConfigurationEnum'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { FlexRow, TextBlack, PreviewValue } from 'styles/styles'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import WarningMessage from '../WarningMessage'
import MultiSelectCheckboxModal from './MultiSelectCheckboxModal'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const MultiSelectCheckboxControl = (props) => {
  const description: MultiSelectCheckboxDescription = props.description

  const { t } = useContext(configContext)
  const { isEnabled, addError, removeError, displayAllErrors } =
    useContext(settingsContext)

  const getLocalized = (key: string, params?): string => {
    return t(`${props.localizationPath}.${key}`, params)
  }
  const tableItems = description.items.map((x) => {
    return {
      value: x.value,
      label: getLocalized(x.label)
    }
  })

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, props.data.deviceSettings)
  )
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const showError = displayError || displayAllErrors
  const emptyArray = !value.length
  const enabled = isEnabled(description.attribute)

  useEffect(() => {
    setValue(PoliciesHelper.getData(description, props.data.deviceSettings))
  }, [props.data.deviceSettings])

  useEffect(() => {
    const err = enabled && emptyArray
    setError(showError && err)
    err
      ? addError(props.id, description.attribute, showError)
      : removeError(props.id, description.attribute)
  }, [enabled, emptyArray, showError])

  const selectedItems = useMemo(() => {
    return value.map((x) => {
      const found = tableItems.find((y) => y.value === x)
      return found ? found.label : ''
    })
  }, [value])

  const l18n = useMemo(() => {
    return {
      title: getLocalized(
        value.length ? description.change : description.select
      ),
      description: getLocalized(description.description),
      column: getLocalized(description.tableColumn),
      button: t(value.length ? 'common.save' : 'common.select'),
      cancel: t('common.cancel'),
      selected: (count) => t('common.n-items-selected', { count })
    }
  }, [emptyArray])

  const onChange = (v) => {
    setDisplayError(true)
    const deviceSettings = [...props.data.deviceSettings]
    PoliciesHelper.setData(description, deviceSettings, v)
    props.onAttributeChange({ ...props.data, deviceSettings })
  }

  const disabledHelp = !enabled && !!description.disabledHelp
  return (
    <>
      {description.label && (
        <FlexRow className={'alignCenter marginBottom8'}>
          <TextBlack>{getLocalized(description.label)}</TextBlack>
          <FlexRow className={'paddingLeft4 lineBreak'}>
            {(description.help || disabledHelp) && (
              <HelpButton
                enabled={enabled || disabledHelp}
                description={getLocalized(
                  disabledHelp ? description.disabledHelp : description.help
                )}
              />
            )}
          </FlexRow>
        </FlexRow>
      )}

      <FlexRow className={'alignCenter'}>
        <Button
          appearance="secondary"
          disabled={!enabled}
          onClick={() => setShowModal(true)}
        >
          {getLocalized(value.length ? description.change : description.select)}
        </Button>
        <TextBlack className={'marginLeft12'} disabled={!enabled}>
          {getLocalized(description.selected, {
            count: value.length
          })}
        </TextBlack>
      </FlexRow>
      {error && (
        <WarningMessage
          id={description.attribute + '.error'}
          type={MessageTypesEnum.ERROR}
          message={getLocalized(description.error)}
        />
      )}

      <PreviewValue className={'marginTop8'} disabled={!enabled}>
        {selectedItems.join(', ')}
      </PreviewValue>
      {showModal && (
        <MultiSelectCheckboxModal
          id={description.attribute}
          onClose={() => setShowModal(false)}
          items={tableItems}
          value={value}
          onChange={(selectedItems) => onChange(selectedItems)}
          l18n={l18n}
        />
      )}
    </>
  )
}

export default memo(MultiSelectCheckboxControl)

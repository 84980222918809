import React, { useContext, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import {
  Button,
  ButtonGroup,
  ColumnIndexTypes,
  Modal,
  Search,
  Select,
  Table,
  ProgressIndicator
} from '@veneer/core'
import { SortTypes, SelectionStateTypes } from '@veneer/core/dist/scripts/table'
import primitives from '@veneer/primitives'
import configContext from 'context/config/configContext'
import { getDecoratedName } from 'common/decoratedName'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import { abcSort, getRowSelectAllState } from 'common/utilities'
import { Item } from 'context/policies/PoliciesConfigurationEnum'
import configData from 'common/config/settings.json'
import { FlexRowWithSpace, ModalTableHeight } from 'styles/styles'
import 'components/policies/modal/index.scss'

export enum FwUpdateEnum {
  MODELS = 'models',
  VERSION = 'version',
  UI_MODEL = 'uiModel',
  UI_VERSION = 'uiVersion',
  NAME = 'name', // from configData.fwuModels
  NUMBERS = 'numbers' // from configData.fwuModels
}

export const isThisModel = (x, model) => x[FwUpdateEnum.MODELS]?.includes(model)

enum FwUpdateIntEnum {
  VERSIONS = 'versions', // from SOAR + Latest
  LOADED = 'loaded' // SOAR data loaded
}

const defSortOrder = 'ascending' as SortTypes
const orderDescending = 'descending' as SortTypes

const soarUrl = 'https://h20614.www2.hp.com/ediags/solutions/software/v4'
const swTypeFw = 'FULLFW'
const swMaxVerIgnore = [4, 11, 0, 1] // FS versions for f/w update: 4.11.0.2+

export const getSoarVersions = (
  productNumber,
  setResult: (productNumber, items: Item[]) => void
) => {
  const isVerOk = (ver) => {
    const verArr = ver.split('.').map((x) => +x)
    for (let i = 0; i < verArr.length; i++) {
      if (verArr[i] !== swMaxVerIgnore[i]) {
        return verArr[i] > swMaxVerIgnore[i]
      }
    }
  }
  const sortVersion = (versions) =>
    abcSort(
      versions
        .filter(
          (x: { Type: string; Identifier: string }) =>
            x.Type === swTypeFw && isVerOk(x.Identifier)
        )
        .map(
          (x: {
            IdentifierType: string
            Identifier: string
            Version: string
          }) => ({
            label: `${x.IdentifierType} ${x.Identifier} - ${x.Version}`,
            value: x.Version
          })
        ),
      (x) => x.label,
      orderDescending
    )

  axios
    .get(soarUrl, { params: { productNumber } })
    .then((res) =>
      setResult(
        productNumber,
        res.status === 200 && res.data ? (sortVersion(res.data) as Item[]) : []
      )
    )
    .catch(() => setResult(productNumber, []))
}

const moreItem = (icon, color?) => ({
  label: ' ',
  value: 'more-versions-value',
  icon: <span style={{ color }}>{icon}</span>
})

const FwUpdateVersionModal = (props) => {
  const { isWex } = useContext(configContext)
  const { value, getLocalized, onClose, onChange, latestVer } = props
  const moreVer = moreItem(
    getLocalized('more-versions'),
    isWex ? primitives.color.darkNavy7 : primitives.color.hpBlue7
  )
  const [searchValue, setSearchValue] = useState('')
  const [orderType, setOrderType] = useState(defSortOrder)
  const [tableData, setTableData] = useState(null)

  const findVersion = (rowConfig) =>
    rowConfig[FwUpdateIntEnum.VERSIONS]?.find(
      ({ value }) => value === rowConfig[FwUpdateEnum.VERSION]
    )

  const versionControl = (rowConfig) => {
    const error = rowConfig.selected && !findVersion(rowConfig)
    return (
      <Select
        options={rowConfig[FwUpdateIntEnum.VERSIONS]}
        disabled={!rowConfig.selected}
        clearIcon={false}
        id={
          'id-fw-update-modal-versions.' +
          rowConfig.item[FwUpdateEnum.NUMBERS][0]
        }
        placeholder={getLocalized('common.select-option')}
        helperText={error ? getLocalized('common.errors.not-selected') : null}
        error={error}
        value={[rowConfig[FwUpdateEnum.VERSION]]}
        onChange={({ value: v }) => onVersion(v, rowConfig)}
      />
    )
  }

  useEffect(
    () =>
      setTableData(
        configData.fwuModels.map((item, id) => {
          const rowConfig = {
            item,
            [FwUpdateIntEnum.VERSIONS]: [latestVer, moreVer],
            [FwUpdateEnum.VERSION]: latestVer.value,
            [FwUpdateIntEnum.LOADED]: false,
            selected: false
          }
          return {
            id,
            [FwUpdateEnum.UI_MODEL]: item[FwUpdateEnum.NAME],
            [FwUpdateEnum.UI_VERSION]: versionControl(rowConfig),
            rowConfig
          }
        })
      ),
    []
  )

  const onVersion = (newVersion, rowConfig) => {
    setTableData((data) =>
      data.map((x) => {
        if (x.rowConfig !== rowConfig) {
          return x
        }
        if (newVersion !== moreVer.value) {
          x.rowConfig[FwUpdateEnum.VERSION] = newVersion
        } else if (!x.rowConfig[FwUpdateIntEnum.LOADED]) {
          getVersions(rowConfig)
        }
        return { ...x, [FwUpdateEnum.UI_VERSION]: versionControl(x.rowConfig) }
      })
    )
  }

  useEffect(
    () =>
      setTableData((data) =>
        data
          ? data.map((x) => {
              const found = value.find((y) =>
                x.rowConfig.item[FwUpdateEnum.NUMBERS].some((z) =>
                  isThisModel(y, z)
                )
              )
              x.rowConfig.selected = !!found
              if (found) {
                x.rowConfig[FwUpdateEnum.VERSION] = found[FwUpdateEnum.VERSION]
                if (found[FwUpdateEnum.VERSION] !== latestVer.value) {
                  x.rowConfig[FwUpdateIntEnum.VERSIONS] = [
                    latestVer,
                    {
                      label: found[FwUpdateEnum.UI_VERSION],
                      value: found[FwUpdateEnum.VERSION]
                    },
                    moreVer
                  ]
                }
              }
              return {
                ...x,
                [FwUpdateEnum.UI_VERSION]: versionControl(x.rowConfig)
              }
            })
          : data
      ),
    [value]
  )

  const filteredData = useMemo(() => {
    const searchLowerCase = searchValue.toLowerCase()
    return searchLowerCase.length && tableData
      ? tableData
          .filter((x) =>
            x.rowConfig.item[FwUpdateEnum.NAME]
              .toLowerCase()
              .includes(searchLowerCase)
          )
          .map((x) => {
            const name = getDecoratedName(
              x.rowConfig.item[FwUpdateEnum.NAME],
              searchLowerCase,
              isWex
            )
            return name.length
              ? { ...x, [FwUpdateEnum.UI_MODEL]: <div>{name}</div> }
              : x
          })
      : tableData
  }, [tableData, searchValue])

  const sortedData = useMemo(
    () =>
      filteredData
        ? abcSort(
            filteredData,
            (x) => x.rowConfig.item[FwUpdateEnum.NAME],
            orderType
          )
        : filteredData,
    [filteredData, orderType]
  )

  const getVersions = (rowConfig) => {
    rowConfig[FwUpdateIntEnum.LOADED] = true
    rowConfig[FwUpdateIntEnum.VERSIONS] = rowConfig[
      FwUpdateIntEnum.VERSIONS
    ].filter((y) => y.value !== moreVer.value)
    rowConfig[FwUpdateIntEnum.VERSIONS].push(moreItem(<ProgressIndicator />))

    getSoarVersions(
      rowConfig.item[FwUpdateEnum.NUMBERS][0],
      (productNumber, versions) =>
        setTableData((data) =>
          data.map((x) => {
            if (x.rowConfig.item[FwUpdateEnum.NUMBERS][0] === productNumber) {
              x.rowConfig[FwUpdateIntEnum.VERSIONS] = versions.length
                ? [latestVer, ...versions]
                : []
              return {
                ...x,
                [FwUpdateEnum.UI_VERSION]: versionControl(x.rowConfig)
              }
            } else {
              return x
            }
          })
        )
    )
  }

  const onSelect = (event, rowId) => {
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = event.target.checked
      found[FwUpdateEnum.UI_VERSION] = versionControl(found.rowConfig)
      setTableData([...tableData])
    }
  }

  const onSelectAll = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        row[FwUpdateEnum.UI_VERSION] = versionControl(row.rowConfig)
        return row
      })
    )
  }

  const selectedItems = useMemo(
    () => tableData?.filter((x) => x.rowConfig.selected) || [],
    [tableData]
  )

  const enableSave = useMemo(
    () =>
      selectedItems.length &&
      selectedItems.every(({ rowConfig }) => findVersion(rowConfig)),
    [selectedItems]
  )

  const rowSelectAllState = useMemo(
    () => getRowSelectAllState(selectedItems.length, tableData?.length),
    [selectedItems]
  )

  const onOK = () => {
    if (enableSave) {
      onChange(
        selectedItems.map((x) => ({
          [FwUpdateEnum.MODELS]: x.rowConfig.item[FwUpdateEnum.NUMBERS],
          [FwUpdateEnum.UI_MODEL]: x.rowConfig.item[FwUpdateEnum.NAME],
          [FwUpdateEnum.VERSION]: x.rowConfig[FwUpdateEnum.VERSION],
          [FwUpdateEnum.UI_VERSION]: findVersion(x.rowConfig)?.label
        }))
      )
      onClose()
    }
  }

  const tableColumns = useMemo(() => {
    const id = 'id'
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id, label: id, index },
      {
        id: FwUpdateEnum.UI_MODEL,
        label: getLocalized('model-name'),
        sortable: true
      },
      {
        id: FwUpdateEnum.UI_VERSION,
        label: getLocalized('firmware-version'),
        sortable: false
      }
    ]
  }, [])

  const add = !value.length
  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized(add ? 'add-model-versions' : 'change-model-versions')}
      className={'extra-large-policy-modal'}
      data-testid={'id-fw-update-modal'}
      footer={
        <FlexRowWithSpace className={'alignCenter fullWidth'}>
          <ButtonGroup>
            <Button disabled={!enableSave} onClick={onOK}>
              {getLocalized(add ? 'common.add' : 'common.save')}
            </Button>
            <Button appearance={'secondary'} onClick={onClose}>
              {getLocalized('common.cancel')}
            </Button>
          </ButtonGroup>
          <p>
            {getLocalized('common.n-items-selected', {
              count: selectedItems.length
            })}
          </p>
        </FlexRowWithSpace>
      }
    >
      <p className={'paddingBottom16'}>{getLocalized('model-select')}</p>
      <Search
        id={'id-fw-update-modal-search'}
        className={'maxSearchWidth paddingBottom16'}
        placeholder={getLocalized('common.search')}
        value={searchValue}
        onChange={(value) => setSearchValue(value)}
      />
      <ModalTableHeight>
        <Table
          columns={tableColumns}
          data={sortedData || []}
          onSort={(_, { type }) => setOrderType(type)}
          onSelect={onSelect}
          onSelectAllPageItems={onSelectAll}
          rowSelectAllState={rowSelectAllState as SelectionStateTypes}
          loading={sortedData === null}
          loadingDataLength={10}
          rowSelector={'multiSelection'}
          className={'paddingThirdCol4'}
          data-testid={'id-fw-update-modal-table'}
          preferences={{
            sortBy: { id: FwUpdateEnum.UI_MODEL, type: orderType },
            width: [
              { columnId: FwUpdateEnum.UI_MODEL, width: 320 },
              { columnId: FwUpdateEnum.UI_VERSION, width: 320 }
            ]
          }}
          i18n={Retrievei18nItems()}
        />
      </ModalTableHeight>
    </Modal>
  )
}

export default FwUpdateVersionModal

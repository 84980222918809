import { Item } from '../PoliciesConfigurationEnum'

const imageSetting = (setting, value) => {
  return { value, label: `policy.settings.${setting}.${value}` }
}

export default class ImageSettingItems {
  static imageSharpnessItems: Item[] = Array.from(Array(5)).map((_, i) =>
    imageSetting('sharpness', (i + 1).toString())
  )
  static imageDarknessItems: Item[] = Array.from(Array(9)).map((_, i) =>
    imageSetting('darkness', (i + 1).toString())
  )
  static imageContrastItems: Item[] = Array.from(Array(9)).map((_, i) =>
    imageSetting('contrast', (i + 1).toString())
  )
  static imageBgCleanupItems: Item[] = Array.from(Array(9)).map((_, i) =>
    imageSetting('bg-cleanup', (i + 1).toString())
  )
}

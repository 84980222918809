import React, { useContext, memo, useMemo, useEffect } from 'react'
import settingsContext from 'context/settings/settingsContext'
import {
  DropBoxDescription,
  PolicyItemPrimitives,
  SwitchDescription
} from 'context/policies/PoliciesConfigurationEnum'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import PoliciesGenerator from 'context/policies/PoliciesGenerator'

const SwitchControl = (props) => {
  const description: SwitchDescription = props.description

  const modeDescription: DropBoxDescription = {
    type: PolicyItemPrimitives.Dropbox,
    attribute: description.attribute,
    items: []
  }
  const {
    attributes,
    data: { deviceSettings },
    onAttributeChange,
    id
  } = props

  const { removeError } = useContext(settingsContext)

  // Fetch mode data
  const mode = useMemo(() => {
    return PoliciesHelper.getData(modeDescription, deviceSettings)
  }, [deviceSettings])

  //Remove mode dependent setting Errors when mode changes
  useEffect(() => {
    Object.keys(description.settingsMap)
      .filter((key) => key !== mode)
      .forEach((key) => {
        description.settingsMap[key].forEach((setting) => {
          removeError(id, setting.attribute)
        })
      })
  }, [mode])

  const generateSettings = (
    data,
    settings,
    attributes,
    onAttributeChange,
    onSettingsChanges
  ) => {
    const generatedSettings = onAttributeChange
      ? PoliciesGenerator.generateDeviceSettings(
          data,
          {
            deviceSettings: settings,
            id: id
          },
          attributes,
          onAttributeChange,
          onSettingsChanges
        )
      : PoliciesGenerator.generateDeviceSettingsPreview(data, {
          deviceSettings: settings,
          id: id
        })

    return generatedSettings.map((item) => (
      <div key={item.key} className={item.className}>
        {item.form}
      </div>
    ))
  }

  const RenderControls = useMemo(() => {
    const settings = description.settingsMap[mode] || []
    return generateSettings(
      props.data,
      settings,
      attributes,
      onAttributeChange,
      props.onSettingsChanges
    )
  }, [mode, attributes])

  return <div>{RenderControls}</div>
}

export default memo(SwitchControl)

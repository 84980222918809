import { PolicyAttributeEnum } from '../PoliciesCategories'
import { Item } from '../PoliciesConfigurationEnum'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

const emailNotificationCondition = (x) => {
  return {
    value: 'notification-condition.' + x,
    label: 'policy.settings.notification-condition.' + x
  }
}

const emailNotificationMethod = (x) => {
  return {
    value: 'notification-method.' + x,
    label: 'policy.settings.notification-method.' + x,
    option: x
  }
}

export default class PrintEmailNotificationItems {
  static emailNotificationConditions: Item[] = [
    emailNotificationCondition('none'),
    emailNotificationCondition('fail'),
    emailNotificationCondition('done')
  ]
  static emailNotificationMethodItems: Item[] = [
    emailNotificationMethod('print'),
    emailNotificationMethod('email')
  ]

  static emailNotificationMethods = (
    constraints?: PolicyConstraints,
    id?: PolicyAttributeEnum
  ): Item[] => {
    if (!constraints || !id) {
      return PrintEmailNotificationItems.emailNotificationMethodItems
    }
    return constraints.filterSettingOptions(
      PrintEmailNotificationItems.emailNotificationMethodItems,
      id === PolicyAttributeEnum.Fax_Receive
        ? ConstraintsResourceEnum.jobTicketCfgReceiveFax
        : ConstraintsResourceEnum.jobTicketCfgDefFax,
      ConstraintsSettingEnum.notificationMode
    )
  }

  static isEmailNotification = (method: string): boolean =>
    method === PrintEmailNotificationItems.emailNotificationMethodItems[1].value

  static isNotifyCondition = (condition: string): boolean =>
    condition !==
    PrintEmailNotificationItems.emailNotificationConditions[0].value
}

import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Table, Select, IconCheckmark, Checkbox } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import {
  AccessControlPermissionDescription,
  ArrayAttributeDescription,
  PolicyItemPrimitives
} from 'context/policies/PoliciesConfigurationEnum'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import {
  FlexColumn,
  TextBlack,
  PreviewLabel,
  MaxTableHeight
} from 'styles/styles'
import 'styles/global.scss'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import { TABLE_CONSTANTS } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import {
  DeviceAccessEnum,
  DeviceAccessRolesEnum,
  getDefaultRoleName
} from './DeviceAccessEnums'
import DevicePermissionItems from './DevicePermissionItems'

const rolesDescription: ArrayAttributeDescription = {
  type: PolicyItemPrimitives.AccessCtrlRoles,
  attribute: `${PolicyAttributeEnum.Device_Access_Ctrl}.roles`
}

const ControlPanelAccessControl = (props) => {
  const description: AccessControlPermissionDescription = props.description
  const {
    compliance,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { label, items, attribute, constraints } = description

  const { tt } = useContext(configContext)
  const { isEnabled } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const [tableData, setTableData] = useState([])

  const enabled = isEnabled(attribute)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const strValue = useMemo(() => JSON.stringify(value), [value])

  // Fetch roles data
  const [rolesValue, setRolesValue] = useState(
    PoliciesHelper.getData(rolesDescription, deviceSettings)
  )

  useEffect(() => {
    const newValue = PoliciesHelper.getData(description, deviceSettings)
    if (strValue !== JSON.stringify(newValue)) {
      setValue(newValue)
    }
    const newRoles = PoliciesHelper.getData(rolesDescription, deviceSettings)
    if (columns.rolesStrValue !== JSON.stringify(newRoles)) {
      setRolesValue(newRoles)
    }
  }, [deviceSettings])

  const columns = useMemo(
    () => ({
      rolesStrValue: JSON.stringify(rolesValue),
      tablePreferences: {
        width: [
          { columnId: DeviceAccessEnum.PERMISSION, width: 300 },
          { columnId: DeviceAccessEnum.SIGN_IN, width: 200 },
          ...rolesValue.map((role) => ({
            columnId: role[DeviceAccessEnum.ID],
            width: 120
          }))
        ]
      },
      tableColumns: [
        {
          id: TABLE_CONSTANTS.ID,
          label: TABLE_CONSTANTS.ID,
          index: 'hidden' as ColumnIndexTypes
        },
        {
          id: DeviceAccessEnum.PERMISSION,
          label: getLocalized('ctrl-panel-application')
        },
        {
          id: DeviceAccessEnum.SIGN_IN,
          label: getLocalized('ctrl-panel-sign-in-method')
        },
        ...rolesValue.map((role) => {
          const name = getDefaultRoleName(role[DeviceAccessEnum.ID])
          return {
            id: role[DeviceAccessEnum.ID],
            label: name ? getLocalized(name) : role[DeviceAccessEnum.NAME]
          }
        })
      ]
    }),
    [rolesValue]
  )

  const setData = (newValue) => {
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const onChange = (updateItem, tableIndex, permissions?) => {
    const items = []

    setTableData((prev) => {
      const changeItem = (i) => {
        if (i >= 0 && i < prev.length) {
          const { item, index } = prev[i].rowConfig
          updateItem(item)
          items.push({ item, index })
          prev[i] = tableItemEdit({ item, index }, i)
        }
      }
      changeItem(tableIndex)
      if (permissions) {
        permissions.forEach((x) =>
          changeItem(prev.findIndex((y) => y[TABLE_CONSTANTS.ID] === x))
        )
      }
      return [...prev]
    })

    setData(
      items.reduce(
        (acc, { item, index }) => {
          acc[index] = item
          return acc
        },
        [...value]
      )
    )
  }

  // All permissions
  const allPermissions = useMemo(
    () => DevicePermissionItems.getPermissions(constraints),
    []
  )

  // Create a map of permissions for quick lookup
  const permissionsMap = useMemo(
    () =>
      DevicePermissionItems.allPermissions.reduce((acc, x) => {
        const name = getLocalized(x.label)
        if (!x.parentId) {
          acc[x.value] = {
            level: 0,
            name: <b>{name}</b>
          }
        } else {
          const parent = acc[x.parentId]
          if (parent) {
            const level = parent.level + 1
            acc[x.value] = {
              parent: x.parentId,
              level,
              name: <div style={{ paddingLeft: `${16 * level}px` }}>{name}</div>
            }
            if (!parent.children) {
              parent.children = [x.value]
            } else {
              parent.children.push(x.value)
            }
          }
        }
        return acc
      }, {}),
    []
  )

  const findPermissionsToUpdate = (permission, includeParents) => {
    const permissionsToUpdate = []
    const process = (children) => {
      if (children) {
        children.forEach((child) => {
          permissionsToUpdate.push(child)
          process(permissionsMap[child].children)
        })
      }
    }
    process(permission.children)
    if (includeParents) {
      let parent = permission.parent
      while (parent) {
        permissionsToUpdate.push(parent)
        parent = permissionsMap[parent]?.parent
      }
    }
    return permissionsToUpdate
  }

  const signInMethods = useMemo(
    () => items.map((v) => ({ value: v.value, label: getLocalized(v.label) })),
    []
  )

  const tableItemEdit = ({ item, index }, tableIndex) => {
    // preloaded data
    const permId = item[DeviceAccessEnum.PERMISSION]
    const permission = permissionsMap[permId]

    // ID and name
    const tableItem = {
      [TABLE_CONSTANTS.ID]: permId,
      [DeviceAccessEnum.PERMISSION]: permission.name,
      rowConfig: { item, index }
    }

    // Sign in method
    if (!permission.parent) {
      tableItem[DeviceAccessEnum.SIGN_IN] = (
        <Select
          id={permId}
          placeholder={getLocalized('common.select-option')}
          options={signInMethods}
          clearIcon={false}
          value={[item[DeviceAccessEnum.SIGN_IN] ?? '']}
          onChange={({ value }) =>
            onChange((x) => {
              x[DeviceAccessEnum.SIGN_IN] = value
            }, tableIndex)
          }
        />
      )
    }

    // Roles
    const updateRoles = (id, checked, roles) => {
      const ids = [DeviceAccessRolesEnum.GUEST, DeviceAccessRolesEnum.USER]
      if (
        (checked && id === DeviceAccessRolesEnum.GUEST) ||
        (!checked && id === DeviceAccessRolesEnum.USER)
      ) {
        // If guest is checked then user should be checked as well
        // If user is unchecked then guest should be unchecked as well
        roles.forEach((x) => {
          if (ids.includes(x[DeviceAccessEnum.ID])) {
            x[DeviceAccessEnum.CHECK] = checked
          }
        })
      } else {
        // Otherwise just update this role
        const found = roles.find((x) => x[DeviceAccessEnum.ID] === id)
        if (found) {
          found[DeviceAccessEnum.CHECK] = checked
        }
      }
    }
    return item[DeviceAccessEnum.ROLES].reduce((acc, role) => {
      const roleId = role[DeviceAccessEnum.ID]
      acc[roleId] = (
        <Checkbox
          id={roleId}
          checked={role[DeviceAccessEnum.CHECK]}
          disabled={roleId === DeviceAccessRolesEnum.ADMIN}
          onChange={({ target: { checked } }) =>
            onChange(
              (x) => updateRoles(roleId, checked, x[DeviceAccessEnum.ROLES]),
              tableIndex,
              findPermissionsToUpdate(permission, checked)
            )
          }
        />
      )
      return acc
    }, tableItem)
  }

  const tableItemPreview = ({ item }) => {
    // preloaded data
    const permission = permissionsMap[item[DeviceAccessEnum.PERMISSION]]

    // ID and name
    const tableItem = {
      [TABLE_CONSTANTS.ID]: item[DeviceAccessEnum.PERMISSION],
      [DeviceAccessEnum.PERMISSION]: permission.name
    }

    // Sign in method
    const signIn =
      !permission.parent &&
      items.find((x) => x.value === item[DeviceAccessEnum.SIGN_IN])?.label
    if (signIn) {
      tableItem[DeviceAccessEnum.SIGN_IN] = (
        <TextBlack>{getLocalized(signIn)}</TextBlack>
      )
    }

    // Roles
    return item[DeviceAccessEnum.ROLES].reduce((acc, x) => {
      if (x.check) {
        acc[x[DeviceAccessEnum.ID]] = <IconCheckmark filled color={'green7'} />
      }
      return acc
    }, tableItem)
  }

  const getTableData = (val) => {
    // Create a map for quick lookup of val items by permission UUID
    const valMap = val.reduce((acc, item, index) => {
      acc[item[DeviceAccessEnum.PERMISSION]] = { item, index }
      return acc
    }, {})

    // Add permissions in predefined order if they present in the data
    const createTableItem = onAttributeChange ? tableItemEdit : tableItemPreview
    return allPermissions.reduce((acc, x) => {
      const permission = valMap[x.value]
      if (permission) {
        acc.push(createTableItem(permission, acc.length))
      }
      return acc
    }, [])
  }

  useEffect(() => {
    setTableData(getTableData(value))
  }, [value, permissionsMap])

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'marginBottom12'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  return (
    <FlexColumn>
      {tableHeader(getLocalized(label))}
      <MaxTableHeight>
        <Table
          columns={columns.tableColumns}
          data={tableData}
          className={'paddingSecondCol4'}
          data-testid={`id-${label}-table`}
          preferences={columns.tablePreferences}
          i18n={Retrievei18nItems()}
        />
      </MaxTableHeight>
      {compliance && (
        <PreviewItem compliance={compliance} className={'marginTop4'} />
      )}
    </FlexColumn>
  )
}

export default memo(ControlPanelAccessControl)

import React from 'react'
import { ShellProps } from './types/shell'
import RoutePoliciesTab from 'components/policies/routePoliciesTab'
import PoliciesProvider from 'context/policies/PoliciesProvider'
import SolutionsProvider from 'context/solutions/SolutionsProvider'
import CollectionsProvider from 'context/devices/CollectionsProvider'
import ConfigProvider from 'context/config/ConfigProvider'
import projectNames from './configs/projectNames'
import { ThemeProvider, ToastProvider, useToast } from '@veneer/core'
import SimplePolicyPreview from 'components/policies/preview/SimplePreview'
import DeviceComplianceInfo from 'components/policies/deviceComplianceInfo'
import AppConfigModal from 'components/policies/appConfig'
import Error from 'components/policies/modal/error'
import { fixTheme } from 'common/utilities'

declare global {
  interface Window {
    Shell: ShellProps
  }
}
export default function Root(props) {
  const { v1 } = window.Shell as ShellProps
  const navigation = v1?.navigation
  const accessControl = v1?.accessControl
  const stack = v1?.app?.getAuthStack()
  const authProvider = v1?.authProvider
  const events = v1?.events
  const featureFlags = v1?.featureFlags
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.()
  const mfeProps = {
    events,
    navigation,
    accessControl,
    featureFlags
  }
  // Custom properties
  const {
    policyId,
    deviceId,
    deviceModelNumber,
    deviceSerialNumber,
    entitlements,
    appConfig, // { appId, mocId, uuid, header, preview, config, onClose, onChange },
    themeIdentifier,
    demoEnabled,
    apiPath,
    ...otherProps
  } = props
  if (!otherProps.useToast) {
    otherProps.useToast = useToast
  }
  const localization = {
    language: v1?.localization?.language || 'en',
    country: v1?.localization?.country || 'US',
    themeIdentifier
  }

  const WithToast = (content) =>
    props.useToast ? (
      content.children
    ) : (
      <ToastProvider>{content.children}</ToastProvider>
    )

  const providerProps = { stack, demoEnabled, apiPath, authProvider }

  return (
    <section id={projectNames.packageJsonName}>
      <ThemeProvider {...fixTheme(themeProviderProps)}>
        <ConfigProvider {...localization}>
          <PoliciesProvider {...providerProps}>
            <CollectionsProvider {...providerProps}>
              <SolutionsProvider
                stack={stack}
                authProvider={v1.authProvider}
                demoEnabled={demoEnabled}
              >
                {policyId ? (
                  <SimplePolicyPreview policyId={policyId} />
                ) : deviceId ? (
                  <WithToast>
                    <DeviceComplianceInfo
                      deviceId={deviceId}
                      entitlements={entitlements}
                      deviceModelNumber={deviceModelNumber}
                      deviceSerialNumber={deviceSerialNumber}
                      useToast={otherProps.useToast}
                      containerId={otherProps.containerId}
                    />
                  </WithToast>
                ) : appConfig?.appId && appConfig?.mocId ? (
                  <AppConfigModal {...appConfig} />
                ) : (
                  <WithToast>
                    <RoutePoliciesTab {...otherProps} {...mfeProps} />
                  </WithToast>
                )}
                <Error />
              </SolutionsProvider>
            </CollectionsProvider>
          </PoliciesProvider>
        </ConfigProvider>
      </ThemeProvider>
    </section>
  )
}

import { Item } from 'context/policies/PoliciesConfigurationEnum'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

const countryItem = (x, s) => {
  return {
    value: 'country-code.' + x,
    label: `policy.settings.${s}.${x}`,
    option: x.toUpperCase()
  }
}
const countryCode = (x) => countryItem(x, 'countries')
const certificateCountryCode = (x) => countryItem(x, 'certificate-countries')

export default class CountryItems {
  static items: Item[] = [
    countryCode('ao'),
    countryCode('ar'),
    countryCode('au'),
    countryCode('at'),
    countryCode('bd'),
    countryCode('be'),
    countryCode('by'),
    countryCode('br'),
    countryCode('bn'),
    countryCode('bg'),
    countryCode('ca'),
    countryCode('kh'),
    countryCode('cl'),
    countryCode('cn'),
    countryCode('co'),
    countryCode('cr'),
    countryCode('hr'),
    countryCode('cy'),
    countryCode('cz'),
    countryCode('dk'),
    countryCode('ec'),
    countryCode('eg'),
    countryCode('ee'),
    countryCode('fi'),
    countryCode('fr'),
    countryCode('de'),
    countryCode('gr'),
    countryCode('hk'),
    countryCode('hu'),
    countryCode('is'),
    countryCode('in'),
    countryCode('id'),
    countryCode('ie'),
    countryCode('il'),
    countryCode('it'),
    countryCode('jp'),
    countryCode('jo'),
    countryCode('kz'),
    countryCode('kp'),
    countryCode('kw'),
    countryCode('la'),
    countryCode('lv'),
    countryCode('lb'),
    countryCode('li'),
    countryCode('lt'),
    countryCode('lu'),
    countryCode('my'),
    countryCode('mv'),
    countryCode('mt'),
    countryCode('mx'),
    countryCode('mn'),
    countryCode('ma'),
    countryCode('mz'),
    countryCode('mm'),
    countryCode('nl'),
    countryCode('nz'),
    countryCode('no'),
    countryCode('pk'),
    countryCode('pa'),
    countryCode('py'),
    countryCode('pe'),
    countryCode('ph'),
    countryCode('pl'),
    countryCode('pt'),
    countryCode('qa'),
    countryCode('ro'),
    countryCode('ru'),
    countryCode('sa'),
    countryCode('sg'),
    countryCode('sk'),
    countryCode('si'),
    countryCode('za'),
    countryCode('es'),
    countryCode('se'),
    countryCode('lk'),
    countryCode('ch'),
    countryCode('tw'),
    countryCode('th'),
    countryCode('tl'),
    countryCode('tn'),
    countryCode('tr'),
    countryCode('ua'),
    countryCode('uy'),
    countryCode('uk'),
    countryCode('us'),
    countryCode('ae'),
    countryCode('ve'),
    countryCode('vn'),
    countryCode('ye')
  ]

  static certificateItems: Item[] = [
    certificateCountryCode('af'),
    certificateCountryCode('ax'),
    certificateCountryCode('al'),
    certificateCountryCode('dz'),
    certificateCountryCode('as'),
    certificateCountryCode('ad'),
    certificateCountryCode('ao'),
    certificateCountryCode('ai'),
    certificateCountryCode('aq'),
    certificateCountryCode('ar'),
    certificateCountryCode('ag'),
    certificateCountryCode('am'),
    certificateCountryCode('aw'),
    certificateCountryCode('au'),
    certificateCountryCode('at'),
    certificateCountryCode('az'),
    certificateCountryCode('bs'),
    certificateCountryCode('bh'),
    certificateCountryCode('bd'),
    certificateCountryCode('bb'),
    certificateCountryCode('by'),
    certificateCountryCode('be'),
    certificateCountryCode('bz'),
    certificateCountryCode('bj'),
    certificateCountryCode('bm'),
    certificateCountryCode('bt'),
    certificateCountryCode('bo'),
    certificateCountryCode('ba'),
    certificateCountryCode('bw'),
    certificateCountryCode('bv'),
    certificateCountryCode('br'),
    certificateCountryCode('io'),
    certificateCountryCode('bn'),
    certificateCountryCode('bg'),
    certificateCountryCode('bf'),
    certificateCountryCode('bi'),
    certificateCountryCode('kh'),
    certificateCountryCode('cm'),
    certificateCountryCode('ca'),
    certificateCountryCode('cv'),
    certificateCountryCode('ky'),
    certificateCountryCode('cf'),
    certificateCountryCode('td'),
    certificateCountryCode('cl'),
    certificateCountryCode('cn'),
    certificateCountryCode('cx'),
    certificateCountryCode('cc'),
    certificateCountryCode('co'),
    certificateCountryCode('km'),
    certificateCountryCode('cg'),
    certificateCountryCode('cd'),
    certificateCountryCode('ck'),
    certificateCountryCode('cr'),
    certificateCountryCode('ci'),
    certificateCountryCode('hr'),
    certificateCountryCode('cu'),
    certificateCountryCode('cy'),
    certificateCountryCode('cz'),
    certificateCountryCode('dk'),
    certificateCountryCode('dj'),
    certificateCountryCode('dm'),
    certificateCountryCode('do'),
    certificateCountryCode('tp'),
    certificateCountryCode('ec'),
    certificateCountryCode('eg'),
    certificateCountryCode('sv'),
    certificateCountryCode('gq'),
    certificateCountryCode('er'),
    certificateCountryCode('ee'),
    certificateCountryCode('et'),
    certificateCountryCode('fk'),
    certificateCountryCode('fo'),
    certificateCountryCode('fm'),
    certificateCountryCode('fj'),
    certificateCountryCode('fi'),
    certificateCountryCode('fr'),
    certificateCountryCode('fx'),
    certificateCountryCode('gf'),
    certificateCountryCode('pf'),
    certificateCountryCode('tf'),
    certificateCountryCode('ga'),
    certificateCountryCode('gm'),
    certificateCountryCode('ge'),
    certificateCountryCode('de'),
    certificateCountryCode('gh'),
    certificateCountryCode('gi'),
    certificateCountryCode('gr'),
    certificateCountryCode('gl'),
    certificateCountryCode('gd'),
    certificateCountryCode('gp'),
    certificateCountryCode('gg'),
    certificateCountryCode('gu'),
    certificateCountryCode('gt'),
    certificateCountryCode('gn'),
    certificateCountryCode('gw'),
    certificateCountryCode('gy'),
    certificateCountryCode('ht'),
    certificateCountryCode('hm'),
    certificateCountryCode('hn'),
    certificateCountryCode('hk'),
    certificateCountryCode('hu'),
    certificateCountryCode('is'),
    certificateCountryCode('in'),
    certificateCountryCode('id'),
    certificateCountryCode('ir'),
    certificateCountryCode('iq'),
    certificateCountryCode('ie'),
    certificateCountryCode('im'),
    certificateCountryCode('il'),
    certificateCountryCode('it'),
    certificateCountryCode('jm'),
    certificateCountryCode('jp'),
    certificateCountryCode('je'),
    certificateCountryCode('jo'),
    certificateCountryCode('kz'),
    certificateCountryCode('ke'),
    certificateCountryCode('ki'),
    certificateCountryCode('kp'),
    certificateCountryCode('kr'),
    certificateCountryCode('kw'),
    certificateCountryCode('kg'),
    certificateCountryCode('la'),
    certificateCountryCode('lv'),
    certificateCountryCode('lb'),
    certificateCountryCode('ls'),
    certificateCountryCode('lr'),
    certificateCountryCode('ly'),
    certificateCountryCode('li'),
    certificateCountryCode('lt'),
    certificateCountryCode('lu'),
    certificateCountryCode('mo'),
    certificateCountryCode('mk'),
    certificateCountryCode('mg'),
    certificateCountryCode('mw'),
    certificateCountryCode('my'),
    certificateCountryCode('mv'),
    certificateCountryCode('ml'),
    certificateCountryCode('mt'),
    certificateCountryCode('mh'),
    certificateCountryCode('mq'),
    certificateCountryCode('mr'),
    certificateCountryCode('mu'),
    certificateCountryCode('yt'),
    certificateCountryCode('mx'),
    certificateCountryCode('md'),
    certificateCountryCode('mc'),
    certificateCountryCode('mn'),
    certificateCountryCode('me'),
    certificateCountryCode('ms'),
    certificateCountryCode('ma'),
    certificateCountryCode('mz'),
    certificateCountryCode('mm'),
    certificateCountryCode('na'),
    certificateCountryCode('nr'),
    certificateCountryCode('np'),
    certificateCountryCode('nl'),
    certificateCountryCode('an'),
    certificateCountryCode('nc'),
    certificateCountryCode('nz'),
    certificateCountryCode('ni'),
    certificateCountryCode('ne'),
    certificateCountryCode('ng'),
    certificateCountryCode('nu'),
    certificateCountryCode('nf'),
    certificateCountryCode('mp'),
    certificateCountryCode('no'),
    certificateCountryCode('om'),
    certificateCountryCode('pk'),
    certificateCountryCode('pw'),
    certificateCountryCode('ps'),
    certificateCountryCode('pa'),
    certificateCountryCode('pg'),
    certificateCountryCode('py'),
    certificateCountryCode('pe'),
    certificateCountryCode('ph'),
    certificateCountryCode('pn'),
    certificateCountryCode('pl'),
    certificateCountryCode('pt'),
    certificateCountryCode('pr'),
    certificateCountryCode('qa'),
    certificateCountryCode('re'),
    certificateCountryCode('ro'),
    certificateCountryCode('ru'),
    certificateCountryCode('rw'),
    certificateCountryCode('sh'),
    certificateCountryCode('kn'),
    certificateCountryCode('lc'),
    certificateCountryCode('pm'),
    certificateCountryCode('vc'),
    certificateCountryCode('ws'),
    certificateCountryCode('sm'),
    certificateCountryCode('st'),
    certificateCountryCode('sa'),
    certificateCountryCode('sn'),
    certificateCountryCode('rs'),
    certificateCountryCode('cs'),
    certificateCountryCode('sc'),
    certificateCountryCode('sl'),
    certificateCountryCode('sg'),
    certificateCountryCode('sk'),
    certificateCountryCode('si'),
    certificateCountryCode('gs'),
    certificateCountryCode('sb'),
    certificateCountryCode('so'),
    certificateCountryCode('za'),
    certificateCountryCode('es'),
    certificateCountryCode('lk'),
    certificateCountryCode('sd'),
    certificateCountryCode('sr'),
    certificateCountryCode('sj'),
    certificateCountryCode('sz'),
    certificateCountryCode('se'),
    certificateCountryCode('ch'),
    certificateCountryCode('sy'),
    certificateCountryCode('tw'),
    certificateCountryCode('tj'),
    certificateCountryCode('tz'),
    certificateCountryCode('th'),
    certificateCountryCode('tl'),
    certificateCountryCode('tg'),
    certificateCountryCode('tk'),
    certificateCountryCode('to'),
    certificateCountryCode('tt'),
    certificateCountryCode('tn'),
    certificateCountryCode('tr'),
    certificateCountryCode('tm'),
    certificateCountryCode('tc'),
    certificateCountryCode('tv'),
    certificateCountryCode('ug'),
    certificateCountryCode('ua'),
    certificateCountryCode('ae'),
    certificateCountryCode('gb'),
    certificateCountryCode('us'),
    certificateCountryCode('um'),
    certificateCountryCode('uy'),
    certificateCountryCode('uz'),
    certificateCountryCode('su'),
    certificateCountryCode('va'),
    certificateCountryCode('ve'),
    certificateCountryCode('vg'),
    certificateCountryCode('vi'),
    certificateCountryCode('vn'),
    certificateCountryCode('vu'),
    certificateCountryCode('wf'),
    certificateCountryCode('eh'),
    certificateCountryCode('ye'),
    certificateCountryCode('zm')
  ]

  static countryCodes = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? CountryItems.items
      : constraints.filterSettingOptions(
          CountryItems.items,
          ConstraintsResourceEnum.faxModemCfg,
          ConstraintsSettingEnum.analogFaxSetupCountry
        )
}

import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Table, Button } from '@veneer/core'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import {
  ObjectAttributeDescription,
  Item
} from 'context/policies/PoliciesConfigurationEnum'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import FaxMethodItems from 'context/policies/dropboxItems/FaxMethodItems'
import PrintEmailNotificationItems from 'context/policies/dropboxItems/PrintEmailNotificationItems'
import ImageSettingItems from 'context/policies/dropboxItems/ImageSettingItems'
import FaxSendCommonModal, { faxSendCommonEnum } from './FaxSendCommonModal'
import FaxSendModemModal, { faxSendModemEnum } from './FaxSendModemModal'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import HelpButton from '../../../HelpButton'
import FaxSendTextModal from './FaxSendTextModal'
import WarningMessage from '../WarningMessage'
import { FlexColumn, PreviewLabel } from 'styles/styles'
import 'styles/global.scss'
import PoliciesErrors, {
  MessageTypesEnum
} from 'context/policies/PoliciesErrors'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'

const columnId = 'id'
const columnOption = 'option'
const columnValue = 'value'
const tablePref = {
  width: [
    { columnId: columnOption, width: 240 },
    { columnId: columnValue, width: 360 }
  ]
}

const filler = (id) => {
  return { id, title: `${id}-title`, button: `${id}-settings` }
}
const tables = [filler('common'), filler('modem'), filler('internet')] // FaxSendItems

const boolItems: Item[] = [
  { value: 'true', label: 'common.on' },
  { value: 'false', label: 'common.off' }
]

export enum faxSendInternetEnum {
  DOMAIN = 'internet-domain',
  EMAIL = 'internet-email'
}

enum faxSendEnum {
  METHOD = 'method'
}

const FaxSendControl = (props) => {
  const description: ObjectAttributeDescription = props.description
  const {
    localizationPath,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const { isEnabled, addError, removeError, displayAllErrors } =
    useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  const initArray = (x) => Array.from(Array(tables.length)).map(() => x)
  const [showModal, setShowModal] = useState(initArray(false))
  const [error, setError] = useState(initArray(false))
  const [displayError, setDisplayError] = useState(initArray(displayAllErrors))

  useEffect(
    () => setDisplayError((x) => x.map((y) => y || displayAllErrors)),
    [displayAllErrors]
  )

  const value = useMemo(
    () => PoliciesHelper.getData(description, deviceSettings),
    [deviceSettings]
  )

  const compliance = useMemo(
    () => PoliciesHelper.getCompliance(description, deviceSettings),
    [deviceSettings]
  )

  const onChange = (v, i?) => {
    if (i !== undefined) {
      setDisplayError((x) => {
        x[i] = true
        return x
      })
    }
    const newValue = { ...value, ...v }
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const getTableData = (val) => {
    const item = (id, items?) => {
      const found = items?.find((v) => v.value === val[id])
      const text = found ? getLocalized(found.label) : val[id]
      const status = compliance[id]
      return {
        id,
        option: getLocalized(id),
        value: (
          <FlexColumn>
            {text}
            {status && <PreviewItem compliance={status} />}
          </FlexColumn>
        )
      }
    }

    const commonItems = [
      {
        id: faxSendCommonEnum.BG_CLEANUP,
        items: ImageSettingItems.imageBgCleanupItems
      },
      {
        id: faxSendCommonEnum.DARKNESS,
        items: ImageSettingItems.imageDarknessItems
      },
      {
        id: faxSendCommonEnum.CONTRAST,
        items: ImageSettingItems.imageContrastItems
      },
      {
        id: faxSendCommonEnum.SHARPNESS,
        items: ImageSettingItems.imageSharpnessItems
      },
      {
        id: faxSendCommonEnum.NOTIFICATION_CONDITION,
        items: PrintEmailNotificationItems.emailNotificationConditions
      },
      {
        id: faxSendCommonEnum.NOTIFICATION_METHOD,
        items: PrintEmailNotificationItems.emailNotificationMethodItems
      },
      { id: faxSendCommonEnum.NOTIFICATION_ADDRESS }
    ]

    const modemItems = [
      { id: faxSendModemEnum.ECM, items: boolItems },
      { id: faxSendModemEnum.JBIG_COMPRESSION, items: boolItems }
    ]

    const internetItems = [
      { id: faxSendInternetEnum.DOMAIN },
      { id: faxSendInternetEnum.EMAIL }
    ]

    const filteredItems = (items) =>
      items
        .filter(({ id }) => val[id] !== undefined)
        .map(({ id, items }) => item(id, items))

    return [
      filteredItems(commonItems),
      filteredItems(modemItems),
      filteredItems(internetItems)
    ]
  }

  const tableData = useMemo(() => getTableData(value), [value])

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: columnId, label: columnId, index },
      { id: columnOption, label: getLocalized('option-name') },
      { id: columnValue, label: getLocalized('value') }
    ]
  }, [])

  const enabled = isEnabled(attribute)
  const i18nItems = Retrievei18nItems()
  const table = (i, cn?) => (
    <FlexColumn key={tables[i].button} className={cn}>
      {onAttributeChange ? (
        <div className={'marginBottom12'}>
          <Button
            appearance={'secondary'}
            disabled={!enabled}
            onClick={() => setShowModal(showModal.map((_, j) => j === i))}
          >
            {getLocalized(tables[i].button)}
          </Button>
        </div>
      ) : (
        <PreviewLabel>{getLocalized(tables[i].title)}</PreviewLabel>
      )}
      <Table
        columns={tableColumns}
        data={tableData[i]}
        className={'widthColAuto'}
        preferences={tablePref}
        i18n={i18nItems}
      />
      {error[i] && (
        <WarningMessage
          id={tables[i].title + '.error'}
          type={MessageTypesEnum.ERROR}
          message={getLocalized('common.errors.not-selected')}
        />
      )}
    </FlexColumn>
  )

  const index = useMemo(() => {
    const idx = FaxMethodItems.faxSendMethods().findIndex(
      (x) => x.value === value.method
    )
    return idx < 0 ? 0 : idx // Internal Modem is default
  }, [value])

  const checkValue = (x) => value[x] !== undefined

  useEffect(() => {
    const ids = [
      [
        faxSendCommonEnum.BG_CLEANUP,
        faxSendCommonEnum.DARKNESS,
        faxSendCommonEnum.CONTRAST,
        faxSendCommonEnum.SHARPNESS,
        faxSendCommonEnum.NOTIFICATION_CONDITION
      ].filter((x) => checkValue(x)),
      [faxSendModemEnum.ECM, faxSendModemEnum.JBIG_COMPRESSION].filter((x) =>
        checkValue(x)
      ),
      [faxSendInternetEnum.DOMAIN, faxSendInternetEnum.EMAIL]
    ]
    const err = Array.from(Array(tables.length)).map(
      enabled
        ? (_, i) => (!i || i === index + 1) && ids[i].some((y) => !value[y])
        : () => false
    )
    if (!err[0]) {
      // check dependant fields
      err[0] =
        PrintEmailNotificationItems.isNotifyCondition(
          value[faxSendCommonEnum.NOTIFICATION_CONDITION]
        ) &&
        PrintEmailNotificationItems.isEmailNotification(
          value[faxSendCommonEnum.NOTIFICATION_METHOD]
        ) &&
        !value[faxSendCommonEnum.NOTIFICATION_ADDRESS]
    }
    setError(err.map((x, i) => x && displayError[i]))
    err.forEach((x, i) =>
      x
        ? addError(props.id, attribute + tables[i].button, displayError[i])
        : removeError(props.id, attribute + tables[i].button)
    )
  }, [enabled, value, displayError])

  const radio = (label, methods) => {
    return onAttributeChange ? (
      <LabelRadioButtons
        disabled={!enabled}
        label={getLocalized(label)}
        className={'marginBottom16'}
        id={'id-fax-send-method'}
        value={value.method}
        helpButton={
          <HelpButton
            enabled={enabled}
            description={getLocalized(label + '_help')}
          />
        }
        onChange={(_, method) => onChange({ method })}
        options={methods.map((item) => {
          return { value: item.value, label: getLocalized(item.label) }
        })}
      />
    ) : (
      <PreviewItem
        label={`${localizationPath}.${label}`}
        value={getLocalized(FaxMethodItems.faxSendMethods()[index].label)}
        compliance={compliance.method}
        className={'marginBottom16'}
      />
    )
  }

  const modalParams = (index) => {
    return {
      value,
      getLocalized,
      onChange: (v) => onChange(v, index),
      onClose: () => setShowModal(showModal.map(() => false)),
      id: tables[index].id,
      key: tables[index].id,
      constraints: description.constraints
    }
  }

  const pair = (key, errors) => {
    return { key, errors }
  }

  const modals = [
    (modalProps) => <FaxSendCommonModal {...modalProps} />,
    (modalProps) => <FaxSendModemModal {...modalProps} />,
    (modalProps) => (
      <FaxSendTextModal
        {...modalProps}
        textBoxParams={[
          pair(faxSendInternetEnum.DOMAIN, PoliciesErrors.errorsFaxDomainName),
          pair(faxSendInternetEnum.EMAIL, PoliciesErrors.errorsInvalidEmail)
        ]}
      />
    )
  ]

  return (
    <>
      <FlexColumn>
        {checkValue(faxSendEnum.METHOD) &&
          radio(
            faxSendEnum.METHOD,
            FaxMethodItems.faxSendMethods(description.constraints)
          )}
        {table(0)}
        {index < tables.length - 1 && table(index + 1, 'paddingTop16')}
      </FlexColumn>
      {showModal.map((x, i) => x && modals[i](modalParams(i)))}
    </>
  )
}

export default memo(FaxSendControl)

import React, { useContext, useEffect, useState, memo } from 'react'
import configContext from 'context/config/configContext'
import settingsContext from 'context/settings/settingsContext'
import { Checkbox } from '@veneer/core'
import { FlexRow } from 'styles/styles'
import 'styles/global.scss'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { ToggleDescription } from 'context/policies/PoliciesConfigurationEnum'
import HelpButton from 'components/policies/settings/attributes/HelpButton'

const ToggleViewControl = (props) => {
  const description: ToggleDescription = props.description
  const {
    attributes,
    data: { deviceSettings }
  } = props
  const { attribute, help } = description

  const { t } = useContext(configContext)
  const { isEnabled, addDisabled, removeDisabled } = useContext(settingsContext)

  const getLocalized = (key: string): string => {
    return t(`${props.localizationPath}.${key}`)
  }

  const [enabled, setEnabled] = useState(isEnabled(description.attribute))
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )

  useEffect(() => {
    setEnabled(isEnabled(description.attribute))
  }, [isEnabled(description.attribute)])

  useEffect(() => {
    setValue(PoliciesHelper.getData(description, deviceSettings))
  }, [deviceSettings])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const val = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && val === value) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  return (
    <>
      <FlexRow className={'alignCenter'}>
        <Checkbox
          id={attribute}
          label={getLocalized(description.label)}
          disabled={true}
          checked={value}
        />
        <FlexRow className={'paddingLeft4 lineBreak'}>
          {help && (
            <HelpButton enabled={enabled} description={getLocalized(help)} />
          )}
        </FlexRow>
      </FlexRow>
    </>
  )
}

export default memo(ToggleViewControl)

import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Modal,
  Select,
  Table,
  TableColumns
} from '@veneer/core'
import { SortTypes } from '@veneer/core/dist/scripts/table'
import { FlexRowWithSpace } from 'styles/styles'
import { getRowSelectAllState, abcSort } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'

const keySelect = 'common.select-option'
const keyError = 'common.errors.not-selected'

const TrayAdministrationModal = (props) => {
  const defSortOrder = 'ascending' as SortTypes
  const visibleOptions = 7
  const uidColumnId = 'uid'
  const trayColumnId = 'label'
  const mediaSizeColumnId = 'mediaSize'
  const mediaTypeColumnId = 'mediaType'

  const [tableData, setTableData] = useState([])
  const [orderType, setOrderType] = useState(defSortOrder)
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [selectedCount, setSelectedCount] = useState(0)
  const [okButtonDisabled, setOkButtonDisabled] = useState(true)

  const { value, getLocalized, onClose, onChange } = props

  const buildRowDataForTable = (item) => {
    return {
      ...item,
      mediaSize: buildMediaSizeSelectControl(item),
      mediaType: buildMediaTypeSelectControl(item)
    }
  }

  const buildSelectControlOptions = (items) => {
    return items.map((v) => {
      return {
        value: v.value,
        label: getLocalized(v.label)
      }
    })
  }

  const buildMediaSizeSelectControl = (item) => {
    const controlId = 'mediaSizeSelect_' + `${item.uid}`
    const displayError =
      item.rowConfig.selected && !item.mediaSizeValue && item.mediaSizeError
    return (
      <>
        <Select
          id={controlId}
          placeholder={getLocalized(keySelect)}
          helperText={displayError ? getLocalized(keyError) : null}
          options={buildSelectControlOptions(props.description.sizes)}
          clearIcon={false}
          error={displayError}
          disabled={!item.rowConfig.selected}
          value={[item.mediaSizeValue || '']}
          visibleOptions={visibleOptions}
          onChange={(v) => {
            const found = tableData.find((row) => row.uid === item.uid)
            if (found) {
              found.mediaSizeValue = v.value
              found.mediaSizeError = true
              setTableData([...tableData])
            }
          }}
        />
      </>
    )
  }

  const buildMediaTypeSelectControl = (item) => {
    const controlId = 'mediaTypeSelect_' + `${item.uid}`
    const displayError =
      item.rowConfig.selected && !item.mediaTypeValue && item.mediaTypeError
    return (
      <>
        <Select
          id={controlId}
          placeholder={getLocalized(keySelect)}
          helperText={displayError ? getLocalized(keyError) : null}
          options={buildSelectControlOptions(props.description.types)}
          clearIcon={false}
          error={displayError}
          disabled={!item.rowConfig.selected}
          value={[item.mediaTypeValue || '']}
          visibleOptions={visibleOptions}
          onChange={(v) => {
            const found = tableData.find((row) => row.uid === item.uid)
            if (found) {
              found.mediaTypeValue = v.value
              found.mediaTypeError = true
              setTableData([...tableData])
            }
          }}
        />
      </>
    )
  }

  useEffect(() => {
    setTableData(
      props.description.trays.map((item, index) => {
        const found = value.find((x) => x.tray === item.value)
        return {
          uid: index,
          label: getLocalized(item.label),
          tray: item.value,
          mediaSizeValue: found ? found.size : null,
          mediaSizeError: false,
          mediaTypeValue: found ? found.type : null,
          mediaTypeError: false,
          rowConfig: { selected: !!found }
        }
      })
    )
  }, [value])

  const onRowSelect = (event, rowId) => {
    const { checked } = event.target
    const existingTrayData = tableData.find((row) => row.uid === rowId)
    if (existingTrayData) {
      existingTrayData.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    const newTableData = tableData.map((row) => {
      const rowCopy = row
      row.rowConfig.selected = checked
      return rowCopy
    })
    setTableData(newTableData)
  }

  useEffect(() => {
    const selected = tableData.filter((row) => row.rowConfig.selected)
    setSelectedCount(selected.length)
    setRowSelectAllState(
      getRowSelectAllState(selected.length, tableData.length)
    )
    setOkButtonDisabled(
      selected.length === 0 ||
        (value.length == selected.length &&
          tableData.filter((tableDataItem) => {
            return (
              tableDataItem.rowConfig.selected &&
              value.length &&
              value.filter((valueItem) => {
                return (
                  valueItem.tray === tableDataItem.tray &&
                  valueItem.size === tableDataItem.mediaSizeValue &&
                  valueItem.type === tableDataItem.mediaTypeValue
                )
              }).length === 0
            )
          }).length === 0)
    )
  }, [tableData])

  const handleOkButtonAction = () => {
    tableData.forEach((tableItem) => {
      tableItem.mediaSizeError = tableItem.mediaTypeError = true
    })
    setTableData([...tableData])
    const selected = tableData.filter(({ rowConfig }) => rowConfig.selected)
    if (selected.some((item) => !item.mediaSizeValue || !item.mediaTypeValue)) {
      return
    }
    onChange(
      selected.map((rowData) => {
        return {
          tray: rowData.tray,
          size: rowData.mediaSizeValue,
          type: rowData.mediaTypeValue
        }
      })
    )
    onClose()
  }

  const tableColumns: TableColumns[] = [
    {
      id: uidColumnId,
      label: 'ID',
      index: 'hidden'
    },
    {
      id: trayColumnId,
      label: getLocalized('trays-table-column-tray'),
      sortable: true
    },
    {
      id: mediaSizeColumnId,
      label: getLocalized('trays-table-column-media-size'),
      sortable: false
    },
    {
      id: mediaTypeColumnId,
      label: getLocalized('trays-table-column-media-type'),
      sortable: false
    }
  ]

  const sortedTableData = useMemo(() => {
    return abcSort(tableData, (x) => x.label, orderType).map((item) =>
      buildRowDataForTable(item)
    )
  }, [tableData, orderType])

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized(!value.length ? 'select-trays' : 'change-trays')}
      className={'very-large-policy-modal'}
      data-testid={'id-tray-admin-modal'}
      footer={
        <FlexRowWithSpace className={'alignCenter fullWidth'}>
          <ButtonGroup>
            <Button
              id={'okBtn'}
              onClick={handleOkButtonAction}
              disabled={okButtonDisabled}
            >
              {getLocalized(!value.length ? 'common.select' : 'common.save')}
            </Button>
            <Button id={'cancelBtn'} appearance={'secondary'} onClick={onClose}>
              {getLocalized('common.cancel')}
            </Button>
          </ButtonGroup>
          <p>
            {getLocalized('common.n-items-selected', { count: selectedCount })}
          </p>
        </FlexRowWithSpace>
      }
    >
      <p className={'paddingBottom16'}>
        {getLocalized('trays-modal-description')}
      </p>
      <Table
        columns={tableColumns}
        data={sortedTableData}
        onSort={(_, { type }) => setOrderType(type)}
        onSelect={onRowSelect}
        onSelectAllPageItems={handleSelectAllPageItems}
        rowSelector={'multiSelection'}
        rowSelectAllState={rowSelectAllState}
        preferences={{
          sortBy: { id: trayColumnId, type: orderType },
          width: [
            { columnId: trayColumnId, width: 150 },
            { columnId: mediaSizeColumnId, width: 300 },
            { columnId: mediaTypeColumnId, width: 300 }
          ]
        }}
        className={
          'widthColAuto paddingThirdCol4 paddingFourthCol4 verticalAlignThirdColTop verticalAlignFourthColTop'
        }
        data-testid={'id-tray-admin-modal-table'}
        i18n={Retrievei18nItems()}
      />
    </Modal>
  )
}
export default TrayAdministrationModal

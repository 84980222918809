import configContext from 'context/config/configContext'
import { WiFiChannelDescription } from 'context/policies/PoliciesConfigurationEnum'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import settingsContext from 'context/settings/settingsContext'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import WiFiDirectItems from 'context/policies/dropboxItems/WiFiDirectItems'
import LabelSelect from 'common/controls/labelSelect'
import LabelRadioButtons from 'common/controls/labelRadioButtons'

const WiFiControl = (props) => {
  const description: WiFiChannelDescription = props.description
  const {
    id,
    localizationPath,
    data: { deviceSettings },
    onAttributeChange,
    compliance
  } = props
  const { attribute } = description

  const { isEnabled, addError, removeError, displayAllErrors } =
    useContext(settingsContext)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )

  //Set default radio value based on the dropdown value
  const [radioValue, setRadioValue] = useState(WiFiDirectItems.getBand(value))

  const { tt } = useContext(configContext)
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const showError = displayError || displayAllErrors

  const enabled = isEnabled(attribute)

  const channelOptions = useMemo(
    () => WiFiDirectItems.getChannels(radioValue),
    [radioValue]
  )

  useEffect(() => {
    const err = enabled && !value // "not selected" error
    setError(showError ? err : null)
    err ? addError(id, attribute, showError) : removeError(id, attribute)
  }, [enabled, value, showError])

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  useEffect(() => {
    const val = PoliciesHelper.getData(description, deviceSettings)
    setValue(val)
    setRadioValue(WiFiDirectItems.getBand(val))
  }, [deviceSettings])

  //Set default values for 2.4GHz or 5GHz when the dropdown value is not present
  useEffect(() => {
    if (!channelOptions.some((option) => option.value === value)) {
      handleChange(WiFiDirectItems.getDefChannel(radioValue))
    }
  }, [channelOptions, value])

  const handleChange = (v) => {
    const val = v.toString()
    setValue(val)
    setDisplayError(true)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, val)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const label = 'channel'
  return onAttributeChange ? (
    <>
      <LabelRadioButtons
        disabled={!enabled}
        label={getLocalized('band')}
        className={'paddingBottom16'}
        id={attribute}
        value={radioValue}
        onChange={(_, v) => setRadioValue(v)}
        options={WiFiDirectItems.wifiBands.map((i) => ({
          ...i,
          label: getLocalized(i.value)
        }))}
      />
      <div className={'devSettingsIndent1'}>
        <LabelSelect
          id={'channel-selector'}
          disabled={!enabled}
          label={getLocalized(label)}
          className={'maxTextWidth'}
          placeholder={getLocalized('common.select-option')}
          options={channelOptions}
          clearIcon={false}
          value={[value]}
          onChange={({ value: v }) => handleChange(v)}
          helperText={
            error ? getLocalized('common.errors.not-selected') : undefined
          }
          error={error}
        />
      </div>
    </>
  ) : (
    <PreviewItem
      label={`${localizationPath}.${label}`}
      value={value}
      compliance={compliance}
    />
  )
}

export default memo(WiFiControl)

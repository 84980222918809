import { Item } from '../PoliciesConfigurationEnum'

const action = (x) => {
  return { value: `action.${x}`, label: `policy.settings.actions.${x}` }
}

export default class SupplyActionItems {
  static supplyActions: Item[] = [
    action('stop'),
    action('prompt'),
    action('continue')
  ]
}

import React from 'react'
import { Card, Checkbox, Scrollbar } from '@veneer/core'
import { FlexColumn, cssFullHeight } from 'styles/styles'
import FileName, {
  getFileNamePreview
} from 'components/policies/modal/FileName'
import { FileNameEnum } from 'components/policies/modal/FileName/FileNameModal'
import FileSettingItems from 'context/policies/dropboxItems/FileSettingItems'
import PrintMediaItems from 'context/policies/dropboxItems/PrintMediaItems'
import LabelSelect from 'common/controls/labelSelect'
import { QuickSetsFileEnum } from './QuickSetsEnums'
import { errorKeys } from './QuickSetsModal'
import { isTrueString, getError } from 'common/utilities'
import LabelTextBox from 'common/controls/labelTextBox'
import PoliciesErrors from 'context/policies/PoliciesErrors'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'

export const fileOptions = {
  [QuickSetsFileEnum.MEDIA_SIZE]: (constraints) =>
    PrintMediaItems.mediaSizes(constraints, PolicyAttributeEnum.Email_File),
  [QuickSetsFileEnum.SCAN_PLEX]: (constraints) =>
    FileSettingItems.scanPlexs(constraints),
  [QuickSetsFileEnum.COLOR_PREFERENCE]: (constraints) =>
    FileSettingItems.fileColorPrefs(constraints),
  [QuickSetsFileEnum.TYPE]: (constraints) =>
    FileSettingItems.fileTypes(constraints),
  [QuickSetsFileEnum.RESOLUTION]: (constraints) =>
    FileSettingItems.fileResolutions(constraints),
  [QuickSetsFileEnum.COMPRESSION_BLACK]: (constraints) =>
    FileSettingItems.fileCompressionBlacks(constraints),
  [QuickSetsFileEnum.COMPRESSION_COLOR]: (constraints) =>
    FileSettingItems.fileCompressionColors(constraints)
}

export const isFieldEnabled = (value, id) => {
  switch (id) {
    case QuickSetsFileEnum.COMPRESSION_BLACK:
    case QuickSetsFileEnum.COMPRESSION_COLOR:
      return FileSettingItems.isFileTypeTiff(value[QuickSetsFileEnum.TYPE])
    case QuickSetsFileEnum.COMPRESSION:
      return (
        FileSettingItems.isResolutionCompressed(
          value[QuickSetsFileEnum.RESOLUTION]
        ) &&
        FileSettingItems.isFileTypeCompressed(value[QuickSetsFileEnum.TYPE])
      )
    case QuickSetsFileEnum.PDF_ENCRYPTION:
      return FileSettingItems.isFileTypePdf(value[QuickSetsFileEnum.TYPE])
    default:
      return true
  }
}

export const checkConstraints = (constraints, x): boolean => {
  return constraints
    ? !!constraints.isPolicyAttributeSettingSupported(
        PolicyAttributeEnum.Email_File,
        PolicyAttributeEnum.Email_File +
          (x === QuickSetsFileEnum.FILE_NAME ? FileNameEnum.OBJECT : '.') +
          x
      )
    : true
}

const AddQuickSetStep3 = (props) => {
  const {
    getLocalized,
    value,
    onChange,
    requiredErrors,
    setRequiredErrors,
    constraints
  } = props

  const getLocalizedEx = (key) =>
    getLocalized(`policy.settings.attributes.email-file.device_settings.${key}`)

  const getDropdownItems = (items) =>
    items.map((item) => ({ ...item, label: getLocalized(item.label) }))

  const getInputFieldsData = (id) => {
    switch (id) {
      case FileNameEnum.PREFIX:
        return {
          placeholder: 'enter-prefix',
          errors: PoliciesErrors.errorsPrefix
        }
      case QuickSetsFileEnum.QUALITY:
        return {
          options: getDropdownItems(FileSettingItems.fileQualityItems),
          value: value[QuickSetsFileEnum.QUALITY]
        }
      case QuickSetsFileEnum.COMPRESSION:
        return {
          options: getDropdownItems(FileSettingItems.fileCompressionItems),
          value: value[QuickSetsFileEnum.COMPRESSION]
        }
      default:
        return {
          options: getDropdownItems(fileOptions[id](constraints) || []),
          value: value[id]
        }
    }
  }

  const generateCheckbox = (id: string, isEnabled = () => true) =>
    checkConstraints(constraints, id) ? (
      <Checkbox
        id={id}
        label={getLocalizedEx(id)}
        key={id}
        disabled={!isEnabled()}
        checked={isTrueString(value[id])}
        onChange={(check) => {
          const { checked, id } = check.target
          onChange({ [id]: checked + '' })
        }}
      />
    ) : null

  const generateTextbox = (id: string, idError: string, required = false) => {
    const boxData = getInputFieldsData(id)
    const placeholder = boxData.placeholder
    const error = requiredErrors[idError]
    const handleChange = (val) => {
      setRequiredErrors((prevValue) => {
        const newValue = { ...prevValue }
        const newError = getError(boxData.errors, val)
        if (newError) {
          newValue[idError] = newError
        } else {
          delete newValue[idError]
        }
        return newValue
      })
      onChange({ [id]: val })
    }
    return (
      <div className={'paddingTop16'}>
        <LabelTextBox
          required={required}
          label={getLocalizedEx(id)}
          className={'maxTextWidth'}
          id={id}
          type={'text'}
          value={value[id] || ''}
          placeholder={getLocalizedEx(placeholder)}
          onChange={(val) => handleChange(val)}
          helperText={
            error?.message
              ? getLocalized(error.message, 0, error.params)
              : getLocalizedEx(placeholder)
          }
          error={!!error}
        />
      </div>
    )
  }

  const generateSelect = (
    id: string,
    isEnabled = () => true,
    changedData = (x) => x
  ) => {
    if (!checkConstraints(constraints, id)) {
      return null
    }
    const error = requiredErrors[id]
    const selectedBoxData = getInputFieldsData(id)
    const handleChange = (option) => {
      setRequiredErrors((prevValue) => {
        const newValue = { ...prevValue }
        if (!option) {
          newValue[id] = { message: errorKeys[0] }
        } else {
          delete newValue[id]
        }
        return newValue
      })
      onChange(changedData({ [id]: option }))
    }
    return (
      <LabelSelect
        id={id}
        label={getLocalizedEx(id)}
        className={'marginBottom16 maxTextWidth'}
        placeholder={getLocalized('common.select-option')}
        options={selectedBoxData.options}
        value={[selectedBoxData.value]}
        disabled={!isEnabled()}
        error={!!error}
        helperText={error?.message ? getLocalized(error?.message) : null}
        onChange={(val) => handleChange(val.value)}
      />
    )
  }

  const generateCardContent = () => {
    const id = QuickSetsFileEnum.FILE_NAME
    const error = requiredErrors[id]
    const updateCompression = (x) => {
      const val = { ...value, ...x } // merge the new value with the existing value
      return !FileSettingItems.isResolutionCompressed(
        val[QuickSetsFileEnum.RESOLUTION]
      ) && FileSettingItems.isFileTypeCompressed(val[QuickSetsFileEnum.TYPE])
        ? { ...x, [QuickSetsFileEnum.COMPRESSION]: 'file-compression.normal' }
        : x
    }
    return (
      <Scrollbar customStyle={cssFullHeight}>
        <FlexColumn className={'padding1224'}>
          {checkConstraints(constraints, QuickSetsFileEnum.FILE_NAME) ? (
            <FileName
              simple={false}
              value={value}
              fileType={value[QuickSetsFileEnum.TYPE]}
              error={!!error}
              attribute={'quick-set'}
              onChange={(fileName) => {
                setRequiredErrors((prevValue) => {
                  const newValue = { ...prevValue }
                  if (!getFileNamePreview(fileName).length) {
                    newValue[id] = { message: errorKeys[0] }
                  } else {
                    delete newValue[id]
                  }
                  return newValue
                })
                onChange(fileName)
              }}
              options={FileSettingItems.fileNameTokens(
                constraints,
                PolicyAttributeEnum.Network_Folder_File
              )}
            />
          ) : (
            generateTextbox(FileNameEnum.PREFIX, id, true)
          )}
          <div className={'marginBottom16'} />
          {generateSelect(QuickSetsFileEnum.MEDIA_SIZE)}
          {generateSelect(QuickSetsFileEnum.SCAN_PLEX)}
          {generateSelect(QuickSetsFileEnum.COLOR_PREFERENCE)}
          {generateSelect(QuickSetsFileEnum.QUALITY)}
          {generateSelect(
            QuickSetsFileEnum.RESOLUTION,
            () => true,
            updateCompression
          )}
          {generateSelect(
            QuickSetsFileEnum.TYPE,
            () => true,
            (x) =>
              updateCompression(
                FileSettingItems.isFileTypePdf(x[QuickSetsFileEnum.TYPE])
                  ? x
                  : { ...x, [QuickSetsFileEnum.PDF_ENCRYPTION]: 'false' }
              )
          )}
          <div className={'marginBottom16 devSettingsIndent1'}>
            {generateSelect(QuickSetsFileEnum.COMPRESSION_BLACK, () =>
              isFieldEnabled(value, QuickSetsFileEnum.COMPRESSION_BLACK)
            )}
            {generateSelect(QuickSetsFileEnum.COMPRESSION_COLOR, () =>
              isFieldEnabled(value, QuickSetsFileEnum.COMPRESSION_COLOR)
            )}
            {generateSelect(QuickSetsFileEnum.COMPRESSION, () =>
              isFieldEnabled(value, QuickSetsFileEnum.COMPRESSION)
            )}
            {generateCheckbox(QuickSetsFileEnum.PDF_ENCRYPTION, () =>
              isFieldEnabled(value, QuickSetsFileEnum.PDF_ENCRYPTION)
            )}
          </div>
          {generateCheckbox(QuickSetsFileEnum.BLANK_PAGE_SUPPRESSION)}
        </FlexColumn>
      </Scrollbar>
    )
  }
  return (
    <>
      <h5 className={'step-heading'}>{getLocalized('header', 3)}</h5>
      <p className={'contentPadding'}>{getLocalized('step-description', 3)}</p>
      <div className={'quick-set-card-height'}>
        <Card
          id={'add-quick-set-step-3'}
          content={generateCardContent()}
          border={'dropShadow'}
          customStyle={{ height: '100%', padding: '12px 0 12px 0' }}
        />
      </div>
    </>
  )
}

export default AddQuickSetStep3

import moment from 'moment'
import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import FileNameModal, { FileNameEnum } from './FileNameModal'
import { Button } from '@veneer/core'
import configContext from 'context/config/configContext'
import { TextBlack } from 'styles/styles'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import WarningMessage from 'components/policies/settings/attributes/device/controls/WarningMessage'
import FileSettingItems from 'context/policies/dropboxItems/FileSettingItems'
import { TABLE_CONSTANTS, isTrueString } from 'common/utilities'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const tokenSep = '%'
const fileNameTokens = 'file-name-tokens'
const fileNameSettings = 'file-name-settings'
const localizationPath = 'policy.settings.' + fileNameSettings

export const getFileNamePreview = (
  value,
  getLocalized = (x) => x,
  fileType = ''
) => {
  const params = (token) => {
    if (token.length <= 2) {
      // special case: %% -> %
      return tokenSep
    }
    const found = FileSettingItems.fileNameTokenItems.find(
      (x) => x.value === token
    )
    if (!found) {
      // unknown token -> skip
      return ''
    }
    if (token.includes('DATE_') || token.includes('TIME_')) {
      // for date/time tokens -> extract format from the value
      return moment(new Date()).format(
        token.slice(token.lastIndexOf('_') + 1, -1)
      )
    }
    // for the rest of items -> just use token labels (user/host names)
    return getLocalized(found.label.replace(fileNameTokens, fileNameSettings))
  }

  const getPreview = (str) => {
    let result = ''
    for (let i = 0; i < str?.length; ) {
      const begin = str.indexOf(tokenSep, i)
      if (begin >= 0) {
        const end = str.indexOf(tokenSep, begin + tokenSep.length)
        if (end >= 0) {
          result += str.slice(i, begin)
          i = end + tokenSep.length
          result += params(str.slice(begin, i))
          continue
        }
      }
      result += str.slice(i)
      break
    }
    return result
  }

  const fn =
    getPreview(value[FileNameEnum.PREFIX]) +
    (value[FileNameEnum.NAME] || '') +
    getPreview(value[FileNameEnum.SUFFIX])
  if (fn && fileType) {
    const ext = FileSettingItems.fileTypeItems.find(
      ({ value }) => value === fileType
    )?.['extension']
    if (ext) {
      return fn + ext
    }
  }
  return fn
}

const FileName = (props) => {
  const { tt } = useContext(configContext)
  const { value, disabled, error, compliance, attribute, onChange } = props
  const [fileName, setFileName] = useState(value)
  const [showModal, setShowModal] = useState(false)
  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  useEffect(() => setFileName(value), [value])

  const preview = useMemo(
    () => getFileNamePreview(fileName, getLocalized, props.fileType),
    [fileName]
  )

  const name = fileName[FileNameEnum.NAME] || ''
  return (
    <>
      {onChange && (
        <div className={'marginBottom16'}>
          <TextBlack className={'marginBottom4'} disabled={disabled}>
            {getLocalized('file-name')}
          </TextBlack>
          <Button
            disabled={disabled}
            onClick={() => setShowModal(true)}
            id={attribute + '.addFileName'}
          >
            {getLocalized('set-file-name')}
          </Button>
          {error && (
            <WarningMessage
              id={attribute + '.error'}
              type={MessageTypesEnum.ERROR}
              message={getLocalized('common.errors.not-selected')}
            />
          )}
        </div>
      )}

      <PreviewItem
        label={`${localizationPath}.file-name-prefix`}
        value={fileName[FileNameEnum.PREFIX] || TABLE_CONSTANTS.NO_DATA}
        compliance={compliance?.[FileNameEnum.PREFIX]}
        className={'marginBottom16'}
      />
      <PreviewItem
        label={`${localizationPath}.default-file-name`}
        value={
          isTrueString(fileName[FileNameEnum.EDITABLE])
            ? getLocalized('default-file-name-editable', {
                name,
                editable: getLocalized('user-editable')
              })
            : name
        }
        compliance={compliance?.[FileNameEnum.EDITABLE]}
        className={'marginBottom16'}
      />
      <PreviewItem
        label={`${localizationPath}.file-name-suffix`}
        value={fileName[FileNameEnum.SUFFIX] || TABLE_CONSTANTS.NO_DATA}
        compliance={compliance?.[FileNameEnum.SUFFIX]}
        className={'marginBottom16'}
      />
      <PreviewItem
        label={`${localizationPath}.file-name-preview`}
        value={preview || TABLE_CONSTANTS.NO_DATA}
      />

      {showModal && (
        <FileNameModal
          simple={props.simple}
          getLocalized={getLocalized}
          value={fileName}
          onClose={() => setShowModal(false)}
          onChange={(v) => {
            setFileName(v)
            onChange(v)
          }}
          tokens={props.options}
        />
      )}
    </>
  )
}

export default memo(FileName)

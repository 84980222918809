import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from '../PoliciesConfigurationEnum'

export enum dstEnum {
  OFFSET_HOURS = 'offset-hours',
  OFFSET_MINUTES = 'offset-minutes',
  DST_START_COUNT = 'dst-start-day-count',
  DST_START_WEEK = 'dst-start-day-of-week',
  DST_START_MONTH = 'dst-start-month',
  DST_START_HOURS = 'dst-start-hours',
  DST_END_COUNT = 'dst-end-day-count',
  DST_END_WEEK = 'dst-end-day-of-week',
  DST_END_MONTH = 'dst-end-month',
  DST_END_HOURS = 'dst-end-hours',
  DST_OFFSET = 'dst-offset'
}

export interface TimeZoneItem extends Item {
  readonly [dstEnum.OFFSET_HOURS]: string
  readonly [dstEnum.OFFSET_MINUTES]: string
  readonly [dstEnum.DST_START_COUNT]?: string
  readonly [dstEnum.DST_START_WEEK]?: string
  readonly [dstEnum.DST_START_MONTH]?: string
  readonly [dstEnum.DST_START_HOURS]?: string
  readonly [dstEnum.DST_END_COUNT]?: string
  readonly [dstEnum.DST_END_WEEK]?: string
  readonly [dstEnum.DST_END_MONTH]?: string
  readonly [dstEnum.DST_END_HOURS]?: string
  readonly [dstEnum.DST_OFFSET]?: string
}

export default class TimeZoneItems {
  static timeZoneCodes: TimeZoneItem[] = [
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.gmt-plus-12`,
      label: 'zone-gmt-plus-12',
      [dstEnum.OFFSET_HOURS]: '-12',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.gmt-plus-11`,
      label: 'zone-gmt-plus-11',
      [dstEnum.OFFSET_HOURS]: '-11',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.pacific-pago_pago`,
      label: 'zone-pacific-pago_pago',
      [dstEnum.OFFSET_HOURS]: '-11',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.pacific-honolulu`,
      label: 'zone-pacific-honolulu',
      [dstEnum.OFFSET_HOURS]: '-10',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-anchorage`,
      label: 'zone-america-anchorage',
      [dstEnum.DST_START_COUNT]: '2',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '11',
      [dstEnum.DST_END_HOURS]: '2',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-9',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-los_angeles`,
      label: 'zone-america-los_angeles',
      [dstEnum.DST_START_COUNT]: '2',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '11',
      [dstEnum.DST_END_HOURS]: '2',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-8',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-tijuana`,
      label: 'zone-america-tijuana',
      [dstEnum.DST_START_COUNT]: '2',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '11',
      [dstEnum.DST_END_HOURS]: '2',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-8',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-phoenix`,
      label: 'zone-america-phoenix',
      [dstEnum.OFFSET_HOURS]: '-7',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-denver`,
      label: 'zone-america-denver',
      [dstEnum.DST_START_COUNT]: '2',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '11',
      [dstEnum.DST_END_HOURS]: '2',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-7',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-managua`,
      label: 'zone-america-managua',
      [dstEnum.OFFSET_HOURS]: '-6',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-chicago`,
      label: 'zone-america-chicago',
      [dstEnum.DST_START_COUNT]: '2',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '11',
      [dstEnum.DST_END_HOURS]: '2',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-6',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-chihuahua`,
      label: 'zone-america-chihuahua',
      [dstEnum.OFFSET_HOURS]: '-6',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-monterrey`,
      label: 'zone-america-monterrey',
      [dstEnum.OFFSET_HOURS]: '-6',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-regina`,
      label: 'zone-america-regina',
      [dstEnum.OFFSET_HOURS]: '-6',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-bogota`,
      label: 'zone-america-bogota',
      [dstEnum.OFFSET_HOURS]: '-5',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-cancun`,
      label: 'zone-america-cancun',
      [dstEnum.OFFSET_HOURS]: '-5',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-new_york`,
      label: 'zone-america-new_york',
      [dstEnum.DST_START_COUNT]: '2',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '11',
      [dstEnum.DST_END_HOURS]: '2',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-5',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-indiana-indianapolis`,
      label: 'zone-america-indiana-indianapolis',
      [dstEnum.DST_START_COUNT]: '2',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '11',
      [dstEnum.DST_END_HOURS]: '2',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-5',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-asuncion`,
      label: 'zone-america-asuncion',
      [dstEnum.DST_START_COUNT]: '1',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '10',
      [dstEnum.DST_START_HOURS]: '0',
      [dstEnum.DST_END_COUNT]: '4',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '3',
      [dstEnum.DST_END_HOURS]: '0',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-halifax`,
      label: 'zone-america-halifax',
      [dstEnum.DST_START_COUNT]: '2',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '11',
      [dstEnum.DST_END_HOURS]: '2',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-caracas`,
      label: 'zone-america-caracas',
      [dstEnum.OFFSET_HOURS]: '-4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-cuiaba`,
      label: 'zone-america-cuiaba',
      [dstEnum.OFFSET_HOURS]: '-4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-guyana`,
      label: 'zone-america-guyana',
      [dstEnum.OFFSET_HOURS]: '-4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-santiago`,
      label: 'zone-america-santiago',
      [dstEnum.DST_START_COUNT]: '1',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '9',
      [dstEnum.DST_START_HOURS]: '23',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '4',
      [dstEnum.DST_END_HOURS]: '23',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-st_johns`,
      label: 'zone-america-st_johns',
      [dstEnum.DST_START_COUNT]: '2',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '11',
      [dstEnum.DST_END_HOURS]: '2',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-3',
      [dstEnum.OFFSET_MINUTES]: '-30'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-recife`,
      label: 'zone-america-recife',
      [dstEnum.OFFSET_HOURS]: '-3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-fortaleza`,
      label: 'zone-america-fortaleza',
      [dstEnum.OFFSET_HOURS]: '-3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-argentina-buenos_aires`,
      label: 'zone-america-argentina-buenos_aires',
      [dstEnum.OFFSET_HOURS]: '-3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-montevideo`,
      label: 'zone-america-montevideo',
      [dstEnum.OFFSET_HOURS]: '-3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-bahia`,
      label: 'zone-america-bahia',
      [dstEnum.OFFSET_HOURS]: '-3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-nuuk`,
      label: 'zone-america-nuuk',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '23',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '23',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.america-noronha`,
      label: 'zone-america-noronha',
      [dstEnum.OFFSET_HOURS]: '-2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.atlantic-azores`,
      label: 'zone-atlantic-azores',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '0',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '10',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '-1',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.atlantic-cape_verde`,
      label: 'zone-atlantic-cape_verde',
      [dstEnum.OFFSET_HOURS]: '-1',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.gmt`,
      label: 'zone-gmt',
      [dstEnum.OFFSET_HOURS]: '0',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-london`,
      label: 'zone-europe-london',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '1',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '2',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '0',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.atlantic-reykjavik`,
      label: 'zone-atlantic-reykjavik',
      [dstEnum.OFFSET_HOURS]: '0',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-amsterdam`,
      label: 'zone-europe-amsterdam',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '3',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '1',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-belgrade`,
      label: 'zone-europe-belgrade',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '3',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '1',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-brussels`,
      label: 'zone-europe-brussels',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '3',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '1',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.africa-casablanca`,
      label: 'zone-africa-casablanca',
      [dstEnum.DST_START_COUNT]: '2',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '5',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '4',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '3',
      [dstEnum.DST_END_HOURS]: '3',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '1',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-sarajevo`,
      label: 'zone-europe-sarajevo',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '3',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '1',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.africa-algiers`,
      label: 'zone-africa-algiers',
      [dstEnum.OFFSET_HOURS]: '1',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-athens`,
      label: 'zone-europe-athens',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '3',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '4',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-beirut`,
      label: 'zone-asia-beirut',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '23',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '23',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.africa-cairo`,
      label: 'zone-africa-cairo',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '4',
      [dstEnum.DST_START_HOURS]: '23',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '23',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-chisineu`,
      label: 'zone-europe-chisineu',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '3',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.africa-harare`,
      label: 'zone-africa-harare',
      [dstEnum.OFFSET_HOURS]: '2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-helsinki`,
      label: 'zone-europe-helsinki',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '3',
      [dstEnum.DST_END_COUNT]: '5',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '4',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-jerusalim`,
      label: 'zone-asia-jerusalim',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '4',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '2',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-nicosia`,
      label: 'zone-asia-nicosia',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '3',
      [dstEnum.DST_START_HOURS]: '3',
      [dstEnum.DST_END_COUNT]: '4',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '10',
      [dstEnum.DST_END_HOURS]: '3',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-kaliningrad`,
      label: 'zone-europe-kaliningrad',
      [dstEnum.OFFSET_HOURS]: '2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.africa-tripoli`,
      label: 'zone-africa-tripoli',
      [dstEnum.OFFSET_HOURS]: '2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.africa-windhoek`,
      label: 'zone-africa-windhoek',
      [dstEnum.OFFSET_HOURS]: '2',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-amman`,
      label: 'zone-asia-amman',
      [dstEnum.OFFSET_HOURS]: '3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-baghdad`,
      label: 'zone-asia-baghdad',
      [dstEnum.OFFSET_HOURS]: '3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-damascus`,
      label: 'zone-asia-damascus',
      [dstEnum.OFFSET_HOURS]: '3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-istanbul`,
      label: 'zone-europe-istanbul',
      [dstEnum.OFFSET_HOURS]: '3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-minsk`,
      label: 'zone-europe-minsk',
      [dstEnum.OFFSET_HOURS]: '3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-moscow`,
      label: 'zone-europe-moscow',
      [dstEnum.OFFSET_HOURS]: '3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.africa-nairobi`,
      label: 'zone-africa-nairobi',
      [dstEnum.OFFSET_HOURS]: '3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-kuwait`,
      label: 'zone-asia-kuwait',
      [dstEnum.OFFSET_HOURS]: '3',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-tehran`,
      label: 'zone-asia-tehran',
      [dstEnum.OFFSET_HOURS]: '3',
      [dstEnum.OFFSET_MINUTES]: '30'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-muscat`,
      label: 'zone-asia-muscat',
      [dstEnum.OFFSET_HOURS]: '4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-baku`,
      label: 'zone-asia-baku',
      [dstEnum.OFFSET_HOURS]: '4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.europe-samara`,
      label: 'zone-europe-samara',
      [dstEnum.OFFSET_HOURS]: '4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.indian-mauritius`,
      label: 'zone-indian-mauritius',
      [dstEnum.OFFSET_HOURS]: '4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-tbilisi`,
      label: 'zone-asia-tbilisi',
      [dstEnum.OFFSET_HOURS]: '4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-yerevan`,
      label: 'zone-asia-yerevan',
      [dstEnum.OFFSET_HOURS]: '4',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-kabul`,
      label: 'zone-asia-kabul',
      [dstEnum.OFFSET_HOURS]: '4',
      [dstEnum.OFFSET_MINUTES]: '30'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-ashgabat`,
      label: 'zone-asia-ashgabat',
      [dstEnum.OFFSET_HOURS]: '5',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-almaty`,
      label: 'zone-asia-almaty',
      [dstEnum.OFFSET_HOURS]: '5',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-kalkata`,
      label: 'zone-asia-kalkata',
      [dstEnum.OFFSET_HOURS]: '5',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-yekaterinburg`,
      label: 'zone-asia-yekaterinburg',
      [dstEnum.OFFSET_HOURS]: '5',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-karachi`,
      label: 'zone-asia-karachi',
      [dstEnum.OFFSET_HOURS]: '5',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-colombo`,
      label: 'zone-asia-colombo',
      [dstEnum.OFFSET_HOURS]: '5',
      [dstEnum.OFFSET_MINUTES]: '30'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-kathmandu`,
      label: 'zone-asia-kathmandu',
      [dstEnum.OFFSET_HOURS]: '5',
      [dstEnum.OFFSET_MINUTES]: '45'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-dhaka`,
      label: 'zone-asia-dhaka',
      [dstEnum.OFFSET_HOURS]: '6',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-yangon`,
      label: 'zone-asia-yangon',
      [dstEnum.OFFSET_HOURS]: '6',
      [dstEnum.OFFSET_MINUTES]: '30'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-bangkok`,
      label: 'zone-asia-bangkok',
      [dstEnum.OFFSET_HOURS]: '7',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-krasnoyarsk`,
      label: 'zone-asia-krasnoyarsk',
      [dstEnum.OFFSET_HOURS]: '7',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-novosibirsk`,
      label: 'zone-asia-novosibirsk',
      [dstEnum.OFFSET_HOURS]: '7',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-shanghai`,
      label: 'zone-asia-shanghai',
      [dstEnum.OFFSET_HOURS]: '8',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-irkutsk`,
      label: 'zone-asia-irkutsk',
      [dstEnum.OFFSET_HOURS]: '8',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-kuala_lumpur`,
      label: 'zone-asia-kuala_lumpur',
      [dstEnum.OFFSET_HOURS]: '8',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.australia-perth`,
      label: 'zone-australia-perth',
      [dstEnum.OFFSET_HOURS]: '8',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-taipei`,
      label: 'zone-asia-taipei',
      [dstEnum.OFFSET_HOURS]: '8',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-ulaanbaatar`,
      label: 'zone-asia-ulaanbaatar',
      [dstEnum.OFFSET_HOURS]: '8',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-tokyo`,
      label: 'zone-asia-tokyo',
      [dstEnum.OFFSET_HOURS]: '9',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-seoul`,
      label: 'zone-asia-seoul',
      [dstEnum.OFFSET_HOURS]: '9',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-yakutsk`,
      label: 'zone-asia-yakutsk',
      [dstEnum.OFFSET_HOURS]: '9',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.australia-adelaide`,
      label: 'zone-australia-adelaide',
      [dstEnum.DST_START_COUNT]: '1',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '10',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '4',
      [dstEnum.DST_END_HOURS]: '3',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '9',
      [dstEnum.OFFSET_MINUTES]: '30'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.australia-darwin`,
      label: 'zone-australia-darwin',
      [dstEnum.OFFSET_HOURS]: '9',
      [dstEnum.OFFSET_MINUTES]: '30'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.australia-brisbane`,
      label: 'zone-australia-brisbane',
      [dstEnum.OFFSET_HOURS]: '10',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.australia-sydney`,
      label: 'zone-australia-sydney',
      [dstEnum.DST_START_COUNT]: '1',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '10',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '4',
      [dstEnum.DST_END_HOURS]: '3',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '10',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.pacific-guam`,
      label: 'zone-pacific-guam',
      [dstEnum.OFFSET_HOURS]: '10',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.australia-hobart`,
      label: 'zone-australia-hobart',
      [dstEnum.DST_START_COUNT]: '1',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '10',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '1',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '4',
      [dstEnum.DST_END_HOURS]: '3',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '10',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-vladivostok`,
      label: 'zone-asia-vladivostok',
      [dstEnum.OFFSET_HOURS]: '10',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-srednekolymsk`,
      label: 'zone-asia-srednekolymsk',
      [dstEnum.OFFSET_HOURS]: '11',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-magadan`,
      label: 'zone-asia-magadan',
      [dstEnum.OFFSET_HOURS]: '11',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.pacific-guadalcanal`,
      label: 'zone-pacific-guadalcanal',
      [dstEnum.OFFSET_HOURS]: '11',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.asia-anadyr`,
      label: 'zone-asia-anadyr',
      [dstEnum.OFFSET_HOURS]: '12',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.pacific-auckland`,
      label: 'zone-pacific-auckland',
      [dstEnum.DST_START_COUNT]: '5',
      [dstEnum.DST_START_WEEK]: '1',
      [dstEnum.DST_START_MONTH]: '9',
      [dstEnum.DST_START_HOURS]: '2',
      [dstEnum.DST_END_COUNT]: '3',
      [dstEnum.DST_END_WEEK]: '1',
      [dstEnum.DST_END_MONTH]: '3',
      [dstEnum.DST_END_HOURS]: '3',
      [dstEnum.DST_OFFSET]: '60',
      [dstEnum.OFFSET_HOURS]: '12',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.gmt-minus-12`,
      label: 'zone-gmt-minus-12',
      [dstEnum.OFFSET_HOURS]: '12',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.pacific-fiji`,
      label: 'zone-pacific-fiji',
      [dstEnum.OFFSET_HOURS]: '12',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.pacific-tongatapu`,
      label: 'zone-pacific-tongatapu',
      [dstEnum.OFFSET_HOURS]: '13',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.pacific-apia`,
      label: 'zone-pacific-apia',
      [dstEnum.OFFSET_HOURS]: '13',
      [dstEnum.OFFSET_MINUTES]: '0'
    },
    {
      value: `${PolicyAttributeEnum.Time_Zone}.zone.value.pacific-kiritimati`,
      label: 'zone-pacific-kiritimati',
      [dstEnum.OFFSET_HOURS]: '14',
      [dstEnum.OFFSET_MINUTES]: '0'
    }
  ]
}

import React, { useState } from 'react'
import {
  Button,
  ButtonGroup,
  IconCheckmark,
  IconChevronLeft,
  IconChevronRight,
  Stepper,
  StepsType,
  Modal
} from '@veneer/core'
import { StepperStatus } from 'components/policies/table/CreatePolicy'
import {
  FlexColumn,
  FlexRow,
  FlexRowWithSpace,
  FlexColumnGrow
} from 'styles/styles'
import {
  QuickSetsEnum,
  QuickSetsFileEnum,
  QuickSetsMessageEnum
} from './QuickSetsEnums'
import { EmailMessageEnum } from '../EmailMessage/EmailMessageEnums'
import AddQuickSetStep1 from './AddQuickSetStep1'
import AddQuickSetStep2, {
  isFieldEnabled as isEmailFieldEnabled,
  checkConstraints as checkConstraintsEmail
} from './AddQuickSetStep2'
import AddQuickSetStep3, {
  fileOptions,
  isFieldEnabled as isFileFieldEnabled,
  checkConstraints as checkConstraintsFile
} from './AddQuickSetStep3'
import AddQuickSetStep4 from './AddQuickSetStep4'
import { getFileNamePreview } from 'components/policies/modal/FileName'
import { FileNameEnum } from 'components/policies/modal/FileName/FileNameModal'
import { getError } from 'common/utilities'
import PoliciesErrors from 'context/policies/PoliciesErrors'

enum QuickSetsSteps {
  BASIC_INFO,
  EMAIL_SETTINGS,
  COMMON_JOB,
  CONFIRMATION
}

const requiredIdsStep1: string[] = [
  QuickSetsEnum.TITLE,
  QuickSetsEnum.LOCATION,
  QuickSetsEnum.START
]
const requiredIdsStep2: string[] = [
  QuickSetsMessageEnum.RESTRICTIONS,
  EmailMessageEnum.FROM,
  EmailMessageEnum.TO,
  EmailMessageEnum.CC,
  EmailMessageEnum.BCC,
  EmailMessageEnum.DEF_NAME,
  EmailMessageEnum.DEF_FROM,
  EmailMessageEnum.SUBJECT,
  EmailMessageEnum.MESSAGE,
  EmailMessageEnum.TO + QuickSetsMessageEnum.ADDRESSES,
  EmailMessageEnum.CC + QuickSetsMessageEnum.ADDRESSES,
  EmailMessageEnum.BCC + QuickSetsMessageEnum.ADDRESSES
]
const requiredIdsStep3: string[] = [
  QuickSetsFileEnum.FILE_NAME,
  QuickSetsFileEnum.MEDIA_SIZE,
  QuickSetsFileEnum.SCAN_PLEX,
  QuickSetsFileEnum.COLOR_PREFERENCE,
  QuickSetsFileEnum.QUALITY,
  QuickSetsFileEnum.TYPE,
  QuickSetsFileEnum.RESOLUTION,
  QuickSetsFileEnum.COMPRESSION,
  QuickSetsFileEnum.COMPRESSION_BLACK,
  QuickSetsFileEnum.COMPRESSION_COLOR
]

export const errorKeys = [
  'common.errors.not-selected',
  'error_empty',
  'error_all_blank'
]

const QuickSetsModal = (props) => {
  const { edit, value, constraints, onClose, onChange } = props

  const getLocalized = (key: string, step = 0, params?): string =>
    props.getLocalized(
      step
        ? `policy.settings.attributes.quick-sets.device_settings.step-${step}.${key}`
        : key,
      params
    )

  const createAddQuickSetsSteps = (): StepsType[] => [
    {
      label: getLocalized('stepper-title', 1),
      status: StepperStatus.Current,
      step: 1
    },
    {
      label: getLocalized('stepper-title', 2),
      status: StepperStatus.Incomplete,
      step: 2
    },
    {
      label: getLocalized('stepper-title', 3),
      status: StepperStatus.Incomplete,
      step: 3
    },
    {
      label: getLocalized('stepper-title', 4),
      status: StepperStatus.Incomplete,
      step: 4
    }
  ]

  const preProcessValue = () => {
    if (!constraints) {
      return value
    }
    const quickSet = { ...value }
    const fileItems = [
      { id: QuickSetsFileEnum.FILE_NAME },
      { id: QuickSetsFileEnum.MEDIA_SIZE },
      { id: QuickSetsFileEnum.SCAN_PLEX },
      { id: QuickSetsFileEnum.COLOR_PREFERENCE },
      { id: QuickSetsFileEnum.QUALITY },
      { id: QuickSetsFileEnum.TYPE },
      { id: QuickSetsFileEnum.RESOLUTION },
      { id: QuickSetsFileEnum.COMPRESSION },
      { id: QuickSetsFileEnum.COMPRESSION_BLACK },
      { id: QuickSetsFileEnum.COMPRESSION_COLOR },
      { id: QuickSetsFileEnum.PDF_ENCRYPTION },
      { id: QuickSetsFileEnum.BLANK_PAGE_SUPPRESSION }
    ]
    const items = [
      { id: QuickSetsMessageEnum.RESTRICTIONS },
      { id: EmailMessageEnum.FROM + EmailMessageEnum.EDITABLE },
      { id: EmailMessageEnum.CC },
      { id: EmailMessageEnum.CC + QuickSetsMessageEnum.ADDRESSES },
      { id: EmailMessageEnum.CC + EmailMessageEnum.EDITABLE },
      { id: EmailMessageEnum.BCC },
      { id: EmailMessageEnum.BCC + QuickSetsMessageEnum.ADDRESSES },
      { id: EmailMessageEnum.BCC + EmailMessageEnum.EDITABLE },
      { id: EmailMessageEnum.SUBJECT + EmailMessageEnum.EDITABLE },
      { id: EmailMessageEnum.MESSAGE + EmailMessageEnum.EDITABLE },
      { id: QuickSetsMessageEnum.SIGN },
      { id: QuickSetsMessageEnum.SIGN + EmailMessageEnum.EDITABLE },
      { id: QuickSetsMessageEnum.ENCRYPT },
      { id: QuickSetsMessageEnum.ENCRYPT + EmailMessageEnum.EDITABLE }
    ]
    items.forEach((item) => {
      if (!checkConstraintsEmail(constraints, item.id)) {
        delete quickSet[QuickSetsEnum.MESSAGE][item.id]
      }
    })
    fileItems.forEach((item) => {
      if (!checkConstraintsFile(constraints, item.id)) {
        if (item.id === QuickSetsFileEnum.FILE_NAME) {
          delete quickSet[QuickSetsEnum.FILE][FileNameEnum.NAME]
          delete quickSet[QuickSetsEnum.FILE][FileNameEnum.EDITABLE]
          delete quickSet[QuickSetsEnum.FILE][FileNameEnum.SUFFIX]
        } else {
          delete quickSet[QuickSetsEnum.FILE][item.id]
        }
      } else if (fileOptions[item.id]) {
        const options = fileOptions[item.id](constraints)
        const value = quickSet[QuickSetsEnum.FILE][item.id]
        if (!options.some((option) => option.value === value)) {
          quickSet[QuickSetsEnum.FILE][item.id] = ''
        }
      }
    })
    return quickSet
  }

  const [stepItems, setStepItems] = useState(createAddQuickSetsSteps())
  const [currentStep, setCurrentStep] = useState(0)
  const [quickSetObject, setQuickSetObject] = useState(preProcessValue)
  const [requiredErrors, setRequiredErrors] = useState({})

  const instantStart =
    quickSetObject[QuickSetsEnum.START] === 'quick-sets.sets.start.instantly'

  const createModalFooter = () => {
    return (
      <FlexRowWithSpace className={'footerBorder fullWidth'}>
        <div>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </div>
        <ButtonGroup>
          {currentStep > 0 && (
            <Button
              appearance={'secondary'}
              onClick={handleBackBtn}
              leadingIcon={<IconChevronLeft />}
              id={'backBtn'}
            >
              {getLocalized('common.back')}
            </Button>
          )}

          {currentStep < stepItems.length - 1 ? (
            <Button
              appearance={'primary'}
              onClick={handleNextBtn}
              trailingIcon={<IconChevronRight />}
              id={'nextBtn'}
            >
              {getLocalized('common.next')}
            </Button>
          ) : (
            <Button
              appearance={'primary'}
              onClick={handleSaveBtn}
              id={'saveBtn'}
            >
              {getLocalized(edit ? 'common.save' : 'common.add')}
            </Button>
          )}
        </ButtonGroup>
      </FlexRowWithSpace>
    )
  }

  const validateForm = () => {
    switch (currentStep) {
      case QuickSetsSteps.BASIC_INFO:
        return validateBasicInfo()

      case QuickSetsSteps.EMAIL_SETTINGS:
        return validateEmailSettings()

      case QuickSetsSteps.COMMON_JOB:
        return validateFileSettings()
    }
  }

  const validateBasicInfo = () => {
    const errors = { ...requiredErrors }
    requiredIdsStep1.forEach((id) => {
      if (!quickSetObject[id]) {
        errors[id] = {
          message: errorKeys[+(id === QuickSetsEnum.TITLE)] // 0 or 1
        }
      } else {
        delete errors[id]
      }
    })
    setRequiredErrors(errors)
    return Object.keys(errors).some((key) => requiredIdsStep1.includes(key))
  }

  const getOtherErrors = (id, msg) => {
    // Handle All addresses are blank error
    if (
      id === EmailMessageEnum.TO &&
      instantStart &&
      [EmailMessageEnum.TO, EmailMessageEnum.CC, EmailMessageEnum.BCC].every(
        (y) => msg[y] === 'message-recipient.blank'
      )
    ) {
      return { message: errorKeys[2] }
    }
    return msg[id] ? null : { message: errorKeys[0] } // default error
  }

  const validateEmailSettings = () => {
    const errors = { ...requiredErrors }
    const message = quickSetObject[QuickSetsEnum.MESSAGE]
    requiredIdsStep2
      .filter((id) => checkConstraintsEmail(constraints, id))
      .forEach((id) => {
        const getErrorObject = (x) => {
          switch (id) {
            case EmailMessageEnum.DEF_FROM:
              return getError(PoliciesErrors.errorsInvalidEmail, x)
            case EmailMessageEnum.DEF_NAME:
              return getError(PoliciesErrors.errorsDefFromName(constraints), x)
            case EmailMessageEnum.SUBJECT:
              return getError(PoliciesErrors.errorsSubject(constraints), x)
            case EmailMessageEnum.MESSAGE:
              return getError(PoliciesErrors.errorsMessage(constraints), x)
            case EmailMessageEnum.TO + QuickSetsMessageEnum.ADDRESSES:
            case EmailMessageEnum.CC + QuickSetsMessageEnum.ADDRESSES:
            case EmailMessageEnum.BCC + QuickSetsMessageEnum.ADDRESSES:
              return getError(PoliciesErrors.errorsInvalidEmailList, x)
            default:
              return getOtherErrors(id, message)
          }
        }
        const error = getErrorObject(message[id])
        if (error && isEmailFieldEnabled(message, id)) {
          errors[id] = error
        } else {
          delete errors[id]
        }
      })
    setRequiredErrors(errors)
    return Object.keys(errors).some((key) => requiredIdsStep2.includes(key))
  }

  const validateFileSettings = () => {
    const errors = { ...requiredErrors }
    const file = quickSetObject[QuickSetsEnum.FILE] || {}
    requiredIdsStep3
      .filter((id) => checkConstraintsFile(constraints, id))
      .forEach((id) => {
        const error =
          id === QuickSetsFileEnum.FILE_NAME
            ? !getFileNamePreview(file).length
            : !file[id]
        if (error && isFileFieldEnabled(file, id)) {
          errors[id] = { message: errorKeys[0] }
        } else {
          delete errors[id]
        }
      })
    if (!checkConstraintsFile(constraints, QuickSetsFileEnum.FILE_NAME)) {
      // Prefix only
      const err = getError(
        PoliciesErrors.errorsPrefix,
        file[FileNameEnum.PREFIX]
      )
      if (err) {
        errors[QuickSetsFileEnum.FILE_NAME] = err
      } else {
        delete errors[QuickSetsFileEnum.FILE_NAME]
      }
    }
    setRequiredErrors(errors)
    return Object.keys(errors).some((key) => requiredIdsStep3.includes(key))
  }

  const handleBackBtn = () => {
    const newStepItems = [...stepItems]
    newStepItems[currentStep - 1].status = StepperStatus.Current
    newStepItems[currentStep].status = StepperStatus.Incomplete
    setStepItems(newStepItems)
    setCurrentStep(currentStep - 1)
  }

  const handleNextBtn = () => {
    if (validateForm()) {
      return
    }
    const newStepItems = [...stepItems]
    const oldSelectedItem = newStepItems[currentStep]
    newStepItems[currentStep + 1].status = StepperStatus.Current
    oldSelectedItem.status = StepperStatus.Complete
    oldSelectedItem.step = <IconCheckmark filled />
    setRequiredErrors({})
    setStepItems(newStepItems)
    setCurrentStep(currentStep + 1)
  }

  const handleSaveBtn = () => {
    if (!Object.keys(requiredErrors).length) {
      onChange(quickSetObject)
      onClose()
    }
  }

  const getStepWiseComponent = () => {
    switch (currentStep) {
      case QuickSetsSteps.BASIC_INFO:
        return (
          <AddQuickSetStep1
            value={quickSetObject}
            onChange={(v) => setQuickSetObject((set) => ({ ...set, ...v }))}
            getLocalized={getLocalized}
            requiredErrors={requiredErrors}
            setRequiredErrors={setRequiredErrors}
          />
        )
      case QuickSetsSteps.EMAIL_SETTINGS:
        return (
          <AddQuickSetStep2
            value={quickSetObject[QuickSetsEnum.MESSAGE] || {}}
            instantStart={instantStart}
            constraints={constraints}
            onChange={(v) =>
              setQuickSetObject((set) => ({
                ...set,
                [QuickSetsEnum.MESSAGE]: { ...set[QuickSetsEnum.MESSAGE], ...v }
              }))
            }
            getLocalized={getLocalized}
            requiredErrors={requiredErrors}
            setRequiredErrors={setRequiredErrors}
          />
        )
      case QuickSetsSteps.COMMON_JOB:
        return (
          <AddQuickSetStep3
            value={quickSetObject[QuickSetsEnum.FILE] || {}}
            onChange={(v) =>
              setQuickSetObject((set) => ({
                ...set,
                [QuickSetsEnum.FILE]: { ...set[QuickSetsEnum.FILE], ...v }
              }))
            }
            constraints={constraints}
            getLocalized={getLocalized}
            requiredErrors={requiredErrors}
            setRequiredErrors={setRequiredErrors}
          />
        )
      case QuickSetsSteps.CONFIRMATION:
        return (
          <AddQuickSetStep4
            value={quickSetObject}
            getLocalized={getLocalized}
          />
        )
    }
  }

  return (
    <>
      <Modal
        className={'full-window-modal quick-set-modal-footer'}
        onClose={() => onClose()}
        closeOnBlur={false}
        show={true}
        footer={createModalFooter()}
        data-testid={'id-quick-sets-modal'}
      >
        <FlexRow className={'fullHeight'}>
          <FlexColumnGrow className={'sidebarWidth260 borderTopLeftRadius10'}>
            <aside className={'padding24'}>
              <h5 className={'paddingTop8'}>
                {getLocalized(edit ? 'edit-quick-set' : 'add-quick-set')}
              </h5>
              <Stepper
                steps={stepItems}
                appearance={'standard'}
                direction={'vertical'}
                interactive
                data-testid={'id-quick-sets-stepper'}
              />
            </aside>
          </FlexColumnGrow>
          <FlexColumn className={'quickset-modal-body'}>
            {getStepWiseComponent()}
          </FlexColumn>
        </FlexRow>
      </Modal>
    </>
  )
}

export default QuickSetsModal

import { Table } from '@veneer/core'
import React, { useMemo, useState } from 'react'
import {
  TableData,
  SortTypes,
  TableSortBy
} from '@veneer/core/dist/scripts/table'
import {
  getComplianceReason,
  PolicyAttributeComplianceEnum
} from 'components/policies/constants'
import { abcSort, TABLE_CONSTANTS } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import 'styles/global.scss'

const columnId = 'id'
const columnSettings = 'settings'
const columnPolicy = 'policy'
const columnCompliance = 'compliance'
const columnReason = 'reason'

const defSortOrder: SortTypes = 'descending'
const defComplianceSorting: TableSortBy = {
  id: columnCompliance,
  type: defSortOrder
}

const ComplianceTable = (props) => {
  const { allAttributes, getLocalized, checkIcon } = props
  const [sorting, setSorting] = useState(defComplianceSorting)

  const filteredData = useMemo(() => {
    const reasonText = (item) => {
      if (item.status !== PolicyAttributeComplianceEnum.NONCOMPLIANT) {
        return TABLE_CONSTANTS.NO_DATA
      }
      const reasons = item.settings.reduce((acc, setting) => {
        const reason = getComplianceReason(setting.status, setting.reason)
        if (reason && !acc.includes(reason)) {
          acc.push(reason)
        }
        return acc
      }, [])
      return reasons.map((x) => getLocalized('reason-' + x)).join(', ')
    }

    return allAttributes?.map((item) => ({
      settings: getLocalized(`policy.settings.attributes.${item.name}.name`),
      policy: item.policyName,
      compliance: checkIcon(item.status, 20), // smaller icon size
      reason: reasonText(item),
      status: item.status, // for sorting
      id: item.name
    }))
  }, [allAttributes, checkIcon, getLocalized])

  const tableData = useMemo(() => {
    const complianceTypeKeys: string[] = Object.values(
      PolicyAttributeComplianceEnum
    )
    const picker = () => {
      switch (sorting.id) {
        case columnCompliance:
          return (x) => complianceTypeKeys.indexOf(x.status)
        case columnSettings:
          return (x) => x.settings
        case columnReason:
          return (x) => x.reason
      }
      // columnPolicy
      return (x) => x.policy
    }
    return filteredData
      ? (abcSort([...filteredData], picker(), sorting.type) as TableData[])
      : null
  }, [filteredData, sorting])

  return (
    <Table
      columns={[
        {
          id: columnSettings,
          label: getLocalized(columnSettings),
          sortable: true
        },
        {
          id: columnPolicy,
          label: getLocalized(columnPolicy),
          sortable: true
        },
        {
          id: columnCompliance,
          label: getLocalized(columnCompliance),
          sortable: true
        },
        {
          id: columnReason,
          label: getLocalized(columnReason),
          sortable: true
        },
        {
          id: columnId,
          label: columnId,
          index: 'hidden'
        }
      ]}
      data={tableData || []}
      loading={tableData === null}
      loadingDataLength={25}
      onSort={(_, sortBy) => setSorting(sortBy)}
      preferences={{
        sortBy: sorting,
        width: [
          { columnId: columnSettings, width: 240 },
          { columnId: columnPolicy, width: 240 },
          { columnId: columnCompliance, width: 160 },
          { columnId: columnReason, width: 160 }
        ]
      }}
      i18n={Retrievei18nItems()}
    />
  )
}
export default ComplianceTable

import React, { useContext, useEffect, useState, memo } from 'react'
import configContext from 'context/config/configContext'
import settingsContext from 'context/settings/settingsContext'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { FwUpdateScheduleDescription } from 'context/policies/PoliciesConfigurationEnum'
import { Select } from '@veneer/core'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import LabelSelect from 'common/controls/labelSelect'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import {
  WEEK_DAYS,
  isPm,
  hours24to12,
  hours12to24,
  formatDate,
  DATE_FORMAT
} from 'common/utilities'
import TimeItems from 'context/policies/dropboxItems/TimeItems'
import { FlexColumn, FlexRow, TextBlack } from 'styles/styles'
import './index.scss'
import '../index.scss'
import 'styles/global.scss'

const noSchedule = 'noSchedule'
const setSchedule = 'schedule'
const daily = 'daily'
const weekly = 'weekly'

const FwUpdateScheduleControl = (props) => {
  const description: FwUpdateScheduleDescription = props.description
  const { attribute, label, help } = description
  const {
    id,
    attributes,
    localizationPath,
    data: { deviceSettings },
    onAttributeChange
  } = props

  const { tt, is12HourFormat } = useContext(configContext)
  const { isEnabled, addDisabled, removeDisabled, addError, removeError } =
    useContext(settingsContext)

  const getLocalized = (key: string): string => tt(props.localizationPath, key)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )

  const shiftDays = (days, delta) => {
    if (delta > 1) {
      delta = 7 - 1
    } else if (delta < -1) {
      delta = 7 + 1
    } else {
      delta += 7
    }
    const newDays = days.map((x) => ((+x + delta) % 7).toString())
    newDays.sort()
    return newDays
  }

  const getUiValues = (value) => {
    let selectedButton = noSchedule
    let selectedRecurrence = daily
    const date = new Date()
    let selectedDays = [date.getDay().toString()]

    if (value) {
      selectedButton = setSchedule
      const times = value.split(' ')
      const selDays = times[times.length - 1]
      if (selDays !== '*') {
        selectedRecurrence = weekly
        selectedDays = selDays ? selDays.split(',') : []
      }
      if (times.length < 5) {
        return {
          selectedButton,
          selectedRecurrence,
          hours: times[1],
          minutes: times[0],
          timeFormat: times[2],
          selectedDays
        }
      }
      date.setUTCHours(+times[1], +times[0])
      if (selectedRecurrence === weekly) {
        // shift days only if they came from correct cron string
        const delta = date.getDate() - date.getUTCDate() || 0
        selectedDays = shiftDays(selectedDays, delta)
      }
    }
    const localHours = date.getHours()
    return {
      selectedButton,
      hours: hours24to12(localHours, is12HourFormat).toString(),
      minutes: (Math.floor(date.getMinutes() / 10) * 10).toString(),
      timeFormat: TimeItems.amPms[+isPm(localHours)].value,
      selectedRecurrence,
      selectedDays
    }
  }

  const getCron = (newUiValues) => {
    if (newUiValues.selectedButton === setSchedule) {
      const amPm = newUiValues.timeFormat
      let selectedDays = newUiValues.selectedRecurrence === daily ? '*' : null

      if (!newUiValues.selectedDays.length) {
        // Error string value consists of 4 tokens, not 5
        return `${newUiValues.minutes} ${newUiValues.hours} ${amPm} ${
          selectedDays || newUiValues.selectedDays.join()
        }`
      }

      const date = new Date()
      const hours = hours12to24(
        +newUiValues.hours,
        amPm === TimeItems.amPms[1].value,
        is12HourFormat
      ) as number
      date.setHours(hours, newUiValues.minutes)
      if (!selectedDays) {
        const delta = date.getUTCDate() - date.getDate() || 0
        selectedDays = shiftDays(newUiValues.selectedDays, delta).join()
      }
      return `${date.getUTCMinutes()} ${date.getUTCHours()} * * ${selectedDays}`
    }
    return ''
  }

  const [uiValues, setUiValues] = useState(getUiValues(value))
  const [error, setError] = useState(!uiValues.selectedDays.length)

  const processError = (
    enable: boolean,
    values: {
      selectedButton: string
      hours: string
      minutes: string
      selectedRecurrence: string
      selectedDays: string[]
    }
  ): void => {
    const err =
      enable &&
      values.selectedButton === setSchedule &&
      values.selectedRecurrence === weekly &&
      !values.selectedDays.length
    setError(err)
    err ? addError(id, attribute) : removeError(id, attribute)
  }

  const enabled = isEnabled(attribute)
  useEffect(() => processError(enabled, uiValues), [enabled])

  useEffect(() => {
    setValue(PoliciesHelper.getData(description, deviceSettings))
  }, [deviceSettings])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const val = PoliciesHelper.getData(description, deviceSettings)
    if (val === value) {
      const newUiValues = getUiValues(value)
      setUiValues(newUiValues)
      processError(enabled, newUiValues)
      if (attributes) {
        PoliciesHelper.update(
          description,
          value,
          (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
          props.onSettingsChanges,
          attributes
        )
      }
    }
  }, [value, attributes])

  const handleChange = (newUiValues) => {
    setUiValues(newUiValues)
    const settings = [...deviceSettings]
    const val = getCron(newUiValues)
    PoliciesHelper.setData(description, settings, val)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const handleTimeFormat = (data) =>
    handleChange({ ...uiValues, timeFormat: data.value })

  const scheduleHandle = (_, option) =>
    handleChange({ ...uiValues, selectedButton: option })

  const handleMinutes = (value) => handleChange({ ...uiValues, minutes: value })

  const handleHours = (value) => handleChange({ ...uiValues, hours: value })

  const recurrenceHandle = (_, option) =>
    handleChange({ ...uiValues, selectedRecurrence: option })

  const setDays = ({ value: day }) => {
    const selectedDays = uiValues.selectedDays.filter((item) => item !== day)
    if (selectedDays.length === uiValues.selectedDays.length) {
      selectedDays.push(day)
      selectedDays.sort()
    }
    handleChange({ ...uiValues, selectedDays })
  }

  const clearDays = () => handleChange({ ...uiValues, selectedDays: [] })

  const scheduleOptions = [
    { value: noSchedule, label: getLocalized('schedule-noSchedule') },
    { value: setSchedule, label: getLocalized('schedule-setSchedule') }
  ]

  const recurrenceOptions = [
    { value: daily, label: getLocalized('daily') },
    { value: weekly, label: getLocalized('weekly') }
  ]

  const amPmOptions = TimeItems.amPms.map((x) => {
    return { ...x, label: getLocalized(x.label) }
  })

  const weekDayKeys = WEEK_DAYS.map((day) => 'policy.settings.week-days.' + day)
  const weekDays = weekDayKeys.map((day, i) => ({
    label: getLocalized(day),
    value: i.toString()
  }))

  const getTime = (uiValues) => {
    const date = new Date()
    const hours = hours12to24(
      parseInt(uiValues.hours, 10),
      uiValues.timeFormat === TimeItems.amPms[1].value,
      is12HourFormat
    ) as number
    date.setHours(hours)
    date.setMinutes(parseInt(uiValues.minutes, 10))
    return formatDate(date, DATE_FORMAT.START_END_TIME)
  }

  return onAttributeChange ? (
    <>
      <LabelRadioButtons
        label={getLocalized(label)}
        id={'radiobutton_default'}
        value={uiValues.selectedButton}
        onChange={scheduleHandle}
        disabled={!enabled}
        options={scheduleOptions}
        helpButton={
          <HelpButton enabled={enabled} description={getLocalized(help)} />
        }
      />
      {uiValues.selectedButton === setSchedule && (
        <FlexColumn className={'paddingTop16 devSettingsIndent1'}>
          <LabelRadioButtons
            label={getLocalized('recurrence')}
            id={'dailyWeekly'}
            value={uiValues.selectedRecurrence}
            onChange={recurrenceHandle}
            options={recurrenceOptions}
            helpButton={
              <HelpButton
                enabled={enabled}
                description={getLocalized('recurrence_help')}
              />
            }
          />
          <FlexColumn className={'paddingTop16 devSettingsIndent1'}>
            <LabelSelect
              multiple
              disabled={!enabled || uiValues.selectedRecurrence === daily}
              id={'fw-schedule-days'}
              className={'maxTextWidth'}
              options={weekDays}
              placeholder={getLocalized('common.select-option')}
              value={uiValues.selectedDays}
              error={error}
              helperText={error ? getLocalized('error-no-selected-days') : null}
              onChange={setDays}
              onClear={clearDays}
            />
            <FlexColumn className={'paddingTop16 devSettingsIndent1'}>
              <TextBlack disabled={!enabled} className={'marginBottom4'}>
                {getLocalized('start-time')}
              </TextBlack>
              <FlexRow className={'alignCenter'}>
                <Select
                  options={
                    is12HourFormat ? TimeItems.hours12 : TimeItems.hours24
                  }
                  placeholder={getLocalized('common.select-option')}
                  className={'formatSelect'}
                  id={'id-start-time-hours'}
                  disabled={!enabled}
                  clearIcon={false}
                  value={[uiValues.hours]}
                  onChange={({ value }) => handleHours(value)}
                />
                <TextBlack className={'paddingLeft4'}>
                  {getLocalized('policy.settings.time.separator')}
                </TextBlack>
                <Select
                  options={TimeItems.minutesBy10}
                  placeholder={getLocalized('common.select-option')}
                  id={'id-start-time-minutes'}
                  disabled={!enabled}
                  clearIcon={false}
                  className={'formatSelect paddingLeft4'}
                  value={[uiValues.minutes]}
                  onChange={({ value }) => handleMinutes(value)}
                />
                {is12HourFormat && (
                  <Select
                    options={amPmOptions}
                    placeholder={getLocalized('common.select-option')}
                    id={'id-start-time-ampm'}
                    clearIcon={false}
                    onChange={handleTimeFormat}
                    value={[uiValues.timeFormat]}
                    disabled={!enabled}
                    className={'formatSelect paddingLeft4'}
                  />
                )}
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
      )}
    </>
  ) : (
    <>
      <PreviewItem
        label={`${localizationPath}.${label}`}
        value={
          scheduleOptions[+(uiValues.selectedButton === setSchedule)].label
        }
      />
      {uiValues.selectedButton === setSchedule && (
        <FlexColumn>
          <PreviewItem
            className={'paddingTop16'}
            label={`${localizationPath}.recurrence`}
            value={
              uiValues.selectedRecurrence === daily
                ? recurrenceOptions[0].label
                : `${recurrenceOptions[1].label} ({{0-${uiValues.selectedDays.length}}})`
            }
            keys={uiValues.selectedDays.map((x) => weekDayKeys[x])}
          />
          <PreviewItem
            className={'paddingTop16'}
            label={`${localizationPath}.start-time`}
            value={getTime(uiValues)}
          />
        </FlexColumn>
      )}
    </>
  )
}

export default memo(FwUpdateScheduleControl)

import React, { useEffect, useState, useMemo } from 'react'
import {
  Button,
  ButtonGroup,
  Checkbox,
  IconPlus,
  Modal,
  Select,
  TextArea
} from '@veneer/core'
import { FlexColumn, FlexRow, TextBlack } from 'styles/styles'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import WarningMessage from 'components/policies/settings/attributes/device/controls/WarningMessage'
import LabelTextBox from 'common/controls/labelTextBox'
import PoliciesErrors, {
  MessageTypesEnum
} from 'context/policies/PoliciesErrors'
import { isTrueString, getError } from 'common/utilities'

export enum FileNameEnum {
  PREFIX = 'prefix',
  NAME = 'name',
  SUFFIX = 'suffix',
  EDITABLE = 'editable',
  OBJECT = '.name.',
  EMPTY = 'empty' // for error message
}
const FileNameModal = (props) => {
  const { getLocalized, onChange, onClose, value, simple, tokens } = props
  const [inputValue, setInputValue] = useState(value)
  const [prefixValue, setPrefixValue] = useState('')
  const [suffixValue, setSuffixValue] = useState('')
  const [errors, setErrors] = useState({})
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const getEmptyError = (key?: string, value?: string) => {
    const newValue = key ? { ...inputValue, [key]: value } : inputValue
    return !(
      newValue[FileNameEnum.PREFIX] ||
      newValue[FileNameEnum.SUFFIX] ||
      newValue[FileNameEnum.NAME]
    )
      ? { message: 'value-required' }
      : null
  }

  const errorsFileName2 = PoliciesErrors.errorsFileName.slice(1)
  const setError = (key, error) =>
    setErrors((prevErrors) => ({ ...prevErrors, [key]: error }))

  useEffect(
    () =>
      setIsSaveDisabled(JSON.stringify(inputValue) === JSON.stringify(value)),
    [inputValue, value]
  )

  const onSave = () => {
    const currentErrors = {
      [FileNameEnum.PREFIX]: getError(
        errorsFileName2,
        inputValue[FileNameEnum.PREFIX]
      ),
      [FileNameEnum.NAME]: getError(
        PoliciesErrors.errorsFileName,
        inputValue[FileNameEnum.NAME]
      ),
      [FileNameEnum.SUFFIX]: getError(
        errorsFileName2,
        inputValue[FileNameEnum.SUFFIX]
      ),
      [FileNameEnum.EMPTY]: getEmptyError(FileNameEnum.EMPTY)
    }
    setErrors(currentErrors)

    if (!Object.values(currentErrors).some((x) => x)) {
      onChange(inputValue)
      onClose()
    }
  }

  const handleAdd = (key, value) => {
    setError(FileNameEnum.EMPTY, null)
    setInputValue({ ...inputValue, [key]: (inputValue[key] || '') + value })
  }
  const handleChange = (key, data) =>
    key === FileNameEnum.PREFIX ? setPrefixValue(data) : setSuffixValue(data)

  const handleAddEdit = (key, value) => {
    setError(FileNameEnum.EMPTY, getEmptyError(key, value))
    setInputValue((prevValue) => ({ ...prevValue, [key]: value }))
  }

  const options = useMemo(
    () =>
      (!simple ? tokens.filter((x) => x.value) : tokens).map((item) => ({
        value: item.value,
        label: getLocalized(item.label)
      })),
    []
  )

  const fileNameComponent = (type, value) => {
    const hint = `enter-${type}`
    const placeholder = getLocalized(`file-name-${type}`)
    return (
      <>
        <FlexRow className={'paddingTop16 alignCenter marginBottom4'}>
          <TextBlack>{getLocalized(`file-name-${type}`)}</TextBlack>
          {!simple && (
            <FlexRow className={'paddingLeft4 lineBreak'}>
              <HelpButton enabled description={getLocalized(`${type}_help`)} />
            </FlexRow>
          )}
        </FlexRow>
        {simple ? (
          <Select
            value={[inputValue[type]]}
            options={options}
            clearIcon={false}
            onChange={(data) => handleAddEdit(type, data.value)}
            placeholder={placeholder}
            id={`${type}`}
          />
        ) : (
          <FlexColumn>
            <FlexRow>
              <Select
                className={'paddingRight12'}
                options={options}
                clearIcon={false}
                onChange={(data) => handleChange(type, data.value)}
                placeholder={placeholder}
                id={`${type}`}
              />
              <Button
                leadingIcon={<IconPlus />}
                onClick={() => handleAdd(type, value)}
                id={'iconPlus'}
                disabled={
                  !(type === FileNameEnum.PREFIX ? prefixValue : suffixValue)
                }
              />
            </FlexRow>
            <TextArea
              id={'textArea'}
              value={inputValue[type]}
              onChange={(value) => {
                setError(type, getError(errorsFileName2, value))
                handleAddEdit(type, value)
              }}
              placeholder={getLocalized(hint)}
              className={'textAreaHeight paddingTop16'}
              helperText={getLocalized(
                errors[type]?.message || hint,
                errors[type]?.params
              )}
              error={!!errors[type]}
            />
          </FlexColumn>
        )}
      </>
    )
  }

  const hint = 'default-file-name'
  return (
    <Modal
      footer={
        <ButtonGroup>
          <Button onClick={onSave} disabled={isSaveDisabled}>
            {getLocalized('common.save')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      className={'small-policy-modal'}
      title={getLocalized('set-file-name')}
      data-testid={'id-file-name-modal'}
    >
      <TextBlack>{getLocalized('description-message')}</TextBlack>
      {fileNameComponent(FileNameEnum.PREFIX, prefixValue)}

      <div className={'paddingTop16'}>
        <LabelTextBox
          label={getLocalized('default-file-name')}
          value={inputValue[FileNameEnum.NAME] || ''}
          placeholder={getLocalized(hint)}
          onChange={(value) => {
            setError(
              FileNameEnum.NAME,
              getError(PoliciesErrors.errorsFileName, value)
            )
            setError(
              FileNameEnum.EMPTY,
              getEmptyError(FileNameEnum.NAME, value)
            )
            setInputValue((prevValue) => {
              return { ...prevValue, [FileNameEnum.NAME]: value }
            })
          }}
          id={'textBox'}
          helperText={getLocalized(
            errors[FileNameEnum.NAME]?.message || hint,
            errors[FileNameEnum.NAME]?.params
          )}
          error={!!errors[FileNameEnum.NAME]}
        />

        <div className={'paddingTop16 devSettingsIndent1'}>
          <Checkbox
            id={'user-editable'}
            label={getLocalized('user-editable')}
            onChange={({ target }) =>
              setInputValue((prevValue) => {
                return {
                  ...prevValue,
                  [FileNameEnum.EDITABLE]: target.checked + ''
                }
              })
            }
            checked={isTrueString(inputValue[FileNameEnum.EDITABLE])}
          />
        </div>
      </div>

      {fileNameComponent(FileNameEnum.SUFFIX, suffixValue)}

      {errors[FileNameEnum.EMPTY] && (
        <WarningMessage
          type={MessageTypesEnum.ERROR}
          message={getLocalized(errors[FileNameEnum.EMPTY].message)}
        />
      )}
    </Modal>
  )
}

export default FileNameModal

import { Attribute, DeviceSettings } from 'common/model/api/Policy'
import {
  PolicyAttributeEnum,
  PolicyCategoryEnum
} from 'context/policies/PoliciesCategories'
import { Condition } from 'context/policies/PoliciesErrors'
import PolicyConstraints from 'context/constraints/Constraints'

export enum PolicyItemPrimitives {
  Password = 'password',
  PasswordView = 'password-view',
  Textbox = 'textbox',
  Toggle = 'toggle',
  ToggleView = 'toggle-view',
  Dropbox = 'dropbox',
  PriorityList = 'priority-list',
  MultiSelectCheckbox = 'multi-select-checkbox',
  DelayLowMsgThreshold = 'delay-low-msg-threshold',
  FwUpdateSchedule = 'fwu-schedule',
  FwUpdateVersion = 'fwu-version',
  TrayAdministration = 'tray-administration',
  HomeScreenLanguageAndKeyboard = 'home-screen-language-and-keyboard',
  HomeScreenApplications = 'home-screen-applications',
  HomeScreenCustomization = 'home-screen-customization',
  Switch = 'switch',
  OutgoingServers = 'outgoing-servers',
  RestrictColor = 'restrict-color',
  CopyStamps = 'copy-stamps',
  SimpleTable = 'simple-table',
  EmailMessage = 'email-message',
  FaxReceive = 'fax-receive',
  FaxSend = 'fax-send',
  FileName = 'file-name',
  RadioTextBox = 'radio-text-box',
  ObjectDropBox = 'object-dropbox',
  AppDeployment = 'app-deployment',
  SioConfig = 'sio-config',
  FileUpload = 'file-upload', // not used
  WallpaperUpload = 'wallpaper-upload',
  WeeklySchedule = 'weekly-schedule',
  HolidaySchedule = 'holiday-schedule',
  QuickSets = 'quick-sets',
  AccessCtrlRoles = 'access-ctrl-roles',
  AccessCtrlPermissions = 'access-ctrl-permissions',
  WebEncryption = 'web-encryption',
  CertificateUpload = 'certificate-upload',
  WiFi_Channel = 'wifi-channel',
  Error = 'error' // non-data type: displays error if enabled
}

export enum ControlWidthEnum {
  NORMAL = 'maxTextWidth', //default
  REDUCED = 'midTextWidth'
}

export type enableCallBackType = (keys: string[], enabled: boolean) => void
export type setValueCallBackType = (deviceValues: DeviceSettings[]) => void

export interface Item {
  readonly value: string
  readonly label?: string
  readonly option?: string
}

export interface DeviceSettingDescription {
  readonly type:
    | PolicyItemPrimitives.Toggle
    | PolicyItemPrimitives.ToggleView
    | PolicyItemPrimitives.Textbox
    | PolicyItemPrimitives.Password
    | PolicyItemPrimitives.PasswordView
    | PolicyItemPrimitives.Dropbox
    | PolicyItemPrimitives.Switch
    | PolicyItemPrimitives.MultiSelectCheckbox
    | PolicyItemPrimitives.DelayLowMsgThreshold
    | PolicyItemPrimitives.PriorityList
    | PolicyItemPrimitives.FwUpdateVersion
    | PolicyItemPrimitives.FwUpdateSchedule
    | PolicyItemPrimitives.TrayAdministration
    | PolicyItemPrimitives.HomeScreenLanguageAndKeyboard
    | PolicyItemPrimitives.HomeScreenApplications
    | PolicyItemPrimitives.HomeScreenCustomization
    | PolicyItemPrimitives.OutgoingServers
    | PolicyItemPrimitives.RestrictColor
    | PolicyItemPrimitives.CopyStamps
    | PolicyItemPrimitives.SimpleTable
    | PolicyItemPrimitives.EmailMessage
    | PolicyItemPrimitives.FaxReceive
    | PolicyItemPrimitives.FaxSend
    | PolicyItemPrimitives.FileName
    | PolicyItemPrimitives.RadioTextBox
    | PolicyItemPrimitives.ObjectDropBox
    | PolicyItemPrimitives.AppDeployment
    | PolicyItemPrimitives.SioConfig
    | PolicyItemPrimitives.FileUpload
    | PolicyItemPrimitives.WallpaperUpload
    | PolicyItemPrimitives.CertificateUpload
    | PolicyItemPrimitives.WeeklySchedule
    | PolicyItemPrimitives.HolidaySchedule
    | PolicyItemPrimitives.QuickSets
    | PolicyItemPrimitives.AccessCtrlRoles
    | PolicyItemPrimitives.AccessCtrlPermissions
    | PolicyItemPrimitives.WebEncryption
    | PolicyItemPrimitives.WiFi_Channel
    | PolicyItemPrimitives.Error
  readonly attribute: string
  readonly indent?: number
}

export interface ErrorDescription extends DeviceSettingDescription {
  readonly type: PolicyItemPrimitives.Error
  readonly error: string
  readonly params?: Record<string, unknown>
}

export interface DeviceSettingHelpDescription extends DeviceSettingDescription {
  readonly type:
    | PolicyItemPrimitives.AppDeployment
    | PolicyItemPrimitives.CopyStamps
    | PolicyItemPrimitives.DelayLowMsgThreshold
    | PolicyItemPrimitives.Dropbox
    | PolicyItemPrimitives.EmailMessage
    | PolicyItemPrimitives.FaxReceive
    | PolicyItemPrimitives.FaxSend
    | PolicyItemPrimitives.FileName
    | PolicyItemPrimitives.FileUpload
    | PolicyItemPrimitives.FwUpdateVersion
    | PolicyItemPrimitives.FwUpdateSchedule
    | PolicyItemPrimitives.HolidaySchedule
    | PolicyItemPrimitives.HomeScreenApplications
    | PolicyItemPrimitives.HomeScreenCustomization
    | PolicyItemPrimitives.HomeScreenLanguageAndKeyboard
    | PolicyItemPrimitives.MultiSelectCheckbox
    | PolicyItemPrimitives.ObjectDropBox
    | PolicyItemPrimitives.OutgoingServers
    | PolicyItemPrimitives.Password
    | PolicyItemPrimitives.PasswordView
    | PolicyItemPrimitives.PriorityList
    | PolicyItemPrimitives.QuickSets
    | PolicyItemPrimitives.AccessCtrlRoles
    | PolicyItemPrimitives.AccessCtrlPermissions
    | PolicyItemPrimitives.RadioTextBox
    | PolicyItemPrimitives.RestrictColor
    | PolicyItemPrimitives.SimpleTable
    | PolicyItemPrimitives.SioConfig
    | PolicyItemPrimitives.Textbox
    | PolicyItemPrimitives.Toggle
    | PolicyItemPrimitives.ToggleView
    | PolicyItemPrimitives.Switch
    | PolicyItemPrimitives.TrayAdministration
    | PolicyItemPrimitives.WallpaperUpload
    | PolicyItemPrimitives.CertificateUpload
    | PolicyItemPrimitives.WeeklySchedule
    | PolicyItemPrimitives.WebEncryption
    | PolicyItemPrimitives.WiFi_Channel
  readonly help?: string
  readonly disabledHelp?: string
}

export interface BooleanSettingDescription {
  update?: (
    value: boolean,
    enableCallBack: enableCallBackType,
    setValueCallBack: setValueCallBackType,
    attributes: Attribute[]
  ) => void
}

export interface StringSettingDescription {
  update?: (
    value: string,
    enableCallBack: enableCallBackType,
    setValueCallBack: setValueCallBackType,
    attributes: Attribute[]
  ) => void
}

export interface ArraySettingDescription {
  update?: (
    value: [],
    enableCallBack: enableCallBackType,
    setValueCallBack: setValueCallBackType,
    attributes: Attribute[]
  ) => void
}

export interface ObjectSettingDescription {
  update?: (
    value: Record<string, string>,
    enableCallBack: enableCallBackType,
    setValueCallBack: setValueCallBackType,
    attributes: Attribute[]
  ) => void
}

export interface LabelSettingDescription {
  readonly label: string
}

export interface LabelOptSettingDescription {
  readonly label?: string
}

export interface HintSettingDescription extends LabelOptSettingDescription {
  readonly helper?: string
  readonly hint: string
}

export interface ErrorsSettingDescription {
  readonly errors?: Condition[]
}

export interface ItemsSettingDescription {
  readonly items: Item[]
}

export interface WidthStyleDescription {
  readonly widthStyle?: ControlWidthEnum
}

export interface ConstraintsDescription {
  readonly constraints?: PolicyConstraints
}

export interface ToggleDescription
  extends DeviceSettingHelpDescription,
    BooleanSettingDescription,
    LabelSettingDescription {
  readonly type: PolicyItemPrimitives.Toggle | PolicyItemPrimitives.ToggleView
  readonly warning?: Condition
}

export interface TextBoxDescription
  extends DeviceSettingHelpDescription,
    StringSettingDescription,
    HintSettingDescription,
    ErrorsSettingDescription,
    WidthStyleDescription {
  readonly type: PolicyItemPrimitives.Textbox
  readonly required?: boolean
  readonly textArea?: boolean
}

export interface PasswordDescription
  extends DeviceSettingHelpDescription,
    StringSettingDescription,
    HintSettingDescription,
    ErrorsSettingDescription {
  readonly type:
    | PolicyItemPrimitives.Password
    | PolicyItemPrimitives.PasswordView
  readonly confirmationHint?: string
  readonly confirmationLabel?: string
  readonly editHint?: string
  readonly editLabel?: string
  readonly required?: boolean
  readonly generate?: () => string
}

export interface DropBoxDescription
  extends DeviceSettingHelpDescription,
    StringSettingDescription,
    LabelOptSettingDescription,
    ItemsSettingDescription {
  readonly type: PolicyItemPrimitives.Dropbox
  readonly warnings?: Condition[]
  readonly radio?: boolean
}

export interface ObjectDropboxDescription
  extends DeviceSettingHelpDescription,
    ObjectSettingDescription,
    LabelSettingDescription,
    ItemsSettingDescription {
  readonly type: PolicyItemPrimitives.ObjectDropBox
}

export interface MultiSelectCheckboxDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription,
    ItemsSettingDescription {
  readonly type: PolicyItemPrimitives.MultiSelectCheckbox
  readonly label?: string
  readonly select: string
  readonly change: string
  readonly description: string
  readonly selected: string
  readonly tableColumn: string
  readonly error: string
}

export interface FwUpdateScheduleDescription
  extends DeviceSettingHelpDescription,
    StringSettingDescription,
    LabelSettingDescription {
  readonly type: PolicyItemPrimitives.FwUpdateSchedule
}

export interface PriorityListControlDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription,
    LabelSettingDescription {
  readonly type: PolicyItemPrimitives.PriorityList
  readonly items: PriorityItem[]
}

export interface TrayAdministrationDescription
  extends DeviceSettingHelpDescription,
    ObjectSettingDescription {
  readonly type: PolicyItemPrimitives.TrayAdministration
  readonly trays: Item[]
  readonly sizes: Item[]
  readonly types: Item[]
}

export interface ObjectAttributeDescription
  extends DeviceSettingHelpDescription,
    ObjectSettingDescription,
    ConstraintsDescription {
  readonly type:
    | PolicyItemPrimitives.DelayLowMsgThreshold
    | PolicyItemPrimitives.EmailMessage
    | PolicyItemPrimitives.FaxReceive
    | PolicyItemPrimitives.FaxSend
    | PolicyItemPrimitives.FileName
    | PolicyItemPrimitives.RadioTextBox
    | PolicyItemPrimitives.SioConfig
    | PolicyItemPrimitives.FileUpload
    | PolicyItemPrimitives.WebEncryption
    | PolicyItemPrimitives.HomeScreenCustomization
}

export interface ArrayAttributeDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription,
    ConstraintsDescription {
  readonly type:
    | PolicyItemPrimitives.OutgoingServers
    | PolicyItemPrimitives.RestrictColor
    | PolicyItemPrimitives.CopyStamps
    | PolicyItemPrimitives.AppDeployment
    | PolicyItemPrimitives.WallpaperUpload
    | PolicyItemPrimitives.CertificateUpload
    | PolicyItemPrimitives.WeeklySchedule
    | PolicyItemPrimitives.HolidaySchedule
    | PolicyItemPrimitives.QuickSets
    | PolicyItemPrimitives.AccessCtrlRoles
}

export interface ArrayItemsAttributeDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription,
    ItemsSettingDescription {
  readonly type: PolicyItemPrimitives.HomeScreenLanguageAndKeyboard
}

export interface FwUpdateVersionDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription {
  readonly model: string
  readonly type: PolicyItemPrimitives.FwUpdateVersion
}

export interface HomeScreenAppsDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription {
  readonly simple: boolean
  readonly type: PolicyItemPrimitives.HomeScreenApplications
}

export interface SwitchDescription extends DeviceSettingDescription {
  readonly type: PolicyItemPrimitives.Switch
  readonly settingsMap: {
    [mode: string]: Array<DeviceSettingDescription>
  }
}

export interface AccessControlPermissionDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription,
    LabelSettingDescription,
    ItemsSettingDescription,
    ConstraintsDescription {
  readonly type: PolicyItemPrimitives.AccessCtrlPermissions
}

export interface RadioTextBoxDescription
  extends ObjectAttributeDescription,
    LabelSettingDescription,
    WidthStyleDescription {
  readonly type: PolicyItemPrimitives.RadioTextBox
  readonly radioKey: string
  readonly textKey: string
  readonly items: ItemEx[]
}

export interface WiFiChannelDescription
  extends DeviceSettingHelpDescription,
    StringSettingDescription {
  readonly type: PolicyItemPrimitives.WiFi_Channel
}

export interface ItemEx extends Item, ErrorsSettingDescription {
  readonly hint: string
  readonly helper?: string
}

export interface PriorityItem extends Item {
  readonly fixed?: boolean
}

export interface SimpleTableDescription
  extends DeviceSettingHelpDescription,
    ErrorsSettingDescription,
    LabelSettingDescription,
    ArraySettingDescription {
  readonly type: PolicyItemPrimitives.SimpleTable
  readonly limit?: number
  readonly emptyAllowed?: boolean
  readonly countLabel: string
  readonly tableColumn: string
  readonly modalDescription?: string
  readonly modalTitle?: string
  readonly modalTextLabel: string
  readonly modalHelperText: string
  readonly duplicateError?: string
  readonly help?: string
}

export interface FileUploadDescription
  extends DeviceSettingHelpDescription,
    ObjectAttributeDescription {
  readonly type: PolicyItemPrimitives.FileUpload
  readonly fileUploadExt?: string
  readonly fileUploadType?: string
}

export interface PolicyAttributeDescription {
  id: PolicyAttributeEnum
  relatedItems?: PolicyAttributeEnum[]
  deviceSettings?: (
    | ToggleDescription
    | TextBoxDescription
    | PasswordDescription
    | DropBoxDescription
    | MultiSelectCheckboxDescription
    | FwUpdateVersionDescription
    | FwUpdateScheduleDescription
    | PriorityListControlDescription
    | TrayAdministrationDescription
    | ArrayItemsAttributeDescription
    | ArrayAttributeDescription
    | ObjectAttributeDescription
    | SimpleTableDescription
    | RadioTextBoxDescription
    | ObjectDropboxDescription
    | FileUploadDescription
    | HomeScreenAppsDescription
    | AccessControlPermissionDescription
    | ErrorDescription
    | SwitchDescription
    | WiFiChannelDescription
  )[]
}

export interface PolicyAttributeDescriptionEx
  extends PolicyAttributeDescription {
  categories: PolicyCategoryEnum[]
}

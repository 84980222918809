import React, { useContext, useEffect, useMemo, useState, memo } from 'react'
import FileName, {
  getFileNamePreview
} from 'components/policies/modal/FileName'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import {
  ObjectAttributeDescription,
  DropBoxDescription
} from 'context/policies/PoliciesConfigurationEnum'
import settingsContext from 'context/settings/settingsContext'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import FileSettingItems from 'context/policies/dropboxItems/FileSettingItems'

const FileNameControl = (props) => {
  const description: ObjectAttributeDescription = props.description
  const {
    data: { deviceSettings },
    onAttributeChange,
    id
  } = props
  const { attribute, constraints } = description
  const { isEnabled, addError, removeError, displayAllErrors } =
    useContext(settingsContext)
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)

  const value = useMemo(
    () => PoliciesHelper.getData(description, deviceSettings),
    [deviceSettings]
  )

  const fileType = useMemo(
    () =>
      PoliciesHelper.getData(
        {
          attribute: attribute.replace('.name', '.type')
        } as DropBoxDescription,
        deviceSettings
      ),
    [deviceSettings]
  )

  const compliance = useMemo(
    () => PoliciesHelper.getCompliance(description, deviceSettings),
    [deviceSettings]
  )

  const onChange = (value) => {
    setDisplayError(true)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, value)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const emptyPreview = useMemo(() => !getFileNamePreview(value).length, [value])

  const enabled = isEnabled(attribute)
  const showError = displayError || displayAllErrors
  useEffect(() => {
    // Improvement point: show the error in case of prefix/suffix syntax errors
    const err = enabled && emptyPreview
    setError(showError && err)
    err ? addError(id, attribute, showError) : removeError(id, attribute)
  }, [enabled, emptyPreview, showError])

  return (
    <FileName
      simple={id === PolicyAttributeEnum.Email_File}
      value={value}
      fileType={fileType}
      error={error}
      compliance={compliance}
      attribute={attribute}
      disabled={!enabled}
      onChange={onAttributeChange ? onChange : undefined}
      options={FileSettingItems.fileNameTokens(constraints, id)}
    />
  )
}

export default memo(FileNameControl)

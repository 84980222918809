import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { PriorityItem } from '../PoliciesConfigurationEnum'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

export default class ConfigurationPrecedence {
  static precedence: PriorityItem[] = [
    {
      value: `${PolicyAttributeEnum.Configuration_Precedence}.order.manual`,
      label: 'manual',
      option: 'manual'
    },
    {
      value: `${PolicyAttributeEnum.Configuration_Precedence}.order.tftp`,
      label: 'tftp',
      option: 'tftp'
    },
    {
      value: `${PolicyAttributeEnum.Configuration_Precedence}.order.dhcpv4`,
      label: 'dhcpv4',
      option: 'dhcpv4'
    },
    {
      value: `${PolicyAttributeEnum.Configuration_Precedence}.order.dhcpv6`,
      label: 'dhcpv6',
      option: 'dhcpv6'
    },
    {
      value: `${PolicyAttributeEnum.Configuration_Precedence}.order.default`,
      label: 'default',
      option: 'default',
      fixed: true
    }
  ]

  static configurationPrecedenceCodes = (
    constraints?: PolicyConstraints
  ): PriorityItem[] =>
    !constraints
      ? ConfigurationPrecedence.precedence
      : constraints.filterSettingOptions(
          ConfigurationPrecedence.precedence,
          ConstraintsResourceEnum.adapterConfigs,
          ConstraintsSettingEnum.configPrecedenceMethod
        )
}

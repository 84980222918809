import React, { useMemo } from 'react'
import { Card, Checkbox, Scrollbar } from '@veneer/core'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import EmailAddressItems from 'context/policies/dropboxItems/EmailAddressItems'
import LabelSelect from 'common/controls/labelSelect'
import LabelTextBox from 'common/controls/labelTextBox'
import { QuickSetsMessageEnum } from './QuickSetsEnums'
import { EmailMessageEnum } from '../EmailMessage/EmailMessageEnums'
import { getError, isTrueString } from 'common/utilities'
import PoliciesErrors from 'context/policies/PoliciesErrors'
import { errorKeys } from './QuickSetsModal'
import { FlexColumn, cssFullHeight } from 'styles/styles'

export const isFieldEnabled = (value, id) => {
  switch (id) {
    case EmailMessageEnum.DEF_FROM:
    case EmailMessageEnum.DEF_NAME:
      return (
        value[EmailMessageEnum.FROM] === EmailAddressItems.fromItems[0].value
      )
    case EmailMessageEnum.TO + QuickSetsMessageEnum.ADDRESSES:
      return value[EmailMessageEnum.TO] === EmailAddressItems.toItemsQs[2].value
    case EmailMessageEnum.CC + QuickSetsMessageEnum.ADDRESSES:
      return value[EmailMessageEnum.CC] === EmailAddressItems.toItemsQs[2].value
    case EmailMessageEnum.BCC + QuickSetsMessageEnum.ADDRESSES:
      return (
        value[EmailMessageEnum.BCC] === EmailAddressItems.toItemsQs[2].value
      )
    default:
      return true
  }
}

// All other settings are message.xxx
const plainSettings = [
  QuickSetsMessageEnum.RESTRICTIONS,
  QuickSetsMessageEnum.SIGN,
  QuickSetsMessageEnum.SIGN + EmailMessageEnum.EDITABLE,
  QuickSetsMessageEnum.ENCRYPT,
  QuickSetsMessageEnum.ENCRYPT + EmailMessageEnum.EDITABLE
]

export const checkConstraints = (constraints, x) =>
  constraints
    ? !!constraints.isPolicyAttributeSettingSupported(
        PolicyAttributeEnum.Email_Message,
        PolicyAttributeEnum.Email_Message +
          (plainSettings.includes(x) ? '.' : EmailMessageEnum.OBJECT) +
          x
      )
    : true

const AddQuickSetStep2 = (props) => {
  const {
    getLocalized,
    value,
    instantStart,
    constraints,
    onChange,
    requiredErrors,
    setRequiredErrors
  } = props

  const getLocalizedEx = (key) =>
    getLocalized(
      `policy.settings.attributes.email-message.device_settings.${key}`
    )

  const inputFieldsData = useMemo(() => {
    const fromItems = EmailAddressItems.fromItems.map((item) => ({
      ...item,
      label: getLocalized(item.label)
    }))

    const toCcBccItems = EmailAddressItems.toItemsQs.map((item) => ({
      ...item,
      label: getLocalized(item.label)
    }))

    const restrictionsItems = EmailAddressItems.restrictionItems.map(
      (item) => ({
        ...item,
        label: getLocalized(item.label)
      })
    )

    return {
      [QuickSetsMessageEnum.RESTRICTIONS]: { options: restrictionsItems },
      [EmailMessageEnum.FROM]: { options: fromItems },
      [EmailMessageEnum.DEF_FROM]: {
        placeholder: 'def-from_hint',
        errors: PoliciesErrors.errorsInvalidEmail
      },
      [EmailMessageEnum.DEF_NAME]: {
        placeholder: 'def-name_hint',
        errors: PoliciesErrors.errorsDefFromName(constraints)
      },
      [EmailMessageEnum.TO]: {
        options: toCcBccItems,
        placeholder: 'email_hint'
      },
      [EmailMessageEnum.CC]: {
        options: toCcBccItems,
        placeholder: 'email_hint'
      },
      [EmailMessageEnum.BCC]: {
        options: toCcBccItems,
        placeholder: 'email_hint'
      },
      [EmailMessageEnum.SUBJECT]: {
        placeholder: 'subject_hint',
        errors: PoliciesErrors.errorsSubject(constraints)
      },
      [EmailMessageEnum.MESSAGE]: {
        placeholder: 'message_hint',
        errors: PoliciesErrors.errorsMessage(constraints)
      }
    }
  }, [constraints])

  const handleAllAddressesBlankError = (id, val, err) => {
    const addrIds = [
      EmailMessageEnum.TO,
      EmailMessageEnum.CC,
      EmailMessageEnum.BCC
    ]
    if (instantStart && addrIds.includes(id)) {
      const isBlank = (x) =>
        (x === id ? val : value[x]) === 'message-recipient.blank'
      if (addrIds.every((x) => isBlank(x))) {
        err[EmailMessageEnum.TO] = { message: errorKeys[2] }
      } else if (
        requiredErrors[EmailMessageEnum.TO]?.message === errorKeys[2]
      ) {
        delete err[EmailMessageEnum.TO]
      }
    }
  }

  const generateSelect = (id: string) => {
    const error = requiredErrors[id]
    return (
      <LabelSelect
        label={getLocalizedEx(id)}
        id={id}
        className={'maxTextWidth'}
        options={inputFieldsData[id].options}
        placeholder={getLocalized('common.select-option')}
        value={[value[id]]}
        error={!!error}
        helperText={error?.message ? getLocalized(error?.message) : null}
        onChange={(option) => {
          setRequiredErrors((prevValue) => {
            const newValue = { ...prevValue }
            if (!option.value) {
              newValue[id] = { message: errorKeys[0] }
            } else {
              delete newValue[id]
            }

            // Additionaly handle All addresses blank error
            handleAllAddressesBlankError(id, option.value, newValue)

            return newValue
          })
          onChange({ [id]: option.value })
        }}
      />
    )
  }

  const generateCheckbox = (id: string, indent = true) => {
    const checkId = indent ? id + EmailMessageEnum.EDITABLE : id
    return (
      <div className={`paddingTop16${indent ? ' devSettingsIndent1' : ''}`}>
        <Checkbox
          id={checkId}
          label={getLocalizedEx(indent ? 'user-editable' : id)}
          key={checkId}
          checked={isTrueString(value[checkId])}
          onChange={(check) => {
            const { checked, id } = check.target
            onChange({ [id]: checked + '' })
          }}
        />
      </div>
    )
  }

  const generateTextbox = (
    id: string,
    textArea = false,
    disabled = false,
    required = false
  ) => {
    const placeholder = inputFieldsData[id].placeholder
    const policyError = inputFieldsData[id].errors
    const error = requiredErrors[id]
    return (
      <div className={'paddingTop16'}>
        <LabelTextBox
          label={getLocalizedEx(id)}
          required={required}
          className={'maxTextWidth'}
          id={id}
          type={'text'}
          disabled={disabled}
          value={value[id] || ''}
          placeholder={getLocalizedEx(placeholder)}
          helperText={
            error?.message
              ? getLocalized(error.message, 0, error.params)
              : getLocalizedEx(placeholder)
          }
          onChange={(val) => {
            setRequiredErrors((prevValue) => {
              const newValue = { ...prevValue }
              const newError = getError(policyError, val)
              if (newError) {
                newValue[id] = newError
              } else {
                delete newValue[id]
              }
              return newValue
            })
            onChange({ [id]: val })
          }}
          error={!!error}
          textArea={textArea}
        />
      </div>
    )
  }

  const generateEmailAddressesTextbox = (id: string, disabled = false) => {
    const placeholder = inputFieldsData[id].placeholder
    const textBoxId = id + QuickSetsMessageEnum.ADDRESSES
    const error = requiredErrors[textBoxId]

    const handleEmailAddressesChange = (val) => {
      const newError = getError(PoliciesErrors.errorsInvalidEmailList, val)
      setRequiredErrors((prevValue) => {
        const newValue = { ...prevValue }
        if (newError) {
          newValue[textBoxId] = newError
        } else {
          delete newValue[textBoxId]
        }
        return newValue
      })
      onChange({ [textBoxId]: newError ? [val] : val.split(',') })
    }

    return (
      <div className={'paddingTop16 devSettingsIndent1'}>
        <LabelTextBox
          required={true}
          className={'maxTextWidth'}
          id={textBoxId}
          type={'text'}
          disabled={disabled}
          value={value[textBoxId]?.toString() || ''}
          placeholder={getLocalizedEx(placeholder)}
          helperText={
            error?.message
              ? getLocalized(error.message, 0, error.params)
              : getLocalizedEx(placeholder)
          }
          onChange={(val) => handleEmailAddressesChange(val)}
          error={!!error}
          textArea={true}
        />
      </div>
    )
  }

  const generateSelectCheckText = (
    id: string,
    textBoxDisabled: boolean,
    hasCheck = true
  ) => (
    <FlexColumn className={'paddingTop16'}>
      {generateSelect(id)}
      {hasCheck && generateCheckbox(id)}
      {generateEmailAddressesTextbox(id, textBoxDisabled)}
    </FlexColumn>
  )

  const generateSelectCheck = (id: string, hasCheck: boolean) => (
    <FlexColumn className={'paddingTop16'}>
      {generateSelect(id)}
      {hasCheck && generateCheckbox(id)}
    </FlexColumn>
  )

  const generateTextCheck = (
    id: string,
    texArea: boolean,
    hasCheck: boolean
  ) => (
    <FlexColumn>
      {generateTextbox(id, texArea)}
      {hasCheck && generateCheckbox(id)}
    </FlexColumn>
  )

  const generateCheckboxGroup = (id: string) =>
    checkConstraints(constraints, id) && (
      <FlexColumn>
        {generateCheckbox(id, false)}
        {checkConstraints(constraints, id + EmailMessageEnum.EDITABLE) &&
          generateCheckbox(id)}
      </FlexColumn>
    )

  const generateCardContent = () => {
    const disabled = (id) => !isFieldEnabled(value, id)
    return (
      <Scrollbar customStyle={cssFullHeight}>
        <FlexColumn className={'padding1224'}>
          {checkConstraints(constraints, QuickSetsMessageEnum.RESTRICTIONS) &&
            generateSelect(QuickSetsMessageEnum.RESTRICTIONS)}
          {generateSelectCheck(
            EmailMessageEnum.FROM,
            checkConstraints(
              constraints,
              EmailMessageEnum.FROM + EmailMessageEnum.EDITABLE
            )
          )}
          <FlexColumn className={'devSettingsIndent1'}>
            {generateTextbox(
              EmailMessageEnum.DEF_FROM,
              false,
              disabled(EmailMessageEnum.DEF_FROM),
              true
            )}
            {generateTextbox(
              EmailMessageEnum.DEF_NAME,
              false,
              disabled(EmailMessageEnum.DEF_NAME),
              true
            )}
          </FlexColumn>
          {generateSelectCheckText(
            EmailMessageEnum.TO,
            disabled(EmailMessageEnum.TO + QuickSetsMessageEnum.ADDRESSES)
          )}
          {checkConstraints(constraints, EmailMessageEnum.CC) &&
            generateSelectCheckText(
              EmailMessageEnum.CC,
              disabled(EmailMessageEnum.CC + QuickSetsMessageEnum.ADDRESSES),
              checkConstraints(
                constraints,
                EmailMessageEnum.CC + EmailMessageEnum.EDITABLE
              )
            )}
          {checkConstraints(constraints, EmailMessageEnum.BCC) &&
            generateSelectCheckText(
              EmailMessageEnum.BCC,
              disabled(EmailMessageEnum.BCC + QuickSetsMessageEnum.ADDRESSES),
              checkConstraints(
                constraints,
                EmailMessageEnum.BCC + EmailMessageEnum.EDITABLE
              )
            )}
          {generateTextCheck(
            EmailMessageEnum.SUBJECT,
            false,
            checkConstraints(
              constraints,
              EmailMessageEnum.SUBJECT + EmailMessageEnum.EDITABLE
            )
          )}
          {generateTextCheck(
            EmailMessageEnum.MESSAGE,
            true,
            checkConstraints(
              constraints,
              EmailMessageEnum.MESSAGE + EmailMessageEnum.EDITABLE
            )
          )}
          {generateCheckboxGroup(QuickSetsMessageEnum.SIGN)}
          {generateCheckboxGroup(QuickSetsMessageEnum.ENCRYPT)}
        </FlexColumn>
      </Scrollbar>
    )
  }

  return (
    <>
      <h5 className={'step-heading'}>{getLocalized('header', 2)}</h5>
      <p className={'contentPadding'}>{getLocalized('step-description', 2)}</p>
      <div className={'quick-set-card-height'}>
        <Card
          id={'add-quick-set-step-2'}
          content={generateCardContent()}
          border={'dropShadow'}
          customStyle={{ height: '100%', padding: '12px 0 12px 0' }}
        />
      </div>
    </>
  )
}

export default AddQuickSetStep2

import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from '../PoliciesConfigurationEnum'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

const duplexItem = (label, option): Item => ({
  value: `${PolicyAttributeEnum.Duplex_Binding}.mode.${label}`,
  label,
  option
})

export default class DuplexBindingItems {
  static modes: Item[] = [
    duplexItem('none', 'oneSided'),
    duplexItem('long-edge', 'twoSidedLongEdge'),
    duplexItem('short-edge', 'twoSidedShortEdge')
  ]

  static duplexModes = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? DuplexBindingItems.modes
      : constraints.filterSettingOptions(
          DuplexBindingItems.modes,
          ConstraintsResourceEnum.jobTicketCfgDefPrint,
          ConstraintsSettingEnum.destPrintDuplexBinding
        )
}

import React, { useMemo } from 'react'
import { Button, ButtonGroup, Modal } from '@veneer/core'
import { AppDeploymentEnum } from './SelectAppModal'
import { SettingCompliance } from 'common/model/api/Compliance'
import { jsonParse } from 'common/utilities'
import 'components/policies/modal/index.scss'
import { FlexRow } from 'styles/styles'

export enum AppDeploymentStateEnum {
  INSTALLED = 'installed',
  INSTALLING = 'installing',
  NOT_INSTALLED = 'notInstalled',
  PRE_INSTALLED = 'preInstalled'
}

enum AdsFieldEnum {
  APP_UUID = 'appUuid',
  APP_VERSION = 'appVersion',
  // APP_TITLE = 'appTitle',
  // APP_STATE = 'appState',
  DEPLOY_STATE = 'deployState',
  DEPLOY_SUB_STATE = 'deploySubState',
  DEPLOY_STATE_DESC = 'deployStateDesc',
  SERIAL_NUMBER = 'serialNumber' // MFE internal
  // DEPLOY_STATE_UPDATED_AT = 'deployStateUpdatedAt'
}

enum AdsStateEnum {
  READY = 'READY',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED'
}

enum AdsSubStateEnum {
  QUEUED = 'Queued',
  VERSION_FINDING = 'VersionFinding',
  LICENSE_SIGNING = 'LicenseSigning',
  LICENSE_INSTALLING = 'LicenseInstalling',
  APP_INSTALLING = 'AppInstalling',
  APP_UNINSTALLING = 'AppUninstalling',
  COMPLETED = 'Completed',
  VERSION_FIND_FAILED = 'VersionFindFailed',
  LICENSE_SIGN_FAILED = 'LicenseSignFailed',
  LICENSE_INSTALL_FAILED = 'LicenseInstallFailed',
  APP_INSTALL_FAILED = 'AppInstallFailed',
  APP_UNINSTALL_FAILED = 'AppUninstallFailed',
  CANCELLED = 'Cancelled',
  ABORTED = 'Aborted'
}

// DEMO (HP for Box)
const myReason = {
  appUuid: 'ef9beb16-aceb-4b55-924b-45a5583748c4',
  appVersion: '1.01.01',
  deployState: 'READY',
  deploySubState: 'Queued',
  deployStateDesc:
    'The device is currently offline. The app installation will be skipped until the device is back online.'
}

export const mergeAppDeploymentStatus = (
  setting: SettingCompliance,
  serialNumber: string,
  remediation: boolean
) => {
  // Merge expected and actual apps
  const expectedApps = jsonParse(setting.expected)
  jsonParse(setting.actual).forEach((x) => {
    const app = expectedApps.find(
      (a) =>
        a[AppDeploymentEnum.UUID] === x[AppDeploymentEnum.UUID] &&
        a[AppDeploymentEnum.VERSION] === x[AppDeploymentEnum.VERSION]
    )
    if (app) {
      app[AppDeploymentEnum.STATUS] = AppDeploymentStateEnum.INSTALLED
    } else {
      expectedApps.push({
        ...x,
        [AppDeploymentEnum.STATUS]: AppDeploymentStateEnum.PRE_INSTALLED
      })
    }
  })

  // Helpers
  const isInstalling = (x) =>
    !x ||
    [AdsStateEnum.READY, AdsStateEnum.RUNNING].includes(
      x[AdsFieldEnum.DEPLOY_STATE]
    )
  const thisApp = (app, x) =>
    x[AdsFieldEnum.APP_UUID] === app[AppDeploymentEnum.UUID] &&
    x[AdsFieldEnum.APP_VERSION] === app[AppDeploymentEnum.VERSION]

  // Get app reasons
  const appReasons = jsonParse(setting.reason)
  return JSON.stringify(
    expectedApps.map((x) => {
      let reason = appReasons.find((y) => thisApp(x, y))
      // DEMO - if there is no real reason, use test one for this app only, and only in remediation mode
      if (!reason && remediation && thisApp(x, myReason)) {
        reason = myReason
      }
      if (reason) {
        x[AppDeploymentEnum.STATUS_DETAILS] = {
          ...reason,
          [AdsFieldEnum.SERIAL_NUMBER]: serialNumber
        }
      }
      if (!x.status) {
        // Set status for not installed apps
        x[AppDeploymentEnum.STATUS] =
          remediation && isInstalling(reason)
            ? AppDeploymentStateEnum.INSTALLING
            : AppDeploymentStateEnum.NOT_INSTALLED
      }
      return x
    })
  )
}

const AppDetailsModal = (props) => {
  const { value, onClose, getLocalized } = props
  const data = useMemo(() => {
    const name = value[AppDeploymentEnum.NAME] || ''
    const version = value[AppDeploymentEnum.VERSION] || ''
    const details = value[AppDeploymentEnum.STATUS_DETAILS]
    let title = 'state-unknown'
    let state = ''
    let message = ''
    let serialNumber = ''
    if (details) {
      switch (details[AdsFieldEnum.DEPLOY_STATE]) {
        case AdsStateEnum.READY:
          title = 'state-ready'
          break
        case AdsStateEnum.RUNNING:
          title = 'state-running'
          break
        case AdsStateEnum.FAILED:
          title = 'state-failed'
          break
        case AdsStateEnum.COMPLETED:
          title = 'state-completed'
          break
      }
      switch (details[AdsFieldEnum.DEPLOY_SUB_STATE]) {
        case AdsSubStateEnum.QUEUED: // install & uninstall
          state = 'sub-state-queued'
          break
        case AdsSubStateEnum.VERSION_FINDING: // install
          state = 'sub-state-version-finding'
          break
        case AdsSubStateEnum.LICENSE_SIGNING: // install
          state = 'sub-state-license-signing'
          break
        case AdsSubStateEnum.LICENSE_INSTALLING: // install
          state = 'sub-state-license-installing'
          break
        case AdsSubStateEnum.APP_INSTALLING: // install
          state = 'sub-state-app-installing'
          break
        case AdsSubStateEnum.APP_UNINSTALLING: // uninstall
          state = 'sub-state-app-uninstalling'
          break
        case AdsSubStateEnum.COMPLETED: // install & uninstall
          state = 'sub-state-completed'
          break
        case AdsSubStateEnum.VERSION_FIND_FAILED: // install
          state = 'sub-state-version-find-failed'
          break
        case AdsSubStateEnum.LICENSE_SIGN_FAILED: // install
          state = 'sub-state-license-sign-failed'
          break
        case AdsSubStateEnum.LICENSE_INSTALL_FAILED: // install
          state = 'sub-state-license-install-failed'
          break
        case AdsSubStateEnum.APP_INSTALL_FAILED: // install
          state = 'sub-state-app-install-failed'
          break
        case AdsSubStateEnum.APP_UNINSTALL_FAILED: // uninstall
          state = 'sub-state-app-uninstall-failed'
          break
        case AdsSubStateEnum.CANCELLED: // install & uninstall
          state = 'sub-state-cancelled'
          break
        case AdsSubStateEnum.ABORTED: // install & uninstall
          state = 'sub-state-aborted'
          break
      }
      message = details[AdsFieldEnum.DEPLOY_STATE_DESC]
      serialNumber = details[AdsFieldEnum.SERIAL_NUMBER]
    }
    return { title, name, version, state, message, serialNumber }
  }, [value])

  const row = (left, right) => (
    <FlexRow className={'paddingBottom4'}>
      <div style={{ width: '160px' }}>{getLocalized(left)}</div>
      {right}
    </FlexRow>
  )

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized(data.title)}
      className={'small-policy-modal'}
      data-testid={'id-app-details-modal'}
      footer={
        <ButtonGroup>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.close')}
          </Button>
        </ButtonGroup>
      }
    >
      {row('app-name', data.name)}
      {row('app-version', data.version)}
      {row('serial-number', data.serialNumber)}
      <p className={'marginTop12'}>
        {data.state && getLocalized(data.state)}
        <br />
        {data.message}
      </p>
    </Modal>
  )
}

export default AppDetailsModal

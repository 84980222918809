import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Modal,
  Select,
  Table,
  TableColumns
} from '@veneer/core'
import { SortTypes } from '@veneer/core/dist/scripts/table'
import HomeScreenLanguageItems from 'context/policies/dropboxItems/HomeScreenLanguageItems'
import 'components/policies/modal/index.scss'
import { FlexRowWithSpace, ModalTableHeight } from 'styles/styles'
import { getRowSelectAllState, abcSort } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'

const defSortOrder = 'ascending' as SortTypes
const visibleOptions = 7

const LanguageAndKeyboardLayoutModal = (props) => {
  const { value, items, onClose, onChange, getLocalized } = props

  const [tableData, setTableData] = useState([])
  const [orderType, setOrderType] = useState(defSortOrder)
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [selectedCount, setSelectedCount] = useState(0)
  const [okButtonDisabled, setOkButtonDisabled] = useState(true)

  const uidColumnId = 'uid'
  const languageColumnId = 'label'
  const layoutColumnId = 'layout'

  const buildSelectControlOptions = (items) => {
    return items.map((v) => {
      return {
        value: v.value,
        label: getLocalized(`${v.label}`)
      }
    })
  }
  const buildKeyboardLayoutSelectControl = (item) => {
    const displayError =
      item.rowConfig.selected && !item.layoutValue && item.layoutError
    return (
      <>
        <Select
          id={'kbLayoutSelect_' + `${item.uid}`}
          placeholder={getLocalized('common.select-option')}
          helperText={
            displayError ? getLocalized('common.errors.not-selected') : null
          }
          options={buildSelectControlOptions(
            HomeScreenLanguageItems.keyboardLayouts
          )}
          clearIcon={false}
          error={displayError}
          disabled={!item.rowConfig.selected}
          value={[item.layoutValue || '']}
          visibleOptions={visibleOptions}
          onChange={(v) => {
            const found = tableData.find((row) => row.uid === item.uid)
            if (found) {
              found.layoutValue = v.value
              found.layoutError = true
              setTableData([...tableData])
            }
          }}
        />
      </>
    )
  }
  const buildRowDataForTable = (item) => {
    return {
      ...item,
      layout: buildKeyboardLayoutSelectControl(item)
    }
  }
  useEffect(() => {
    setTableData(
      items.map((item, index) => {
        const found = value.find((x) => x.language === item.value)
        return {
          uid: index,
          label: getLocalized(item.label),
          language: item.value,
          layoutValue: found ? found.layout : null,
          layoutError: false,
          rowConfig: { selected: !!found }
        }
      })
    )
  }, [items, value])

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    const newTableData = tableData.map((row) => {
      const rowCopy = row
      row.rowConfig.selected = checked
      return rowCopy
    })
    setTableData(newTableData)
  }

  const onRowSelect = (event, rowId) => {
    const { checked } = event.target
    const existingRowData = tableData.find((row) => row.uid === rowId)
    if (existingRowData) {
      existingRowData.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  useEffect(() => {
    const selected = tableData.filter((row) => row.rowConfig.selected)
    setSelectedCount(selected.length)
    setRowSelectAllState(
      getRowSelectAllState(selected.length, tableData.length)
    )
    setOkButtonDisabled(
      selected.length === 0 ||
        (value.length == selected.length &&
          tableData.filter((tableDataItem) => {
            return (
              tableDataItem.rowConfig.selected &&
              value.length &&
              value.filter((valueItem) => {
                return (
                  valueItem.language === tableDataItem.language &&
                  valueItem.layout === tableDataItem.layoutValue
                )
              }).length === 0
            )
          }).length === 0)
    )
  }, [tableData])

  const handleOkButtonAction = () => {
    tableData.forEach((tableItem) => {
      tableItem.layoutError = true
    })
    setTableData([...tableData])
    const selected = tableData.filter(({ rowConfig }) => rowConfig.selected)
    if (selected.some((item) => !item.layoutValue)) {
      return
    }
    onChange(
      selected.map((rowData) => {
        return {
          languageValue: rowData.language,
          layoutValue: rowData.layoutValue
        }
      })
    )
    onClose()
  }

  const tableColumns: TableColumns[] = [
    {
      id: uidColumnId,
      label: 'ID',
      index: 'hidden'
    },
    {
      id: languageColumnId,
      label: getLocalized('home-screen-language-table-column-language'),
      sortable: true
    },
    {
      id: layoutColumnId,
      label: getLocalized('home-screen-language-table-column-kb-layout'),
      sortable: false
    }
  ]

  const sortedTableData = useMemo(() => {
    return abcSort(tableData, (x) => x.label, orderType).map((item) =>
      buildRowDataForTable(item)
    )
  }, [tableData, orderType])

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized(
        !value.length ? 'select-kb-layouts' : 'change-kb-layouts'
      )}
      data-testid={'id-kb-layouts-modal'}
      className={'large-policy-modal'}
      footer={
        <FlexRowWithSpace className={'alignCenter fullWidth'}>
          <ButtonGroup>
            <Button
              onClick={handleOkButtonAction}
              id={'okBtn'}
              disabled={okButtonDisabled}
            >
              {getLocalized(!value.length ? 'common.select' : 'common.save')}
            </Button>
            <Button appearance={'secondary'} id={'cancelBtn'} onClick={onClose}>
              {getLocalized('common.cancel')}
            </Button>
          </ButtonGroup>
          <p>
            {getLocalized('common.n-items-selected', { count: selectedCount })}
          </p>
        </FlexRowWithSpace>
      }
    >
      <p className={'paddingBottom16'}>
        {getLocalized('kb-layouts-modal-description')}
      </p>
      <ModalTableHeight>
        <Table
          columns={tableColumns}
          data={sortedTableData}
          onSort={(_, { type }) => setOrderType(type)}
          loading={tableData === null}
          onSelect={onRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelector={'multiSelection'}
          rowSelectAllState={rowSelectAllState}
          preferences={{
            sortBy: { id: languageColumnId, type: orderType },
            width: [
              { columnId: languageColumnId, width: 140 },
              { columnId: layoutColumnId, width: 240 }
            ]
          }}
          className={'paddingThirdCol4'}
          data-testid={'id-kb-layouts-modal-table'}
          i18n={Retrievei18nItems()}
        />
      </ModalTableHeight>
    </Modal>
  )
}
export default LanguageAndKeyboardLayoutModal

import { PolicyAttributeEnum } from './PoliciesCategories'
import Policy from 'common/model/api/Policy'
import { PublicKey, Secret, SecretId } from 'common/model/api/Security'

const withSecrets = {
  /*
  [PolicyAttributeEnum.EWS_Admin_Password]: [
    `${PolicyAttributeEnum.EWS_Admin_Password}.value`
  ],
  [PolicyAttributeEnum.SNMP_V1_V2]: [
    `${PolicyAttributeEnum.SNMP_V1_V2}.read-password`,
    `${PolicyAttributeEnum.SNMP_V1_V2}.read-write-password`
  ],
  [PolicyAttributeEnum.SNMP_V3]: [
    `${PolicyAttributeEnum.SNMP_V3}.user-name`,
    `${PolicyAttributeEnum.SNMP_V3}.auth-passphrase`,
    `${PolicyAttributeEnum.SNMP_V3}.privacy-passphrase`
  ],
  [PolicyAttributeEnum.PJL_Password]: [
    `${PolicyAttributeEnum.PJL_Password}.value`
  ],
  [PolicyAttributeEnum.Remote_Cfg_Password]: [
    `${PolicyAttributeEnum.Remote_Cfg_Password}.value`
  ],
  [PolicyAttributeEnum.Svc_Access_Code]: [
    `${PolicyAttributeEnum.Svc_Access_Code}.value`
  ],
  [PolicyAttributeEnum.Ldap_Setup]: [
    `${PolicyAttributeEnum.Ldap_Setup}.password`
  ],
  [PolicyAttributeEnum.Id_Certificate]: [
    `${PolicyAttributeEnum.Id_Certificate}.password`
  ],
  [PolicyAttributeEnum.Proxy_Server]: [
    `${PolicyAttributeEnum.Proxy_Server}.password`
  ],
  [PolicyAttributeEnum.WiFi_Direct]: [
    `${PolicyAttributeEnum.WiFi_Direct}.password`
  ],
  [PolicyAttributeEnum.Outgoing_Servers]: [
    `${PolicyAttributeEnum.Outgoing_Servers}.servers` // JSON: array of objects with "password" field
  ],
   */
  [PolicyAttributeEnum.EWS_Admin_Password_New]: [
    `${PolicyAttributeEnum.EWS_Admin_Password_New}.value`
  ],
  [PolicyAttributeEnum.Auth_802_1X_Wired]: [
    `${PolicyAttributeEnum.Auth_802_1X_Wired}.password`
  ],
  [PolicyAttributeEnum.Auth_802_1X_Wireless]: [
    `${PolicyAttributeEnum.Auth_802_1X_Wireless}.password`
  ],
  [PolicyAttributeEnum.Bootloader_Password]: [
    `${PolicyAttributeEnum.Bootloader_Password}.current`,
    `${PolicyAttributeEnum.Bootloader_Password}.new`
  ],
  [PolicyAttributeEnum.Group_One_Pin]: [
    `${PolicyAttributeEnum.Group_One_Pin}.value`
  ],
  [PolicyAttributeEnum.Group_Two_Pin]: [
    `${PolicyAttributeEnum.Group_Two_Pin}.value`
  ],
  [PolicyAttributeEnum.Identity_Certificate]: [
    `${PolicyAttributeEnum.Identity_Certificate}.est-password`,
    `${PolicyAttributeEnum.Identity_Certificate}.scep-password`,
    `${PolicyAttributeEnum.Identity_Certificate}.scep-challenge-password`
  ],
  [PolicyAttributeEnum.Sip_Server_Settings]: [
    `${PolicyAttributeEnum.Sip_Server_Settings}.primary-password`,
    `${PolicyAttributeEnum.Sip_Server_Settings}.secondary-password`
  ],
  [PolicyAttributeEnum.Secure_Disk_Password]: [
    `${PolicyAttributeEnum.Secure_Disk_Password}.value`
  ],
  [PolicyAttributeEnum.Ldap_AB_Access]: [
    `${PolicyAttributeEnum.Ldap_AB_Access}.password`
  ]
}

const algorithmsMap = {
  rsaes_oaep_sha_256: {
    name: 'RSA-OAEP',
    hash: 'SHA-256'
  },
  rsaes_oaep_sha_1: {
    name: 'RSA-OAEP',
    hash: 'SHA-1'
  }
}

const SECRET_ID = '~+!_@)#($*%&^'

export default class SecretsProcessor {
  // Extract secrets from policy attributes
  static exractSecrets = (
    policy: Policy,
    substitute = true
  ): Record<string, string> =>
    policy?.attributes
      ? policy.attributes.reduce((acc, attribute) => {
          const settingsWithSecrets = withSecrets[attribute.name]
          return settingsWithSecrets
            ? attribute.deviceSettings.reduce((acc, set) => {
                if (set.value && settingsWithSecrets.includes(set.name)) {
                  if (substitute || set.value !== SECRET_ID) {
                    // extract secrets ids or modified values
                    acc[set.name] = set.value
                  }
                  if (substitute) {
                    // replace secret id with dummy value
                    set.value = SECRET_ID
                  }
                }
                return acc
              }, acc)
            : acc
        }, {})
      : {}

  // Update policy with secrets
  static updateSecrets = (
    policy: Policy,
    secrets: Record<string, string>
  ): Policy => {
    const attributes = policy.attributes.map((attribute) => {
      const settingsWithSecrets = withSecrets[attribute.name]
      if (settingsWithSecrets) {
        const deviceSettings = attribute.deviceSettings.map((set) => {
          if (set.value && settingsWithSecrets.includes(set.name)) {
            const value = secrets[set.name]
            if (value) {
              return { ...set, value }
            }
          }
          return set
        })
        return { ...attribute, deviceSettings }
      }
      return attribute
    })
    return { ...policy, attributes }
  }

  static getSecretsArr = (secrets: Record<string, string>): Secret[] =>
    Object.keys(secrets).reduce((acc, key) => {
      acc.push({ key, value: secrets[key] })
      return acc
    }, [])

  static getSecrets = (secrets: SecretId[]): Record<string, string> =>
    secrets.reduce((acc, secret) => {
      acc[secret.key] = secret.id
      return acc
    }, {})

  static encryptSecrets = async (secrets: Secret[], publicKey: PublicKey) => {
    // convert public key to binary
    const binaryKey = Uint8Array.from(atob(publicKey.key), (c) =>
      c.charCodeAt(0)
    )
    // find algorithm
    const algorithm = Object.keys(algorithmsMap).find((key) =>
      publicKey.algorithms.includes(key)
    )
    if (algorithm) {
      try {
        // import public key
        const importedKey = await window.crypto.subtle.importKey(
          'spki',
          binaryKey.buffer,
          algorithmsMap[algorithm],
          true,
          ['encrypt']
        )

        // encrypt and encode text
        const name = algorithmsMap[algorithm].name
        const encryptText = async (text) =>
          window.crypto.subtle
            .encrypt({ name }, importedKey, new TextEncoder().encode(text))
            .then((encrypted) =>
              btoa(String.fromCharCode(...new Uint8Array(encrypted)))
            )

        // encrypt all secrets
        const encryptedSecrets = await Promise.all(
          secrets.map(async ({ key, value }) => ({
            key,
            value: await encryptText(value)
          }))
        )
        return { secrets: encryptedSecrets, algorithm }
      } catch {
        // ignore
      }
    }
    return null
  }
}

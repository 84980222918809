import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'
import { Item } from '../PoliciesConfigurationEnum'
import { PolicyAttributeEnum } from '../PoliciesCategories'

const fileNameToken = (value, label) => {
  return {
    value,
    label: 'policy.settings.file-name-tokens.' + label,
    option: value
  }
}

const fileColor = (x, opt) => {
  return {
    value: 'file-color-preference.' + x,
    label: 'policy.settings.file-color-preferences.' + x,
    option: opt
  }
}

const fileQuality = (x) => {
  return {
    value: 'file-quality.' + x,
    label: 'policy.settings.file-qualities.' + x
  }
}

const fileType = (x, e?, opt?) => {
  return {
    value: 'file-type.' + x,
    label: 'policy.settings.file-types.' + x,
    extension: '.' + (e || x),
    option: opt || x
  }
}

const fileResolution = (x, opt) => {
  return {
    value: x,
    label: `policy.settings.resolutions.${x}-dpi`,
    option: opt
  }
}

const fileCompression = (x, suffix = '', opt?) => {
  return {
    value: `file-compression${suffix}.${x}`,
    label: `policy.settings.file-compressions${suffix}.${x}`,
    option: opt || x
  }
}

const scanPlex = (x, opt) => ({
  value: `scan-plex.${x}`,
  label: `policy.settings.scan-plexes.${x}`,
  option: opt
})

const blackSuffix = '-black'
const colorSuffix = '-color'

const filterItems = (
  items: Item[],
  setting: ConstraintsSettingEnum,
  constraints?: PolicyConstraints,
  id = PolicyAttributeEnum.Email_File
): Item[] => {
  return !constraints
    ? items
    : constraints.filterSettingOptions(
        items,
        id === PolicyAttributeEnum.Network_Folder_File
          ? ConstraintsResourceEnum.jobTicketCfgDefNetworkFolder
          : ConstraintsResourceEnum.jobTicketCfgDefEmail,
        setting
      )
}

export default class FileSettingItems {
  static fileNameTokenItems: Item[] = [
    fileNameToken('', 'none'),
    fileNameToken('%DEVICE_DATE_DDMMYYYY%', 'date-ddmmyyyy'),
    fileNameToken('%DEVICE_DATE_MMDDYYYY%', 'date-mmddyyyy'),
    fileNameToken('%DEVICE_DATE_YYMMDD%', 'date-yymmdd'),
    fileNameToken('%DEVICE_DATE_YYYYMMDD%', 'date-yyyymmdd'),
    fileNameToken('%DEVICE_TIME_HHMMSS%', 'time-hhmmss'),
    fileNameToken('%SECURITY_USERNAME%', 'user-name'),
    fileNameToken('%DEVICE_HOSTNAME%', 'host-name'),
    fileNameToken('%DEVICE_HOSTNAME_PQDN%', 'host-name-pqdn')
  ]

  static fileColorPrefItems: Item[] = [
    fileColor('color-black-auto', 'autoDetect'),
    fileColor('color-gray-auto', 'autoDetect'),
    fileColor('black', 'monochrome'),
    fileColor('black-gray', 'grayscale'),
    fileColor('color', 'color')
  ]

  static fileQualityItems: Item[] = [
    fileQuality('high'),
    fileQuality('low'),
    fileQuality('medium')
  ]

  static fileTypeItems: Item[] = [
    fileType('csv'),
    fileType('html', 'zip'),
    fileType('jpeg', 'jpg'),
    fileType('m-tiff', 'tif', 'mtiff'),
    fileType('pdf'),
    fileType('pdfa', 'pdf'),
    fileType('rtf'),
    fileType('s-pdf', 'pdf', 'spdf'),
    fileType('s-pdfa', 'pdf', 'spdfa'),
    fileType('text', 'txt'),
    fileType('tiff', 'tif'),
    fileType('u-text', 'txt', 'utext'),
    fileType('xps')
  ]
  static isFileTypeTiff = (type) =>
    ['file-type.tiff', 'file-type.m-tiff'].includes(type)
  static isFileTypePdf = (type) =>
    ['file-type.pdf', 'file-type.s-pdf'].includes(type)
  static isFileTypeCompressed = (type) =>
    FileSettingItems.isFileTypePdf(type) ||
    ['file-type.pdfa', 'file-type.s-pdfa', 'file-type.xps'].includes(type)
  static isResolutionCompressed = (res) => ['200', '300'].includes(res)

  static fileResolutionItems: Item[] = [
    fileResolution('75', 'e75Dpi'),
    fileResolution('100', 'e100Dpi'),
    fileResolution('150', 'e150Dpi'),
    fileResolution('200', 'e200Dpi'),
    fileResolution('240', 'e240Dpi'),
    fileResolution('300', 'e300Dpi'),
    fileResolution('400', 'e400Dpi'),
    fileResolution('500', 'e500Dpi'),
    fileResolution('600', 'e600Dpi')
  ]

  static fileCompressionItems: Item[] = [
    fileCompression('high'),
    fileCompression('normal')
  ]

  static scanPlexItems: Item[] = [
    scanPlex('none', 'oneSided'),
    scanPlex('long-edge', 'twoSidedLongEdge'),
    scanPlex('short-edge', 'twoSidedShortEdge')
  ]

  static fileCompressionBlackItems: Item[] = [
    fileCompression('auto', blackSuffix, 'automatic'),
    fileCompression('g3', blackSuffix),
    fileCompression('g4', blackSuffix),
    fileCompression('lzw', blackSuffix)
  ]

  static fileCompressionColorItems: Item[] = [
    fileCompression('lzw', colorSuffix),
    fileCompression('tiff', colorSuffix, 'postTiff6'),
    fileCompression('tiff-6', colorSuffix, 'tiff6')
  ]

  static fileNameTokens = (
    constraints?: PolicyConstraints,
    id = PolicyAttributeEnum.Email_File
  ): Item[] =>
    filterItems(
      FileSettingItems.fileNameTokenItems,
      ConstraintsSettingEnum.sendFileSuffix,
      constraints,
      id
    )

  static fileTypes = (
    constraints?: PolicyConstraints,
    id = PolicyAttributeEnum.Email_File
  ): Item[] =>
    filterItems(
      FileSettingItems.fileTypeItems,
      ConstraintsSettingEnum.sendFileFileType,
      constraints,
      id
    )

  static fileColorPrefs = (
    constraints?: PolicyConstraints,
    id = PolicyAttributeEnum.Email_File
  ): Item[] =>
    filterItems(
      FileSettingItems.fileColorPrefItems,
      ConstraintsSettingEnum.srcScanColorMode,
      constraints,
      id
    )

  static scanPlexs = (constraints?: PolicyConstraints): Item[] =>
    filterItems(
      FileSettingItems.scanPlexItems,
      ConstraintsSettingEnum.srcPageBinding,
      constraints
    )

  static fileResolutions = (constraints?: PolicyConstraints): Item[] =>
    filterItems(
      FileSettingItems.fileResolutionItems,
      ConstraintsSettingEnum.srcScanResolution,
      constraints
    )

  static fileCompressionBlacks = (constraints?: PolicyConstraints): Item[] =>
    filterItems(
      FileSettingItems.fileCompressionBlackItems,
      ConstraintsSettingEnum.sendFileCompressionBlack,
      constraints
    )

  static fileCompressionColors = (constraints?: PolicyConstraints): Item[] =>
    filterItems(
      FileSettingItems.fileCompressionColorItems,
      ConstraintsSettingEnum.sendFileCompressionColor,
      constraints
    )
}

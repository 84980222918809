import MocDoc from './MocDoc'

// Non-IE specific implementation (Chrome/FireFox/Edge)

class MocDocAll extends MocDoc {
  constructor(xml) {
    super()
    const domParser = new DOMParser()
    this.xmlDoc = domParser.parseFromString(xml, 'text/xml')
  }

  //Single node by XPath
  selectSingleNode(exp) {
    const res = this.xmlDoc.evaluate(
      exp,
      this.xmlDoc,
      null,
      XPathResult.ANY_TYPE,
      null
    )
    if (!res) {
      return null
    } else if (res.resultType === XPathResult.BOOLEAN_TYPE) {
      return res.booleanValue
    } else if (res.resultType === XPathResult.NUMBER_TYPE) {
      return res.numberValue
    }
    return res.iterateNext() // single
  }

  //Boolean XPath
  evaluateBool(exp) {
    try {
      if (MocDoc.hasMatches(exp)) {
        //use own parser only in case of "matches"
        return this.parseBool(exp)
      } else {
        //rely on standard function fully
        const res = this.xmlDoc.evaluate(
          exp,
          this.xmlDoc,
          null,
          XPathResult.BOOLEAN_TYPE,
          null
        )
        return res ? res.booleanValue : false
      }
    } catch (err) {
      console.error('MocDocAll:evaluateBool() - ' + err)
      return null
    }
  }

  //Multiple nodes by XPath
  evaluate(exp) {
    try {
      let node,
        nodes = []
      const res = this.xmlDoc.evaluate(
        exp,
        this.xmlDoc,
        null,
        XPathResult.ANY_TYPE,
        null
      )
      while ((node = res.iterateNext()) != null) {
        nodes.push(node)
      }
      return nodes
    } catch (err) {
      console.error('MocDocAll:evaluate() - ' + err)
      return null
    }
  }

  /*
  //Iterates to find XML parsing error
  findParserError(node, level = 0) {
    let res = null
    if (node.nodeName === 'parsererror') {
      if (typeof node.innerText !== 'undefined') {
        return node.innerText
      }
      for (
        let i = 0;
        i < node.childNodes.length &&
        (res = node.childNodes[i].nodeValue) === null;
        i++
      ) {}
      return res ? res : 'XML parser error'
    }
    if (level < 3) {
      for (
        let i = 0;
        i < node.childNodes.length &&
        (res = this.findParserError(node.childNodes[i], level + 1)) === null;
        i++
      ) {}
    }
    return res
  }

  //Detect XML parsing error
  getParserError() {
    return this.findParserError(this.xmlDoc)
  }

  //Return child nodes
  getChildren(node) {
    const nodes = []
    node.childNodes.forEach((x) => {
      if (x.nodeType === 1 && x.nodeName !== 'parsererror') {
        nodes.push(x)
      }
    })
    return nodes
  }

  //Return current XML
  getXml() {
    return this.xmlDoc.documentElement.outerHTML
  }

  //Return node attributes
  getAttributeNames(node) {
    return node.getAttributeNames()
  }
 */
}

export default MocDocAll

import React, { useContext, memo } from 'react'
import configContext from 'context/config/configContext'
import settingsContext from 'context/settings/settingsContext'
import { TextBox } from '@veneer/core'
import { FlexRow, TextBlack } from 'styles/styles'
import { PasswordDescription } from 'context/policies/PoliciesConfigurationEnum'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import HelpButton from 'components/policies/settings/attributes/HelpButton'

const PasswordViewControl = (props) => {
  const description: PasswordDescription = props.description
  const { attribute, label, help } = description

  const { t } = useContext(configContext)
  const { isEnabled } = useContext(settingsContext)

  const getLocalized = (key: string): string => {
    return t(`${props.localizationPath}.${key}`)
  }

  const enabled = isEnabled(attribute)

  return (
    <>
      {label && (
        <FlexRow className={'alignCenter marginBottom4'}>
          <TextBlack disabled={!enabled}>{getLocalized(label)}</TextBlack>
          <FlexRow className={'paddingLeft4 lineBreak'}>
            {help && (
              <HelpButton enabled={enabled} description={getLocalized(help)} />
            )}
          </FlexRow>
        </FlexRow>
      )}
      <FlexRow>
        <TextBox
          id={attribute}
          placeholder={getLocalized(description.hint)}
          type={'text'}
          value={enabled ? PoliciesHelper.getPassword() : ''}
          disabled={true}
          className={'maxTextWidth'}
        />
      </FlexRow>
    </>
  )
}

export default memo(PasswordViewControl)

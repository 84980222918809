import React, { useState, useMemo, useEffect } from 'react'
import { Button, ButtonGroup, Table, Modal } from '@veneer/core'
import { TextBlack, ModalTableHeight } from 'styles/styles'
import WarningMessage from '../WarningMessage'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'
import WebEncryptionItems from 'context/policies/dropboxItems/WebEncryptionItems'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import { getRowSelectAllState, TABLE_CONSTANTS } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'

export enum WebEncryptionEnum {
  CIPHERS = 'ciphers',
  TLS_MIN = 'tls-min',
  TLS_MAX = 'tls-max'
}

export enum CipherColumnsEnum {
  NAME = 'name',
  VERSIONS = 'version',
  ALGORITHM = 'algorithm'
}

const WebEncryptionModal = (props) => {
  const {
    edit,
    value,
    constraints,
    getErrors,
    getLocalized,
    onClose,
    onChange,
    tableData: { tablePreferences, tableColumns }
  } = props
  const [newValue, setNewValue] = useState(value)
  const [displayErrors, setDisplayErrors] = useState(false)
  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)

  const saveDisabled = useMemo(
    () => JSON.stringify(newValue) === JSON.stringify(value),
    [newValue, value]
  )

  const errors = useMemo(() => getErrors(newValue), [newValue])

  const tableItem = (x) => {
    const versions = x.versions.map((x) => `TLS 1.${x}`).join(', ')
    const selected = value[WebEncryptionEnum.CIPHERS].includes(x.value)
    return {
      [TABLE_CONSTANTS.ID]: x.value,
      [CipherColumnsEnum.NAME]: getLocalized(x.label),
      [CipherColumnsEnum.VERSIONS]: versions,
      [CipherColumnsEnum.ALGORITHM]: getLocalized('algorithm-' + x.type),
      rowConfig: { selected }
    }
  }

  useEffect(() => {
    setTableData(
      WebEncryptionItems.webEncryptionCiphers(constraints).map((x) =>
        tableItem(x)
      )
    )
  }, [constraints, value])

  useEffect(() => {
    const ciphers = tableData
      .filter((x) => x.rowConfig.selected)
      .map((x) => x[TABLE_CONSTANTS.ID])
    setNewValue((prevValue) => ({
      ...prevValue,
      [WebEncryptionEnum.CIPHERS]: ciphers
    }))
    setRowSelectAllState(getRowSelectAllState(ciphers.length, tableData.length))
  }, [tableData])

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find((x) => x[TABLE_CONSTANTS.ID] === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        return row
      })
    )
  }

  const onSave = () => {
    setDisplayErrors(true)
    if (!errors.length) {
      onChange(newValue)
      onClose()
    }
  }

  const tlsVersions = useMemo(
    () => ({
      [WebEncryptionEnum.TLS_MIN]: WebEncryptionItems.tlsMinVersions(
        constraints
      ).map((x) => ({
        value: x.value,
        label: getLocalized(x.label),
        disabled: x.value > newValue[WebEncryptionEnum.TLS_MAX]
      })),
      [WebEncryptionEnum.TLS_MAX]: WebEncryptionItems.tlsMaxVersions(
        constraints
      ).map((x) => ({
        value: x.value,
        label: getLocalized(x.label),
        disabled: x.value < newValue[WebEncryptionEnum.TLS_MIN]
      }))
    }),
    [constraints, newValue]
  )

  const tlsRadio = (id) => (
    <LabelRadioButtons
      label={getLocalized(id)}
      value={newValue[id]}
      id={id}
      options={tlsVersions[id]}
      onChange={(_, v) =>
        setNewValue((prevValue) => ({ ...prevValue, [id]: v }))
      }
      className={'paddingBottom16'}
    />
  )

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized(edit ? 'ciphers-change' : 'ciphers-select')}
      className={'large-policy-modal'}
      data-testid={'id-web-encryption-modal'}
      footer={
        <ButtonGroup>
          <Button onClick={onSave} disabled={saveDisabled}>
            {getLocalized(edit ? 'common.save' : 'common.add')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <TextBlack className={'paddingBottom16'}>
        {getLocalized('ciphers-description')}
      </TextBlack>
      {tlsRadio(WebEncryptionEnum.TLS_MIN)}
      {tlsRadio(WebEncryptionEnum.TLS_MAX)}
      <TextBlack className={'marginBottom12'} required={true}>
        {getLocalized('ciphers')}
      </TextBlack>
      <ModalTableHeight>
        <Table
          columns={tableColumns}
          data={tableData}
          className={'widthColAuto'}
          data-testid={'id-web-encryption-modal-table'}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelector={'multiSelection'}
          rowSelectAllState={rowSelectAllState}
          preferences={tablePreferences}
          i18n={Retrievei18nItems()}
        />
      </ModalTableHeight>

      {displayErrors &&
        errors.map((error) => (
          <WarningMessage
            key={error}
            type={MessageTypesEnum.ERROR}
            message={getLocalized(error)}
          />
        ))}
    </Modal>
  )
}

export default WebEncryptionModal

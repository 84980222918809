import { Item } from '../PoliciesConfigurationEnum'

const strengthItem = (x) => ({
  value: '802-1x-auth.encryption-strength.' + x,
  label: 'strength-' + x
})

const failureItem = (x) => ({
  value: '802-1x-auth.on-failure.' + x,
  label: 'on-failure-' + x
})

export default class Authentication802Items {
  static encryptionStrengths: Item[] = [
    strengthItem('high'),
    strengthItem('medium'),
    strengthItem('low')
  ]
  static failureItems: Item[] = [failureItem('connect'), failureItem('block')]
}

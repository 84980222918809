import React, { useReducer } from 'react'
import { SettingsActions } from 'context/types'
import SettingsContext from './settingsContext'
import SettingsReducer from './SettingsReducer'
import { PolicyAttributeDescription } from 'context/policies/PoliciesConfigurationEnum'

const SettingsProvider = (props) => {
  const initialState = {
    displayAllErrors: false,
    warnings: {},
    errors: {},
    disabled: []
  }

  const [state, dispatch] = useReducer(SettingsReducer, initialState)

  const getWarnings = (description?: PolicyAttributeDescription): number => {
    return !description
      ? Object.keys(state.warnings).reduce(
          (count, x) => count + state.warnings[x].length,
          0
        )
      : state.warnings[description.id]
      ? state.warnings[description.id].length
      : 0
  }

  const getErrors = (description?: PolicyAttributeDescription): boolean => {
    const visibleErrors = (x) => x.filter((x) => x.display).length
    return !description
      ? Object.keys(state.errors).reduce(
          (count, x) => count + visibleErrors(state.errors[x]),
          0
        )
      : state.errors[description.id]
      ? visibleErrors(state.errors[description.id])
      : 0
  }

  const hasErrors = (): boolean => {
    return Object.keys(state.errors).some((x) => state.errors[x].length)
  }

  const isEnabled = (id: string): boolean => {
    return !state.disabled.includes(id)
  }

  const cleanUp = (ids: string[]) => {
    dispatch({ type: SettingsActions.CLEAN_UP, payload: ids })
  }

  const addWarning = (id, attribute) => {
    const payload = { id, attribute }
    dispatch({ type: SettingsActions.ADD_WARNING, payload })
  }

  const removeWarning = (id, attribute) => {
    const payload = { id, attribute }
    dispatch({ type: SettingsActions.REMOVE_WARNING, payload })
  }

  const addError = (id, attribute, display = true) => {
    const payload = { id, attribute, display }
    dispatch({ type: SettingsActions.ADD_ERROR, payload })
  }

  const showErrors = (display) => {
    dispatch({ type: SettingsActions.DISPLAY_ALL_ERRORS, payload: { display } })
  }

  const removeError = (id, attribute) => {
    const payload = { id, attribute }
    dispatch({ type: SettingsActions.REMOVE_ERROR, payload })
  }

  const addDisabled = (payload) => {
    dispatch({ type: SettingsActions.ADD_DISABLED, payload })
  }

  const removeDisabled = (payload) => {
    dispatch({ type: SettingsActions.REMOVE_DISABLED, payload })
  }

  return (
    <SettingsContext.Provider
      value={{
        displayAllErrors: state.displayAllErrors,
        warnings: state.warnings,
        errors: state.errors,
        disabled: state.disabled,
        addWarning,
        removeWarning,
        addError,
        removeError,
        addDisabled,
        removeDisabled,
        getWarnings,
        getErrors,
        hasErrors,
        showErrors,
        isEnabled,
        cleanUp
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  )
}

export default SettingsProvider

//Operators (and value)
const OP = {
  BOOL: 1,
  NOT: 2,
  AND: 3,
  OR: 4,
  MATCH: 5,
  NONE: 6,
  VALUE: 7
}

// Base class for browser-specific implementation
// to provide own parser of boolean XPath expressions.
// Uses "Shunting-yard algorithm" and "Reverse Polish notation" processor.

class MocDoc {
  //Find closing bracket ")"
  static findKet(arg) {
    let count = 0
    for (let i = 0; i < arg.length; i++) {
      if (arg.charAt(i) === '(') {
        count++
      } else if (arg.charAt(i) === ')') {
        if (count === 0) {
          return i
        }
        count--
      }
    }
    return -1
  }

  //Check if there is "matches" function (XPath 2.0 spec) in XPath - cannot rely on standard "evaluate" function in such case
  static hasMatches(exp) {
    return exp.indexOf('[matches(') > 0
  }

  //Pop lower-priority operators from stack to output (part of Shunting-yard algorithm)
  popOps(threshold = OP.NONE, remove = true) {
    while (this.ops.length) {
      if (this.ops[this.ops.length - 1] >= threshold) {
        if (remove) {
          this.ops.pop()
        }
        break
      }
      this.out.push({ op: this.ops.pop() })
    }
  }

  //Recursively parse infix expression to convert it to postfix (take into account the order of operands and brackets)
  //Idea: as XPath could be very complicated but current browsers have a problem with "matches" (all), "boolean" and "not" (IE),
  // so let's handle only these operations and use browser-specific implementation of selectSingleNode for the rest.
  // It could be extended to process more functions/operations if required.
  parseArg(arg) {
    const op1 = [
      { pre: 'boolean(', op: OP.BOOL },
      { pre: 'not(', op: OP.NOT },
      { pre: '(', op: OP.NONE }
    ] //single-operand operators
    const op2 = [
      { pre: 'and', op: OP.AND },
      { pre: 'or', op: OP.OR }
    ] //two-operands operators
    arg = arg.trim()
    for (let op of op1) {
      if (arg.indexOf(op.pre) === 0) {
        arg = arg.substring(op.pre.length)
        let j = MocDoc.findKet(arg)
        //process single-operand operation and/or brackets
        if (j > 0) {
          if (op.op !== OP.NONE) {
            this.ops.push(op.op) //store operator
          }
          this.ops.push(OP.NONE) //store "opening" bracket
          this.parseArg(arg.substring(0, j)) //process 1-st operand recursively
          this.popOps() //handle "closing" bracket

          //At that point there are:
          //OUT: ... 1-st operand (one value or several values with operators in postfix notation)
          //OPS: ... boolean / not / nothing

          //is there any two-operands operator?
          if (++j < arg.length) {
            //arg2 to follow
            arg = arg.substring(j)
            arg = arg.trim()
            op2.forEach((op) => {
              if (arg.indexOf(op.pre) === 0) {
                //process two-operands operation
                this.popOps(op.op, false) //handle higher-priority operators
                this.ops.push(op.op) //store operator
                this.parseArg(arg.substring(op.pre.length)) //process 2-nd operand recursively

                //At that point there are:
                //OUT: ... 1st operand, boolean / not / nothing, 2nd operand
                //OPS: ... and / or

                return
              }
            })
            throw new Error('Unknown logical operator (and/or)')
          }
          //else - there is no second operand
          return
        }
        throw new Error('Non-closed brackets (boolean/not)')
      }
    }

    //is there "matches" function?
    //following syntax is expected: <XPath>[matches(.,<regex>)]
    const matchBra = '[matches(.,',
      matchKet = ')]'
    let j = arg.indexOf(matchBra)
    if (j > 0) {
      let arg1 = arg.substring(0, j)
      arg = arg.substring(j + matchBra.length)
      j = arg.lastIndexOf(matchKet)
      if (j > 2) {
        //process "matches"
        this.parseArg(arg1) //process first operand (XPath) recursively, the result is expected to be a Node or null
        arg = arg.substring(0, j).trim()
        this.out.push({ op: OP.VALUE, val: arg.substring(1, arg.length - 1) }) //write second operand (regex template string)
        this.out.push({ op: OP.MATCH }) //write operator

        //At that point there are:
        //OUT: ... Node/null, regex template string, "matches" operand
        //OPS: ...

        return
      }
      throw new Error('Non-closed brackets (matches)')
    }

    //Assuming XPath
    this.out.push({ op: OP.VALUE, val: this.selectSingleNode(arg) }) //write result: single Node/boolean/number/null

    //At that point there are:
    //OUT: ... single Node/boolean/number/null
    //OPS: ...
  }

  //Own parser of boolean XPath expressions
  parseBool(exp) {
    //Use Shunting-yard algorithm to convert infix (normal) expression to postfix (fixed operations order)
    this.out = [] //output in postfix format
    this.ops = [OP.NONE] //operator stack
    this.parseArg(exp) //parse expression recursively
    this.popOps() //after all - flush remaining operators to output

    //Process postfix expression using stack for values (see Reverse Polish notation)
    let calc = []
    for (const item of this.out) {
      let val
      switch (item.op) {
        case OP.NOT: //pop value from stack and apply NOT operation
          val = !calc.pop()
          break
        case OP.AND: //pop 2 values from stack and apply AND operation
          val = calc.pop()
          val = calc.pop() && val
          break
        case OP.OR: //pop 2 values from stack and apply OR operation
          val = calc.pop()
          val = calc.pop() || val
          break
        case OP.BOOL: //pop value from stack and apply BOOLEAN operation
          val = !!calc.pop()
          break
        case OP.MATCH: {
          //pop 2 values from stack and apply Regular Expression test operation
          const re = new RegExp(calc.pop())
          val = calc.pop()
          if (val && !re.test(val.nodeValue)) {
            val = null
          }
          break
        }
        case OP.VALUE:
          val = item.val //just store value to stack
          break
        default:
          //OP.NONE
          continue
      }
      calc.push(val) //push result to stack
    }
    if (calc.length !== 1) {
      throw new Error('Logical parsing error')
    }
    return calc[0] //after all there should be the only value - result of whole expression
  }
}

export default MocDoc

import React, { useContext, useEffect, useState, useMemo, memo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Button } from '@veneer/core'
import { ArrayItemsAttributeDescription } from 'context/policies/PoliciesConfigurationEnum'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { FlexRow, PreviewValue, TextBlack } from 'styles/styles'
import LanguageAndKeyboardLayoutModal from './LanguageAndKeyboardLayoutModal'
import WarningMessage from '../WarningMessage'
import HomeScreenLanguageItems from 'context/policies/dropboxItems/HomeScreenLanguageItems'
import LanguageItems from 'context/policies/dropboxItems/LanguageItems'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const LanguageAndKeyboardLayoutControl = (props) => {
  const description: ArrayItemsAttributeDescription = props.description

  const { tt } = useContext(configContext)
  const { isEnabled, addError, removeError, displayAllErrors } =
    useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const enabled = isEnabled(description.attribute)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, props.data.deviceSettings)
  )
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const showError = displayError || displayAllErrors

  const summaryLabels = useMemo(() => {
    return value.map((v: { language; layout }) => {
      const language = LanguageItems.languageCodes().find(
        (x) => x.value === v.language
      )
      const layout = HomeScreenLanguageItems.keyboardLayouts.find(
        (x) => x.value === v.layout
      )
      return {
        language: getLocalized(language.label),
        layout: getLocalized(`${layout.label}`)
      }
    })
  }, [value])

  const emptyArray = !value.length
  useEffect(() => {
    const err = enabled && emptyArray
    setError(showError && err)
    err
      ? addError(props.id, description.attribute, showError)
      : removeError(props.id, description.attribute)
  }, [enabled, emptyArray, showError])

  useEffect(() => {
    setValue(PoliciesHelper.getData(description, props.data.deviceSettings))
  }, [props.data.deviceSettings])

  const onChange = (v) => {
    setDisplayError(true)
    const values = v.map((rowData) => {
      return {
        language: rowData.languageValue,
        layout: rowData.layoutValue
      }
    })
    const deviceSettings = [...props.data.deviceSettings]
    PoliciesHelper.setData(description, deviceSettings, values)
    props.onAttributeChange({ ...props.data, deviceSettings })
  }

  return (
    <>
      <FlexRow className={'alignCenter'}>
        <Button
          appearance={'secondary'}
          onClick={() => setShowModal(true)}
          disabled={!enabled}
        >
          {getLocalized(
            value.length ? 'change-kb-layouts' : 'select-kb-layouts'
          )}
        </Button>
        <TextBlack className={'marginLeft12'} disabled={!enabled}>
          {getLocalized('selected-items-count', {
            count: value.length
          })}
        </TextBlack>
      </FlexRow>
      {error && (
        <WarningMessage
          id={description.attribute + '.error'}
          message={getLocalized('kb-layouts-not-selected-error')}
          type={MessageTypesEnum.ERROR}
        />
      )}

      <PreviewValue disabled={!enabled}>
        {summaryLabels.map((values) => (
          <div key={values.language} className={'marginTop8'}>
            {getLocalized('kb-layout-summary-entry', values)}
          </div>
        ))}
      </PreviewValue>
      {showModal && (
        <LanguageAndKeyboardLayoutModal
          value={value}
          getLocalized={getLocalized}
          items={description.items}
          onChange={(selectedValues) => onChange(selectedValues)}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

export default memo(LanguageAndKeyboardLayoutControl)

import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import uuid from 'react-uuid'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Button, ButtonGroup, Table } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import {
  AccessControlPermissionDescription,
  ArrayAttributeDescription,
  PolicyItemPrimitives
} from 'context/policies/PoliciesConfigurationEnum'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { FlexRow, FlexColumn, TextBlack, PreviewLabel } from 'styles/styles'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import {
  DeviceAccessRolesModalEnum,
  DeviceAccessEnum,
  getDefaultRoleName
} from './DeviceAccessEnums'
import DeviceAccessRolesModal from './DeviceAccessRolesModal'
import {
  getRowSelectAllState,
  TABLE_CONSTANTS,
  NO_SELECTION
} from 'common/utilities'
import 'styles/global.scss'

const tablePreferences = {
  width: [
    { columnId: DeviceAccessEnum.NAME, width: 200 },
    { columnId: DeviceAccessEnum.TYPE, width: 200 }
  ]
}

// Permissions settings' descriptions
const permDescriptions: AccessControlPermissionDescription[] = [
  'ctrl-panel-permissions',
  'ews-permissions'
].map((x) => ({
  type: PolicyItemPrimitives.AccessCtrlPermissions,
  attribute: `${PolicyAttributeEnum.Device_Access_Ctrl}.${x}`,
  label: '', // not used
  items: [] // not used
}))

const DeviceAccessRolesControl = (props) => {
  const description: ArrayAttributeDescription = props.description
  const {
    compliance,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const { isEnabled } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )

  const [showModal, setShowModal] = useState(DeviceAccessRolesModalEnum.NONE)
  const [selectedIndex, setSelectedIndex] = useState(NO_SELECTION)
  const [selectedCount, setSelectedCount] = useState(0)
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [tableData, setTableData] = useState([])

  useEffect(
    () => setTableData(value ? value.map((x, i) => tableItem(x, i)) : []),
    [value]
  )

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  const setData = (newTableData, updatePermissions?) => {
    setTableData(newTableData)
    const settings = [...deviceSettings]
    const newValue = newTableData.map((x) => ({
      [DeviceAccessEnum.ID]: x[TABLE_CONSTANTS.ID],
      [DeviceAccessEnum.NAME]: x[DeviceAccessEnum.NAME]
    }))
    PoliciesHelper.setData(description, settings, newValue)

    // Update permissions
    if (updatePermissions) {
      permDescriptions.forEach((descr) =>
        PoliciesHelper.setData(
          descr,
          settings,
          updatePermissions(PoliciesHelper.getData(descr, settings))
        )
      )
    }

    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  useEffect(() => {
    const selected = tableData.filter((x) => x.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
  }, [tableData])

  const onModal = (modalMode = DeviceAccessRolesModalEnum.ADD) => {
    setSelectedIndex(
      modalMode === DeviceAccessRolesModalEnum.ADD
        ? NO_SELECTION
        : tableData.findIndex((x) => x.rowConfig.selected)
    )
    setShowModal(modalMode)
  }

  const onChange = (v, modalMode) => {
    const selectedId =
      selectedIndex !== NO_SELECTION
        ? tableData[selectedIndex][TABLE_CONSTANTS.ID]
        : null
    const finder = (y) => y[DeviceAccessEnum.ID] === selectedId
    if (modalMode !== DeviceAccessRolesModalEnum.EDIT) {
      // ADD, COPY
      const newItem = {
        [DeviceAccessEnum.ID]: uuid(),
        [DeviceAccessEnum.NAME]: v
      }
      const updatePermissions = (permissions) =>
        permissions.map((x) => {
          const roles = x[DeviceAccessEnum.ROLES]
          let check = false
          if (selectedId && modalMode === DeviceAccessRolesModalEnum.COPY) {
            check = roles.find(finder)?.[DeviceAccessEnum.CHECK] ?? false
          }
          roles.push({ ...newItem, [DeviceAccessEnum.CHECK]: check })
          return x
        })
      setData(
        [...tableData, tableItem(newItem, tableData.length)],
        updatePermissions
      )
    } else if (selectedId) {
      // EDIT
      const updatePermissions = (permissions) =>
        permissions.map((x) => {
          const roles = x[DeviceAccessEnum.ROLES]
          const found = roles.find(finder)
          if (found) {
            found[DeviceAccessEnum.NAME] = v // update name in permission roles
          }
          return x
        })
      tableData[selectedIndex][DeviceAccessEnum.NAME] = v // update role name
      setData([...tableData], updatePermissions)
    }
  }

  const onRemove = () => {
    const newTableData = tableData.filter(
      (x) => !x.rowConfig.selected || getDefaultRoleName(x[TABLE_CONSTANTS.ID])
    )
    const updatePermissions = (permissions) =>
      permissions.map((x) => ({
        ...x,
        [DeviceAccessEnum.ROLES]: x[DeviceAccessEnum.ROLES].filter((role) =>
          newTableData.some(
            (y) => y[TABLE_CONSTANTS.ID] === role[DeviceAccessEnum.ID]
          )
        )
      }))
    setData(newTableData, updatePermissions)
  }

  const tableItem = (item, i) => {
    const selected = i < tableData.length && !!tableData[i].rowConfig.selected
    const name = getDefaultRoleName(item[DeviceAccessEnum.ID])
    return {
      [TABLE_CONSTANTS.ID]: item[DeviceAccessEnum.ID],
      [DeviceAccessEnum.NAME]: name
        ? getLocalized(name)
        : item[DeviceAccessEnum.NAME],
      [DeviceAccessEnum.TYPE]: getLocalized(
        name ? 'roles-type-default' : 'roles-type-custom'
      ),
      rowConfig: { selected }
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        return row
      })
    )
  }

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: TABLE_CONSTANTS.ID, label: TABLE_CONSTANTS.ID, index },
      { id: DeviceAccessEnum.NAME, label: getLocalized('roles-name') },
      { id: DeviceAccessEnum.TYPE, label: getLocalized('roles-type') }
    ]
  }, [])

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'marginBottom12'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  const enabled = isEnabled(attribute)
  const nonSingleSelection = !enabled || selectedCount !== 1
  const isDefaultSelected = () =>
    tableData.some(
      (x) => x.rowConfig.selected && getDefaultRoleName(x[TABLE_CONSTANTS.ID])
    )
  return (
    <>
      <FlexColumn>
        {tableHeader(getLocalized('roles'))}
        {onAttributeChange && (
          <FlexRow className={'marginBottom12 alignCenter'}>
            <ButtonGroup>
              <Button
                appearance={'secondary'}
                disabled={!enabled}
                onClick={() => onModal()}
                id={'addRole'}
              >
                {getLocalized('common.add')}
              </Button>
              <Button
                appearance={'secondary'}
                disabled={nonSingleSelection || isDefaultSelected()}
                onClick={() => onModal(DeviceAccessRolesModalEnum.EDIT)}
                id={'editRole'}
              >
                {getLocalized('common.edit')}
              </Button>
              <Button
                appearance={'secondary'}
                disabled={nonSingleSelection}
                onClick={() => onModal(DeviceAccessRolesModalEnum.COPY)}
                id={'editRole'}
              >
                {getLocalized('common.copy')}
              </Button>
              <Button
                appearance={'secondary'}
                disabled={!enabled || !selectedCount || isDefaultSelected()}
                onClick={onRemove}
                id={'removeRole'}
              >
                {getLocalized('common.remove')}
              </Button>
            </ButtonGroup>
          </FlexRow>
        )}
        <Table
          className={'widthColAuto'}
          data-testid={'id-device-access-roles-table'}
          rowSelector={
            onAttributeChange && enabled ? 'multiSelection' : undefined
          }
          rowSelectAllState={rowSelectAllState}
          preferences={tablePreferences}
          i18n={Retrievei18nItems()}
          columns={tableColumns}
          data={tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
        />
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
      {showModal !== DeviceAccessRolesModalEnum.NONE && (
        <DeviceAccessRolesModal
          show={showModal}
          value={
            selectedIndex === NO_SELECTION
              ? ''
              : tableData[selectedIndex][DeviceAccessEnum.NAME]
          }
          values={tableData.map((x) => x[DeviceAccessEnum.NAME])}
          onClose={() => setShowModal(DeviceAccessRolesModalEnum.NONE)}
          getLocalized={getLocalized}
          onChange={(v) => onChange(v, showModal)}
        />
      )}
    </>
  )
}

export default memo(DeviceAccessRolesControl)

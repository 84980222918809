import {
  Accordion,
  AnchorMenu,
  Card,
  IconCheckmarkCircle,
  IconWarningAlt,
  IconInfo,
  IconXCircle,
  IconPlay,
  Button
} from '@veneer/core'
import React, {
  useContext,
  useMemo,
  useEffect,
  useState,
  useCallback
} from 'react'
import { AccordionContent, FlexColumn, FlexRow, SideMenu } from 'styles/styles'
import configContext from 'context/config/configContext'
import PoliciesContext from 'context/policies/policiesContext'
import PolicyConstraints from 'context/constraints/Constraints'
import CreatePolicy from 'components/policies/table/CreatePolicy'
import ComplianceSummary from './complianceSummary'
import CompliancePolicies from './compliancePolices'
import ComplianceTable from './complianceTable'
import { getDisplayToast } from 'common/utilities'
import { ToastIdEnum, PolicyAttributeComplianceEnum } from '../constants'
import 'styles/global.scss'

const emptyDeviceSpecific = {
  policyId: null,
  name: null,
  status: PolicyAttributeComplianceEnum.UNKNOWN,
  attributes: []
}

const DeviceComplianceInfo = (props) => {
  const { tt } = useContext(configContext)
  const {
    getDevicePolicy,
    selectedPolicy,
    getDeviceDetails,
    deviceData,
    triggerAssessment
  } = useContext(PoliciesContext)
  const {
    useToast,
    deviceModelNumber,
    deviceSerialNumber,
    deviceId,
    entitlements,
    containerId
  } = props
  const displayToast = getDisplayToast(useToast)

  const [lastUpdated, setLastUpdated] = useState(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (deviceId) {
      getDevicePolicy(deviceId, (error) =>
        displayToast(ToastIdEnum.GET_DEVICE_POLICY, error, 'negative')
      )
      getDeviceDetails(deviceId, (error) =>
        displayToast(ToastIdEnum.GET_COMPLIANCE, error, 'negative')
      )
    }
  }, [deviceId])

  useEffect(() => {
    setLastUpdated(selectedPolicy?.lastModifiedAt)
  }, [selectedPolicy])

  useEffect(() => {
    // Retrieve newer compliance data if policy has been updated
    if (lastUpdated > deviceData?.lastRunAt) {
      getDeviceDetails(deviceId, (error) =>
        displayToast(ToastIdEnum.GET_COMPLIANCE, error, 'negative')
      )
    }
  }, [lastUpdated, deviceData])

  const getLocalized = useCallback(
    (key: string, params?): string => {
      return tt('complianceStatus', key, params)
    },
    [tt]
  )
  const constraints = useMemo(() => {
    return deviceModelNumber
      ? new PolicyConstraints(deviceModelNumber)
      : undefined
  }, [deviceModelNumber])

  const onRun = () =>
    triggerAssessment(deviceId, (error) => {
      if (error) {
        displayToast(ToastIdEnum.RUN_NOW_ERROR, error, 'negative')
      } else {
        // Make current compliance result look outdated
        setLastUpdated(deviceData?.lastRunAt + 1)
        displayToast(
          ToastIdEnum.RUN_NOW_DONE,
          getLocalized('run-success-title'),
          'positive',
          getLocalized('run-success')
        )
      }
    })

  const policyName = useCallback(
    (name) => name || getLocalized('deviceSpecific'),
    [getLocalized]
  )
  const policyId = (id) => id || 'id-device-specific'

  const allPolicies = useMemo(() => {
    const policies = deviceData?.policies || []
    return !policies.length || policies[0].policyId
      ? [emptyDeviceSpecific, ...policies]
      : policies
  }, [deviceData])

  const anchorMenuLabels = useMemo(
    () =>
      allPolicies.map((policy) => ({
        anchor: policyId(policy.policyId),
        label: policyName(policy.name)
      })),
    [allPolicies, policyName]
  )

  const allAttributes = useMemo(() => {
    return deviceData?.policies
      ? deviceData.policies.reduce((acc, policy) => {
          const attributes = policy.attributes?.filter(
            (attribute) =>
              attribute.status !== PolicyAttributeComplianceEnum.OVERRIDDEN
          )
          const name = policyName(policy.name)
          return attributes?.length
            ? [...acc, ...attributes.map((x) => ({ ...x, policyName: name }))]
            : acc
        }, [])
      : null
  }, [deviceData, policyName])

  const checkIcon = useCallback(
    (status, size?) => {
      const icon = () => {
        const props = (color) => ({
          color,
          size,
          filled: true,
          customStyle: { flexShrink: 0 }
        })
        switch (status) {
          case PolicyAttributeComplianceEnum.NONCOMPLIANT:
            return <IconXCircle {...props('red7')} />
          case PolicyAttributeComplianceEnum.COMPLIANT:
            return <IconCheckmarkCircle {...props('green7')} />
          case PolicyAttributeComplianceEnum.OVERRIDDEN:
            return <IconWarningAlt {...props('darkOrange5')} />
          default:
            break
        }
        // PolicyAttributeComplianceEnum.UNKNOWN
        return <IconInfo {...props('gray7')} />
      }
      return (
        <FlexRow className={'alignCenter'}>
          {icon()}
          <p className={'paddingLeft6'}>{getLocalized(status)}</p>
        </FlexRow>
      )
    },
    [getLocalized]
  )

  const statusAccordionContent = (
    <AccordionContent>
      <FlexColumn className={'fullWidth'}>
        <ComplianceSummary
          deviceData={deviceData}
          allAttributes={allAttributes}
          getLocalized={getLocalized}
          policyName={policyName}
        />
        <ComplianceTable
          allAttributes={allAttributes}
          getLocalized={getLocalized}
          checkIcon={checkIcon}
        />
      </FlexColumn>
    </AccordionContent>
  )

  return (
    <FlexRow id={'deviceInfo'} style={{ marginTop: '20px' }}>
      <FlexColumn style={{ width: '80%' }}>
        <Card
          id={'complianceStatus'}
          content={
            <Accordion
              className={'rightPaddingAccordion'}
              items={[
                {
                  id: 'complianceInfo',
                  content: statusAccordionContent,
                  expanded: true,
                  header: {
                    centralArea: (
                      <div
                        className={'centralAreaAccordion'}
                        data-testid={'compliance-info-title'}
                      >
                        {getLocalized('title')}
                      </div>
                    ),
                    endArea: (
                      <Button
                        small
                        disabled={!allAttributes?.length}
                        appearance={'ghost'}
                        leadingIcon={<IconPlay />}
                        data-testid={'compliance-info-run-button'}
                        onClick={onRun}
                      >
                        {getLocalized('run')}
                      </Button>
                    )
                  }
                }
              ]}
              behavior={'singleExpand'}
              id={'status-accordion'}
            />
          }
          border={'dropShadow'}
          customStyle={{ marginBottom: '16px' }}
        />
        <CompliancePolicies
          allPolicies={allPolicies}
          getLocalized={getLocalized}
          policyName={policyName}
          serialNumber={deviceSerialNumber}
          policyId={policyId}
          checkIcon={checkIcon}
          setShowModal={setShowModal}
        />
      </FlexColumn>
      <SideMenu style={{ width: '20%' }}>
        <AnchorMenu
          containerId={containerId}
          id={'device-compliance-anchor'}
          position={{
            position: 'relative',
            end: 0,
            start: 24
          }}
          items={[
            {
              anchor: 'complianceStatus',
              label: getLocalized('title')
            },
            ...anchorMenuLabels
          ]}
        />
      </SideMenu>
      {showModal && (
        <CreatePolicy
          deviceId={deviceId}
          entitlements={entitlements}
          constraints={constraints}
          attributes={selectedPolicy?.attributes || []}
          displayToast={displayToast}
          onClose={() => setShowModal(false)}
        />
      )}
    </FlexRow>
  )
}
export default DeviceComplianceInfo

import { Item } from '../PoliciesConfigurationEnum'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

// common localization keys
const languageCode = (code, opt?) => {
  return {
    value: 'language-code.' + code,
    label: 'policy.settings.languages.' + code,
    option: opt || code
  }
}

export default class LanguageItems {
  static allCodes: Item[] = [
    languageCode('ar'),
    languageCode('eu'),
    languageCode('bg'),
    languageCode('ca'),
    languageCode('hr'),
    languageCode('cs'),
    languageCode('da'),
    languageCode('nl'),
    languageCode('en'),
    languageCode('et'),
    languageCode('fi'),
    languageCode('fr'),
    languageCode('de'),
    languageCode('el'),
    languageCode('he'),
    languageCode('hu'),
    languageCode('id'),
    languageCode('it'),
    languageCode('ja'),
    languageCode('ko'),
    languageCode('lv'),
    languageCode('lt'),
    languageCode('ms'),
    languageCode('no'),
    languageCode('nb'),
    languageCode('nn'),
    languageCode('pl'),
    languageCode('pt'),
    languageCode('ro'),
    languageCode('ru'),
    languageCode('zh-cn', 'zh-CN'),
    languageCode('sk'),
    languageCode('sl'),
    languageCode('es'),
    languageCode('sv'),
    languageCode('th'),
    languageCode('zh-tw', 'zh-TW'),
    languageCode('tr'),
    languageCode('uk'),
    languageCode('vi')
  ]

  static languageCodes = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? LanguageItems.allCodes
      : constraints.filterSettingOptions(
          LanguageItems.allCodes,
          ConstraintsResourceEnum.controlPanelCfg,
          ConstraintsSettingEnum.currentLanguage
        )

  static languageCodesEws = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? LanguageItems.allCodes
      : constraints.filterSettingOptions(
          LanguageItems.allCodes,
          ConstraintsResourceEnum.ewsConfiguration,
          ConstraintsSettingEnum.selectedLanguage
        )
}

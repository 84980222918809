import { Item } from '../PoliciesConfigurationEnum'
import { PolicyAttributeEnum } from '../PoliciesCategories'

const generateConnectionMethodObj = (connectionMethod: string) => {
  return {
    value: `${PolicyAttributeEnum.WiFi_Direct}.method.${connectionMethod}`,
    label: 'method-' + connectionMethod
  }
}

export default class WiFiDirectItems {
  static allChannels: Item[] = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' }, // 5
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '10' },
    { value: '11' },
    { value: '36' }, // 11
    { value: '40' },
    { value: '44' },
    { value: '48' },
    { value: '149' },
    { value: '153' },
    { value: '157' },
    { value: '161' },
    { value: '165' }
  ]
  static channels24: Item[] = WiFiDirectItems.allChannels.slice(0, 11)
  static channels50: Item[] = WiFiDirectItems.allChannels.slice(11)

  static wifiDirectConnectionMethods: Item[] = [
    generateConnectionMethodObj('auto'),
    generateConnectionMethodObj('manual'),
    generateConnectionMethodObj('advanced')
  ]

  static wifiBands: Item[] = [{ value: '2_4ghz' }, { value: '5ghz' }]

  static getChannels = (band: string) =>
    band === WiFiDirectItems.wifiBands[1].value
      ? WiFiDirectItems.channels50
      : WiFiDirectItems.channels24

  static getDefChannel = (band: string) =>
    WiFiDirectItems.allChannels[
      band === WiFiDirectItems.wifiBands[1].value ? 11 : 5
    ].value

  static getBand = (channel: string) =>
    WiFiDirectItems.channels50.some((option) => option.value === channel)
      ? WiFiDirectItems.wifiBands[1].value
      : WiFiDirectItems.wifiBands[0].value
}

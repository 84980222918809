import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

export interface Condition {
  readonly value?: string | boolean
  readonly rule?: RegExp
  readonly message: string
  readonly messageType?: MessageTypesEnum
  readonly params?
  check?: (value) => boolean
}

export enum MessageTypesEnum {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

const ipV4Seg = '(25[0-5]|((2[0-4]|1[0-9]|[1-9]|)[0-9]))'
const ipV4 = `(${ipV4Seg}\\.){3}${ipV4Seg}`
const hex = '[0-9a-fA-F]'
const ipV6Seg = `${hex}{1,4}`
const alpha = '[a-zA-Z]'
const alphaNum = '[a-zA-Z0-9]'
const alphaNumEx = '[a-zA-Z0-9-]'
const startsWithHttps = '^https://'
const alphaNumExtended = '[a-zA-Z0-9\\-]'
const eMail = `[a-zA-Z0-9\\-\\.]+@(${alphaNumEx}+\\.)+${alphaNumEx}{2,}`

const uppercasePattern = '[A-Z]'
const lowercasePattern = '[a-z]'
const specialCharPattern = '[!@#$%^&*(),.?":{}|<>]'
const numberPattern = '[0-9]'
const digits = '^[0-9]+$'

const reEmail = `^${eMail}$`
const reEmailList = `^${eMail}(,${eMail}){0,}$`
const rePhoneNumber = `^[\\d\\(\\)\\*\\+\\-#]+$`
const reIpV4 = `^${ipV4}$`
const reDomainName = `^${alphaNum}${alphaNumEx}{1,61}${alphaNum}(?:\\.${alpha}{2,})+$`
const reDomainName2 = `^(((${alphaNum})((${alphaNumExtended}){0,62}(\\.?))((${alphaNumExtended}){1,63}(\\.?))*(${alphaNumExtended}){1,63})*)?$`
const reFileName = '[/\\\\:*?"|<>]'

const siteSegment = '[a-zA-Z\\*]([a-zA-Z0-9\\-\\*]{0,61}[a-zA-Z0-9\\*])?'
const reCorsSiteName = `^(${siteSegment}\\.)*${siteSegment}$`
const nnMmPattern = '^(0|[1-9][0-9]?)-(0|[1-9][0-9]?)$'

const reIpV6 = [
  `^(${ipV6Seg}:){7}${ipV6Seg}$`,
  `^(${ipV6Seg}:){1,7}:$`,
  `^(${ipV6Seg}:){1,6}:${ipV6Seg}$`,
  `^(${ipV6Seg}:){1,5}(:${ipV6Seg}){1,2}$`,
  `^(${ipV6Seg}:){1,4}(:${ipV6Seg}){1,3}$`,
  `^(${ipV6Seg}:){1,3}(:${ipV6Seg}){1,4}$`,
  `^(${ipV6Seg}:){1,2}(:${ipV6Seg}){1,5}$`,
  `^${ipV6Seg}:((:${ipV6Seg}){1,6})$`,
  `^:((:${ipV6Seg}){1,7}|:)$`,
  `^fe80:(:${ipV6Seg}){0,4}%${alphaNum}{1,}$`,
  `^::(ffff(:0{1,4}){0,1}:){0,1}${ipV4}$`,
  `^(${ipV6Seg}:){1,4}:${ipV4}$`
]

export default class PoliciesErrors {
  static outOfRange(value: string, min: number, max: number): boolean {
    if (/^(?!0.)\d+$/.test(value)) {
      const numValue = parseInt(value, 10)
      return numValue > max || numValue < min
    }
    return true
  }

  static errorTextLength = (length): Condition => {
    return {
      check: (v) => v.length > length,
      message: 'common.errors.text-length',
      params: { length }
    }
  }

  static errorTextLengthEx = (
    constraints: PolicyConstraints,
    resource: ConstraintsResourceEnum,
    setting: ConstraintsSettingEnum,
    def: number
  ): Condition => {
    return PoliciesErrors.errorTextLength(
      (constraints && constraints.getSettingMax(resource, setting)) || def
    )
  }

  static errorsTextLengthEx = (
    constraints: PolicyConstraints,
    resource: ConstraintsResourceEnum,
    setting: ConstraintsSettingEnum,
    def: number
  ): Condition[] => {
    const conditions = []
    if (constraints && constraints.getSettingMin(resource, setting)) {
      conditions.push(PoliciesErrors.errorsNotSelected[0])
    }
    const maxLen =
      (constraints && constraints.getSettingMax(resource, setting)) || def
    conditions.push(PoliciesErrors.errorTextLength(maxLen))
    return conditions
  }

  static errorIntegerRange = (from, to): Condition => {
    return {
      check: (v) => PoliciesErrors.outOfRange(v, from, to),
      message: 'common.errors.integer-range',
      params: { from, to }
    }
  }

  static errorIntegerRangeEx = (
    constraints: PolicyConstraints,
    resource: ConstraintsResourceEnum,
    setting: ConstraintsSettingEnum,
    defFrom,
    defTo
  ): Condition[] => {
    const min = constraints?.getSettingMin(resource, setting) || defFrom
    const max = constraints?.getSettingMax(resource, setting) || defTo
    return PoliciesErrors.errorsIntegerRange(min, max)
  }

  // Errors

  static atleastOneUppercase: Condition[] = [
    {
      check: (v) => !RegExp(uppercasePattern).test(v),
      message: 'common.errors.atleast-one-uppercase'
    }
  ]

  static atleastOneLowercase: Condition[] = [
    {
      check: (v) => !RegExp(lowercasePattern).test(v),
      message: 'common.errors.atleast-one-lowercase'
    }
  ]

  static atleastOneSpecialChar: Condition[] = [
    {
      check: (v) => !RegExp(specialCharPattern).test(v),
      message: 'common.errors.atleast-one-special-char'
    }
  ]

  static atleastOneNumber: Condition[] = [
    {
      check: (v) => !RegExp(numberPattern).test(v),
      message: 'common.errors.atleast-one-number'
    }
  ]

  static allDigits: Condition[] = [
    {
      check: (v) => !RegExp(digits).test(v),
      message: 'common.errors.all-digits'
    }
  ]

  static passwordLength = (min, max): Condition[] => [
    {
      check: (v) => v.length < min || v.length > max,
      message: 'common.errors.password-length',
      params: { min, max }
    }
  ]

  static errorsPassword = (min, max): Condition[] => [
    ...PoliciesErrors.passwordLength(min, max),
    ...PoliciesErrors.atleastOneUppercase,
    ...PoliciesErrors.atleastOneLowercase,
    ...PoliciesErrors.atleastOneSpecialChar,
    ...PoliciesErrors.atleastOneNumber
  ]

  static errorsPin = (min, max): Condition[] => [
    ...PoliciesErrors.passwordLength(min, max),
    ...PoliciesErrors.allDigits
  ]

  static errorsIpv4: Condition[] = [
    {
      check: (v) => !RegExp(reIpV4).test(v),
      message: 'common.errors.ipv4-format'
    }
  ]
  static errorsIpv6: Condition[] = [
    {
      check: (v) => {
        return !reIpV6.some((x) => RegExp(x).test(v))
      },
      message: 'common.errors.ipv6-format'
    }
  ]
  static errorsIpv4v6Empty: Condition[] = [
    {
      check: (v) => {
        const regExps = [reIpV4, ...reIpV6]
        return !!v && !regExps.some((x) => RegExp(x).test(v))
      },
      message: 'common.errors.ipv4-v6-format'
    }
  ]

  static errorsIpv6Domain: Condition[] = [
    {
      check: (v) => v.length < 3 || v.length > 0x40,
      message: 'error_wrong_domain_name_length'
    },
    {
      check: (v) => !RegExp(reDomainName).test(v),
      message: 'error_wrong_domain_name_format'
    }
  ]

  static errorsHttpsDomainName: Condition[] = [
    {
      check: (v) => !RegExp(startsWithHttps).test(v),
      message: 'common.errors.starts-with-https'
    },
    {
      check: (v) => !RegExp(reDomainName.slice(1)).test(v), // Slicing so that https is the starting string since reDomainName also has StartsWith character.
      message: 'common.errors.invalid-domain'
    }
  ]

  static errorsCorsSiteNameName: Condition[] = [
    {
      check: (v) => !RegExp(reCorsSiteName).test(v),
      message: 'common.errors.invalid-domain'
    }
  ]

  static errorsFaxDomainName: Condition[] = [
    {
      check: (v) => !RegExp(reDomainName).test(v),
      message: 'common.errors.invalid-domain'
    }
  ]

  static errorsDnsDomainName: Condition[] = [
    {
      check: (v) => !RegExp(reDomainName2).test(v),
      message: 'common.errors.invalid-domain'
    },
    { value: '', message: 'common.errors.invalid-domain' }
  ]

  static errorsPhoneNumber: Condition[] = [
    {
      check: (v) => !RegExp(rePhoneNumber).test(v),
      message: 'error_phone_number'
    },
    PoliciesErrors.errorTextLength(20)
  ]

  static errorsFaxSpeedDialLock: Condition[] = [
    {
      check: (v) => !RegExp(nnMmPattern).test(v),
      message: 'error_fax_speed_dial_format'
    },
    {
      check: (v) => {
        const [nn, mm] = v.split('-').map(Number)
        return nn >= mm
      },
      message: 'error_fax_speed_dial_range'
    }
  ]

  static errorWrongCharNumber: Condition = {
    rule: /\D/,
    message: 'common.errors.wrong-char-number'
  }

  static errorsIntegerRange = (min, max): Condition[] => [
    PoliciesErrors.errorWrongCharNumber,
    PoliciesErrors.errorIntegerRange(min, max)
  ]

  // Shared range errors
  static errorsMaxAttempts = PoliciesErrors.errorsIntegerRange(3, 30)
  static errorsResetAfter = PoliciesErrors.errorsIntegerRange(0, 1800)
  static errorsLockoutDuration = PoliciesErrors.errorsIntegerRange(5, 1800)
  static errorsPortNumber = PoliciesErrors.errorsIntegerRange(1, 65535)
  static errorsStartingNumber = PoliciesErrors.errorsIntegerRange(1, 1000)

  static errorsInvalidEmail: Condition[] = [
    {
      check: (v) => !RegExp(reEmail).test(v),
      message: 'common.errors.email-format'
    }
  ]

  static errorsInvalidEmailList: Condition[] = [
    {
      check: (v) => !RegExp(reEmailList).test(v),
      message: 'common.errors.email-list-format'
    }
  ]

  static errorsText32: Condition[] = [PoliciesErrors.errorTextLength(32)]
  static errorsText255: Condition[] = [PoliciesErrors.errorTextLength(255)]

  static errorFileName: Condition = {
    check: (v) => RegExp(reFileName).test(v),
    message: 'common.errors.file-name'
  }

  static errorsFileName: Condition[] = [
    PoliciesErrors.errorTextLength(1024),
    PoliciesErrors.errorFileName
  ]

  static errorsNotSelected: Condition[] = [
    { value: '', message: 'error_empty' }
  ]

  static errorsTextLength = (length): Condition[] => [
    ...PoliciesErrors.errorsNotSelected,
    PoliciesErrors.errorTextLength(length)
  ]

  static errorsPrefix: Condition[] = [
    ...PoliciesErrors.errorsTextLength(20),
    PoliciesErrors.errorFileName
  ]

  static errorsProxyAddress = (constraints?): Condition[] => [
    ...PoliciesErrors.errorsNotSelected,
    PoliciesErrors.errorTextLengthEx(
      constraints,
      ConstraintsResourceEnum.proxyCfg,
      ConstraintsSettingEnum.httpProxyAddress,
      64
    )
  ]

  static errorsLdapBindPrefix = (constraints?): Condition[] => [
    ...PoliciesErrors.errorsNotSelected,
    PoliciesErrors.errorTextLengthEx(
      constraints,
      ConstraintsResourceEnum.ldapConfig,
      ConstraintsSettingEnum.ldapBindPrefix,
      64
    )
  ]

  static errorsAuthWirelessUser = (constraints?): Condition[] => [
    ...PoliciesErrors.errorsNotSelected,
    PoliciesErrors.errorTextLengthEx(
      constraints,
      ConstraintsResourceEnum.wirelessConfig,
      ConstraintsSettingEnum.dot1xAuthConfigUserName,
      128
    )
  ]

  static errorsAuthWirelessPassword = (constraints?): Condition[] =>
    PoliciesErrors.passwordLength(
      1,
      constraints?.getSettingMax(
        ConstraintsResourceEnum.wirelessConfig,
        ConstraintsSettingEnum.dot1xAuthConfigPassword
      ) || 128
    )

  static errorsAuthWirelessIdentity = (constraints?): Condition[] => [
    PoliciesErrors.errorTextLengthEx(
      constraints,
      ConstraintsResourceEnum.wirelessConfig,
      ConstraintsSettingEnum.dot1xAuthConfigServerIdentity,
      256
    )
  ]

  static errorsLdapMatchName = (constraints?): Condition[] =>
    PoliciesErrors.errorsTextLengthEx(
      constraints,
      ConstraintsResourceEnum.ldapConfig,
      ConstraintsSettingEnum.matchLdapNameAttribute,
      64
    )

  static errorsLdapRetrieveName = (constraints?): Condition[] =>
    PoliciesErrors.errorsTextLengthEx(
      constraints,
      ConstraintsResourceEnum.ldapConfig,
      ConstraintsSettingEnum.retrieveLdapNameAttribute,
      64
    )

  static errorsLdapRetrieveEmail = (constraints?): Condition[] =>
    PoliciesErrors.errorsTextLengthEx(
      constraints,
      ConstraintsResourceEnum.ldapConfig,
      ConstraintsSettingEnum.retrieveLdapEmailAttribute,
      64
    )

  static errorsLdapRetrieveGroup = (constraints?): Condition[] =>
    PoliciesErrors.errorsTextLengthEx(
      constraints,
      ConstraintsResourceEnum.ldapConfig,
      ConstraintsSettingEnum.retrieveLdapGroupAttribute,
      64
    )

  static errorsDefFromName = (constraints?): Condition[] => [
    ...PoliciesErrors.errorsNotSelected,
    PoliciesErrors.errorTextLengthEx(
      constraints,
      ConstraintsResourceEnum.jobTicketCfgDefEmail,
      ConstraintsSettingEnum.destEmailFromDisplayName,
      80
    )
  ]

  static errorsSubject = (constraints?): Condition[] => [
    PoliciesErrors.errorTextLengthEx(
      constraints,
      ConstraintsResourceEnum.jobTicketCfgDefEmail,
      ConstraintsSettingEnum.destEmailSubject,
      127
    )
  ]

  static errorsMessage = (constraints?): Condition[] => [
    PoliciesErrors.errorTextLengthEx(
      constraints,
      ConstraintsResourceEnum.jobTicketCfgDefEmail,
      ConstraintsSettingEnum.destEmailBody,
      4000
    )
  ]

  static errorsAssetNumber = (constraints?): Condition[] => [
    PoliciesErrors.errorTextLengthEx(
      constraints,
      ConstraintsResourceEnum.deviceCfg,
      ConstraintsSettingEnum.assetNumber,
      32
    )
  ]

  static errorsCompanyName = (constraints?): Condition[] => [
    PoliciesErrors.errorTextLengthEx(
      constraints,
      ConstraintsResourceEnum.deviceCfg,
      ConstraintsSettingEnum.companyName,
      32
    )
  ]

  static errorsContactPerson = (constraints?): Condition[] => [
    PoliciesErrors.errorTextLengthEx(
      constraints,
      ConstraintsResourceEnum.deviceCfg,
      ConstraintsSettingEnum.companyContact,
      32
    )
  ]

  static errorsDeviceLocation = (constraints?): Condition[] =>
    PoliciesErrors.errorsTextLengthEx(
      constraints,
      ConstraintsResourceEnum.deviceCfg,
      ConstraintsSettingEnum.deviceLocation,
      32
    )

  static errorsDeviceName = (constraints?): Condition[] =>
    PoliciesErrors.errorsTextLengthEx(
      constraints,
      ConstraintsResourceEnum.deviceCfg,
      ConstraintsSettingEnum.deviceDescription,
      32
    )

  static errorsSyncServerTime: Condition[] = [
    PoliciesErrors.errorWrongCharNumber,
    {
      check: (v) => PoliciesErrors.outOfRange(v, 1, 168),
      message: 'error_sync_hours'
    }
  ]

  static errorRingsToAnswer = (constraints?): Condition[] =>
    PoliciesErrors.errorIntegerRangeEx(
      constraints,
      ConstraintsResourceEnum.faxModemCfg,
      ConstraintsSettingEnum.analogFaxReceiveRings,
      1,
      6
    )

  static errorsMinPasswordLength = (constraints?): Condition[] =>
    PoliciesErrors.errorIntegerRangeEx(
      constraints,
      ConstraintsResourceEnum.accountPolicy,
      ConstraintsSettingEnum.minPasswordLength,
      8,
      32
    )

  static errorsTempJobStorageLimit = (constraints?): Condition[] =>
    PoliciesErrors.errorIntegerRangeEx(
      constraints,
      ConstraintsResourceEnum.storeJobMgtCfg,
      ConstraintsSettingEnum.temporaryJobLimit,
      1,
      300
    )
}

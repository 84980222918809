import { Item } from '../PoliciesConfigurationEnum'
import { PolicyAttributeEnum } from '../PoliciesCategories'

const messageRecipient = (x) => {
  return {
    value: 'message-recipient.' + x,
    label: 'policy.settings.message-recipients.' + x
  }
}

export default class EmailAddressItems {
  static restrictionItems: Item[] = [
    {
      value: `${PolicyAttributeEnum.Email_Message}.restrictions.type`,
      label: 'policy.settings.restrictions.type'
    },
    {
      value: `${PolicyAttributeEnum.Email_Message}.restrictions.ab`,
      label: 'policy.settings.restrictions.ab'
    }
  ]

  static recipientItems: Item[] = [
    messageRecipient('default'),
    messageRecipient('blank'),
    messageRecipient('address'),
    messageRecipient('addresses')
  ]

  static fromItems: Item[] = [
    EmailAddressItems.recipientItems[0],
    EmailAddressItems.recipientItems[2]
  ]

  static toItems: Item[] = EmailAddressItems.recipientItems.slice(1, 3)

  static toItemsQs: Item[] = EmailAddressItems.recipientItems.slice(1)
}

import { RadioButton, RadioGroup, TextBox } from '@veneer/core'
import configContext from 'context/config/configContext'
import {
  RadioTextBoxDescription,
  ControlWidthEnum
} from 'context/policies/PoliciesConfigurationEnum'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import settingsContext from 'context/settings/settingsContext'
import { getError } from 'common/utilities'
import { TextBlack } from 'styles/styles'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import WarningMessage from './WarningMessage'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const RadioTextBoxControl = (props) => {
  const description: RadioTextBoxDescription = props.description
  const {
    id,
    localizationPath,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { radioKey, textKey, label, items, widthStyle } = description
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )

  const getErrors = () => {
    if (!value[radioKey]) {
      return {
        [radioKey]: { message: 'common.errors.not-selected', params: {} }
      }
    }
    const item = items.find((item) => item.value === value[radioKey])
    const error = item ? getError(item.errors, value[textKey]) : null
    return error ? { [textKey]: error } : null
  }

  const { tt } = useContext(configContext)
  const [errors, setErrors] = useState(getErrors())

  const handleChange = (option, key) => {
    const settings = [...deviceSettings]
    const options = { ...value, [key]: option }
    setValue(options)
    setDisplayError(true)
    PoliciesHelper.setData(description, settings, options)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }
  const { isEnabled, displayAllErrors, addError, removeError } =
    useContext(settingsContext)

  const [displayError, setDisplayError] = useState(displayAllErrors)
  const showError = displayError || displayAllErrors
  const enabled = isEnabled(attribute)

  useEffect(() => {
    const errs = enabled ? getErrors() : null
    setErrors(showError ? errs : null)
    errs ? addError(id, attribute, showError) : removeError(id, attribute)
  }, [enabled, value, showError])

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  const compliance = useMemo(
    () => PoliciesHelper.getCompliance(description, deviceSettings),
    [deviceSettings]
  )

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  const createPreviewItem = () => {
    if (!value[textKey] || !value[radioKey]) {
      return null
    }
    const key = items.find((item) => item.value === value[radioKey])
    return getLocalized('preview-value', {
      value: value[textKey],
      unit: key ? getLocalized(`${localizationPath}.${key.label}`) : ''
    })
  }

  return onAttributeChange ? (
    <>
      <TextBlack className={'marginBottom8'} disabled={!enabled}>
        {getLocalized(label)}
      </TextBlack>
      <RadioGroup name={'radio_buttons'}>
        {items.map((i) => {
          const error = errors?.[textKey]
          return (
            <div key={i.value}>
              <RadioButton
                id={i.value}
                value={i.value}
                label={getLocalized(i.label)}
                checked={i.value === value[radioKey]}
                disabled={!enabled}
                onChange={() => handleChange(i.value, radioKey)}
              />
              {i.hint && i.value === value[radioKey] && (
                <TextBox
                  value={value[textKey] || ''}
                  placeholder={getLocalized(i.hint)}
                  className={`marginTop8 devSettingsIndent1 ${
                    widthStyle || ControlWidthEnum.NORMAL
                  }`}
                  disabled={!enabled}
                  onChange={(value) => handleChange(value, textKey)}
                  id={textKey}
                  error={!!error}
                  helperText={getLocalized(
                    error?.message || i.helper || i.hint,
                    error?.params
                  )}
                />
              )}
            </div>
          )
        })}
      </RadioGroup>
      {errors?.[radioKey] && (
        <WarningMessage
          id={attribute + '.error'}
          type={MessageTypesEnum.ERROR}
          message={getLocalized(errors[radioKey].message)}
        />
      )}
    </>
  ) : (
    <PreviewItem
      label={`${localizationPath}.${label}`}
      value={createPreviewItem() || ''}
      compliance={compliance[textKey] || compliance[radioKey]}
    />
  )
}

export default memo(RadioTextBoxControl)

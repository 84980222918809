import React, { useState, useMemo, useEffect } from 'react'
import { Button, ButtonGroup, Modal, Table, TextBox } from '@veneer/core'
import { SortTypes } from '@veneer/core/dist/scripts/table'
import WarningMessage from '../WarningMessage'
import PoliciesConfiguration from 'context/policies/PoliciesConfiguration'
import { FlexColumn, FlexRowWithSpace } from 'styles/styles'
import { abcSort, getRowSelectAllState } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const defSortOrder = 'ascending' as SortTypes
const labelColumnId = 'label'
const valueColumnId = 'value'

export enum DelayVeryLowMessageEnum {
  BLACK_TONER = 'black-toner',
  CYAN_TONER = 'cyan-toner',
  MAGENTA_TONER = 'magenta-toner',
  YELLOW_TONER = 'yellow-toner',
  BLACK_DRUM = 'black-drum',
  CYAN_DRUM = 'cyan-drum',
  MAGENTA_DRUM = 'magenta-drum',
  YELLOW_DRUM = 'yellow-drum',
  TRANSFER_KIT = 'transfer-kit',
  FUSER_KIT = 'fuser-kit',
  MAINTENANCE_KIT = 'maintenance-kit'
}

const DelayVeryLowMessageModal = (props) => {
  const { onClose, onChange, getLocalized, value } = props

  const [tableData, setTableData] = useState([])
  const [orderType, setOrderType] = useState(defSortOrder)
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [selectedCount, setSelectedCount] = useState(0)
  const [preSelectClick, setPreSelectClick] = useState(true)
  const [disabled, setDisabled] = useState(true)

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find((row) => row.uid === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    const newTableData = tableData.map((row) => {
      const rowCopy = row
      row.rowConfig.selected = checked
      return rowCopy
    })
    setTableData(newTableData)
  }

  const selectedValues = () => tableData.filter((x) => x.rowConfig.selected)

  useEffect(() => {
    const items = selectedValues()
    setDisabled(
      !items.length ||
        (items.length === value.length &&
          items.every((x) =>
            value
              .filter((v) => v.threshold === x.threshold)
              .map((v) => v.value)
              .includes(x.value)
          ))
    )
    setSelectedCount(items.length)
    setRowSelectAllState(getRowSelectAllState(items.length, tableData.length))
  }, [tableData])

  const handleSelect = () => {
    setPreSelectClick(false)
    const selected = selectedValues()
    if (
      !selected.filter((tableItem) =>
        PoliciesConfiguration.outOfRange(tableItem.value, 0, 65535)
      ).length
    ) {
      onChange(
        selected.map((i) => {
          const { value, threshold } = i
          return { value, threshold }
        })
      )
      onClose()
    }
  }

  const getValue = (item, erroneous) => {
    return (
      <FlexColumn>
        <TextBox
          id={'thTextField_' + `${item.uid}`}
          value={item.value || ''}
          placeholder={getLocalized('placeholder')}
          disabled={!item.rowConfig.selected}
          onChange={(value) => {
            const found = tableData.find((row) => row.uid === item.uid)
            if (found) {
              found.value = value
              found.wasChanged = true
              setTableData([...tableData])
            }
          }}
        />
        {erroneous && (
          <WarningMessage
            type={MessageTypesEnum.ERROR}
            message={getLocalized('error_invalid_value')}
          />
        )}
      </FlexColumn>
    )
  }

  const getDataForTable = (item) => {
    const erroneous =
      item.rowConfig.selected &&
      (!preSelectClick || item.wasChanged || item.value) &&
      PoliciesConfiguration.outOfRange(item.value, 0, 65535)
    return {
      ...item,
      erroneous,
      value: getValue(item, erroneous)
    }
  }

  useEffect(() => {
    setTableData(
      Object.values(DelayVeryLowMessageEnum).map((threshold, index) => {
        const found = value.find((x) => x.threshold === threshold)
        return {
          uid: index,
          threshold,
          label: getLocalized(threshold),
          value: found?.value || '',
          rowConfig: { selected: !!found }
        }
      })
    )
  }, [value])

  const sortedTableData = useMemo(() => {
    return abcSort(tableData, (x) => x.label, orderType).map((item) =>
      getDataForTable(item)
    )
  }, [tableData, orderType, preSelectClick])

  const changeOrSelect = useMemo(
    () => value.filter(({ check }) => check).length > 0,
    [value]
  )

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized(
        changeOrSelect
          ? 'thresholds-modal-title-change'
          : 'thresholds-modal-title-select'
      )}
      className={'large-policy-modal'}
      data-testid={'id-delay-very-low-msg-modal'}
      footer={
        <FlexRowWithSpace className={'alignCenter fullWidth'}>
          <ButtonGroup>
            <Button id={'selectBtn'} onClick={handleSelect} disabled={disabled}>
              {getLocalized(changeOrSelect ? 'common.save' : 'common.select')}
            </Button>
            <Button id={'cancelBtn'} appearance={'secondary'} onClick={onClose}>
              {getLocalized('common.cancel')}
            </Button>
          </ButtonGroup>
          <p>
            {getLocalized('common.n-items-selected', { count: selectedCount })}
          </p>
        </FlexRowWithSpace>
      }
    >
      <p className={'paddingBottom16'}>
        {getLocalized('thresholds-modal-description')}
      </p>
      <Table
        columns={[
          {
            id: 'uid',
            label: 'id',
            index: 'hidden'
          },
          {
            id: labelColumnId,
            label: getLocalized('thresholds-label-column'),
            sortable: true
          },
          {
            id: valueColumnId,
            label: getLocalized('thresholds-value-column'),
            sortable: false
          }
        ]}
        data={sortedTableData}
        onSort={(_, { type }) => setOrderType(type)}
        onSelect={handleRowSelect}
        onSelectAllPageItems={handleSelectAllPageItems}
        rowSelector={'multiSelection'}
        rowSelectAllState={rowSelectAllState}
        preferences={{
          sortBy: { id: labelColumnId, type: orderType },
          width: [
            { columnId: labelColumnId, width: 360 },
            { columnId: valueColumnId, width: 210 }
          ]
        }}
        className={'widthColAuto paddingThirdCol4'}
        data-testid={'id-delay-very-low-msg-modal-table'}
        i18n={Retrievei18nItems()}
      />
    </Modal>
  )
}
export default DelayVeryLowMessageModal

import React, { useContext, useMemo, useState } from 'react'
import { Accordion } from '@veneer/core'
import PolicyAttribute from 'components/policies/settings/attributes'
import Help from 'components/policies/modal/help'
import PolicyHeaderRight from 'components/policies/settings/attributes/header/right'
import PolicyHeaderContent from 'components/policies/settings/attributes/header/content'
import { mergeAppDeploymentStatus } from 'components/policies/settings/attributes/device/controls/AppDeployment/AppDetailsModal'
import configContext from 'context/config/configContext'
import {
  getComplianceReason,
  PolicyAttributeComplianceEnum,
  PolicyAttributeComplianceReasonEnum
} from 'components/policies/constants'
import { Attribute } from 'common/model/api/Policy'
import { AttributeCompliance } from 'common/model/api/Compliance'
import { isAdvanced } from 'common/utilities'
import PoliciesConfiguration from 'context/policies/PoliciesConfiguration'
import { PolicyAttributeDescriptionEx } from 'context/policies/PoliciesConfigurationEnum'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import SettingsProvider from 'context/settings/SettingsProvider'
import 'styles/global.scss'

const DeviceCompliancePreview = (props) => {
  const { t } = useContext(configContext)
  const { attributes, name, serialNumber } = props
  const key = (key: string, args?: Record<string, string>): string => {
    return t(`policy.settings.notification.${key}`, args)
  }

  const [helpText, setHelpText] = useState(null)

  const getDeviceSettings = (
    description,
    attribute: AttributeCompliance & Attribute
  ) => {
    // Display those deviceSettings which present in the compliance result
    description.deviceSettings = description.deviceSettings.filter((x) =>
      attribute.settings.some((y) => y.name.startsWith(x.attribute))
    )
    // Show expected values (from policies)
    attribute.deviceSettings = attribute.settings.map((x) => {
      return {
        name: x.name,
        value:
          x.name === `${PolicyAttributeEnum.App_Deployment}.apps` &&
          getComplianceReason(x.status, x.reason) ===
            PolicyAttributeComplianceReasonEnum.UNMATCHED
            ? mergeAppDeploymentStatus(
                x,
                serialNumber,
                attribute.metadata.remediation
              )
            : x.expected
      }
    })
  }

  const getNotifications = (
    attribute: AttributeCompliance & Attribute,
    key
  ) => {
    const notifications = []
    switch (attribute.status) {
      case PolicyAttributeComplianceEnum.NONCOMPLIANT: {
        // Need to analyze reasons
        const reasons = []
        attribute.settings.forEach((x, i) => {
          const status = getComplianceReason(x.status, x.reason)
          if (status) {
            const compliance = key(`noncompliant.${status}`, {
              policyName: name || key('device-policy')
            })
            if (status !== PolicyAttributeComplianceReasonEnum.UNENTITLED) {
              attribute.deviceSettings[i].compliance = compliance
            }
            if (!reasons.includes(compliance)) {
              reasons.push(compliance)
            }
          }
        })
        notifications.push({
          type: 'negative',
          title: key('noncompliant.title'),
          description: reasons.join('\n')
        })
        break
      }

      case PolicyAttributeComplianceEnum.OVERRIDDEN:
        notifications.push({
          type: 'warning',
          title: key('overridden.title'),
          description: attribute.overriddenBy?.name
            ? key('overridden.global', {
                policyName: attribute.overriddenBy.name
              })
            : key('overridden.device')
        })
        break

      case PolicyAttributeComplianceEnum.UNKNOWN:
        notifications.push({
          type: 'informative',
          title: key('unknown.title'),
          description: key('unknown.description')
        })
        break

      case PolicyAttributeComplianceEnum.COMPLIANT:
      default:
        break
    }
    return notifications
  }

  const createAccordionItem = (
    attribute,
    description,
    notifications,
    isAdvanced,
    setHelpText
  ) => {
    return {
      id: attribute.name,
      expanded: false,
      content: (
        <PolicyAttribute
          item={attribute}
          description={description}
          preview={true}
          notifications={notifications}
        />
      ),
      header: {
        centralArea: (
          <PolicyHeaderContent
            description={description}
            advanced={isAdvanced(attribute.metadata.entitlements)}
            setHelpText={(data) => setHelpText(data)}
          />
        ),
        endArea: (
          <PolicyHeaderRight
            description={description}
            status={attribute.status}
          />
        )
      }
    }
  }

  const accordionItems = useMemo(() => {
    const items = []
    for (const attribute of PoliciesConfiguration.sortPolicyAttributes(
      attributes,
      t
    )) {
      const description: PolicyAttributeDescriptionEx =
        PoliciesConfiguration.getPolicyAttributeDescription(attribute.name)

      getDeviceSettings(description, attribute)
      const notifications = getNotifications(attribute, key)
      const item = createAccordionItem(
        attribute,
        description,
        notifications,
        isAdvanced,
        setHelpText
      )

      items.push(item)
    }
    return items
  }, [attributes])

  return (
    <SettingsProvider>
      <Accordion
        className={'rightPaddingAccordion'}
        items={accordionItems}
        behavior={'singleExpand'}
        id={'compliance-accordion-settings'}
      />
      <Help {...helpText} onClose={() => setHelpText(null)} />
    </SettingsProvider>
  )
}

export default DeviceCompliancePreview

import Policy, { Attribute, DeviceSettings, MetaData } from './model/api/Policy'

function areAttributesEqual(
  selectedAttributes: Attribute[],
  clonedAttributes: Attribute[]
): boolean {
  if (selectedAttributes.length !== clonedAttributes.length) {
    return false
  }

  for (const policyAttribute of clonedAttributes) {
    const selectAttribute: Attribute = selectedAttributes.find(
      (attribute) => attribute.name === policyAttribute.name
    )
    if (!selectAttribute) {
      return false
    }

    if (!isMetadataEqual(policyAttribute.metadata, selectAttribute.metadata)) {
      return false
    }

    if (
      !areDeviceSettingsEqual(
        policyAttribute.deviceSettings,
        selectAttribute.deviceSettings
      )
    ) {
      return false
    }
  }

  return true
}

function isMetadataEqual(
  policyMetadata: MetaData,
  selectMetadata: MetaData
): boolean {
  return (
    policyMetadata.severity === selectMetadata.severity &&
    policyMetadata.remediation === selectMetadata.remediation &&
    policyMetadata.unsupported === selectMetadata.unsupported &&
    policyMetadata.unentitled === selectMetadata.unentitled
  )
}

function areDeviceSettingsEqual(
  policyDeviceSettings: DeviceSettings[],
  selectDeviceSettings: DeviceSettings[]
): boolean {
  if (policyDeviceSettings.length !== selectDeviceSettings.length) {
    return false
  }

  for (const policyDeviceSetting of policyDeviceSettings) {
    const cloneDeviceSetting: DeviceSettings = selectDeviceSettings.find(
      (setting) => setting.name === policyDeviceSetting.name
    )
    if (
      !cloneDeviceSetting ||
      policyDeviceSetting.value !== cloneDeviceSetting.value
    ) {
      return false
    }
  }

  return true
}

/**
 * Compare 2 policies and return false in case of any difference (even if items aren't presented in one of collections),
 * otherwise - true
 *
 * @param selectedPolicy specifies first policy for checking for difference
 * @param clonedPolicy specifies second policy for checking for difference
 */
export function equalPolicies(
  selectedPolicy: Policy,
  clonedPolicy: Policy
): boolean {
  if (!selectedPolicy) {
    return true
  }

  if (
    selectedPolicy.name !== clonedPolicy.name ||
    selectedPolicy.description !== clonedPolicy.description
  ) {
    return false
  }

  return areAttributesEqual(selectedPolicy.attributes, clonedPolicy.attributes)
}

import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  Accordion,
  Button,
  ButtonGroup,
  Modal,
  Card,
  Scrollbar
} from '@veneer/core'
import configContext from 'context/config/configContext'
import policiesContext from 'context/policies/policiesContext'
import solutionsContext from 'context/solutions/solutionsContext'
import '../index.scss'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'
import MocParser from 'common/mocParser/MocParser'
import MocUtils, { MOC } from 'common/mocParser/MocUtils'
import { AccordionContent, FlexRow } from 'styles/styles'
import AppDeploymentCheck from './AppDeploymentCheck'
import AppDeploymentText from './AppDeploymentText'
import AppDeploymentList from './AppDeploymentList'
import AppDeploymentmentFilelist from './AppDeploymentFilelist'
import { AppDeploymentEnum } from './SelectAppModal'
import { jsonParse } from 'common/utilities'

enum AppCfgExtraEnum {
  ID = 'id',
  DATE = 'date',
  SCHEMA_VERSION = 'schemaVersion'
}

const base64ToText = (base64) => {
  const binString = Buffer.from(base64, 'base64').toString('binary')
  const len = binString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binString.codePointAt(i)
  }
  return new TextDecoder().decode(bytes)
}
const textToBase64 = (text) => {
  const bytes = new TextEncoder().encode(text)
  let binaryString = ''
  for (let i = 0; i < bytes.length; i++) {
    binaryString += String.fromCharCode(bytes[i])
  }
  return Buffer.from(binaryString, 'binary').toString('base64')
}

const AppDeploymentModal = (props) => {
  const { language, country, tt } = useContext(configContext)
  const {
    onClose,
    localizationPath,
    item,
    config,
    configId,
    onChange,
    preview,
    tableHeader
  } = props

  const { moc, getMoc } = useContext(solutionsContext)
  const { fleetMgtSvcClient, showError } = useContext(policiesContext)
  useEffect(() => {
    if (item) {
      getMoc(item, (error) => showError(error))
    }
    if (config) {
      setValue(jsonParse(config, {}))
    } else if (configId) {
      fleetMgtSvcClient
        .downloadFile(configId)
        .then((res) => {
          if (res?.status === 200) {
            setValue(jsonParse(base64ToText(res.data.contents), {}))
          }
        })
        .catch((error) => showError(error))
    }
  }, [])

  const mocParser = useMemo(() => (moc ? new MocParser(moc) : null), [moc])

  const frames = useMemo(
    () => mocParser?.getFrames(language + '_' + country) || [],
    [mocParser]
  )

  const [value, setValue] = useState({})
  const [configState, setConfigState] = useState({})
  useEffect(() => {
    if (mocParser && frames) {
      setConfigState(mocParser.getConfig())
    }
  }, [mocParser, frames])

  const resetDisabled = useMemo(
    () => mocParser?.isDefault(),
    [mocParser, configState]
  )

  const toLeanCfg = (cfg) => {
    const reducer = (acc, frame) => [...acc, ...frame[MOC.FIDGETS]]
    return {
      [MOC.FIDGETS]:
        cfg && Array.isArray(cfg[MOC.FRAMES])
          ? cfg[MOC.FRAMES].reduce(reducer, [])
          : []
    }
  }

  const fromLeanCfg = (leanCfg) => {
    const reducer = (acc, frame) => [
      ...acc,
      {
        [MOC.ID]: frame[MOC.ID],
        [MOC.FIDGETS]: frame[MOC.FIDGETS].map((fidget) =>
          leanCfg[MOC.FIDGETS].find((x) => x[MOC.ID] === fidget[MOC.ID])
        )
      }
    ]
    return {
      [MOC.FRAMES]: frames.reduce(reducer, []),
      // Extra info
      [AppCfgExtraEnum.ID]: item[AppDeploymentEnum.UUID],
      [AppCfgExtraEnum.DATE]: new Date().valueOf().toString(),
      [AppCfgExtraEnum.SCHEMA_VERSION]: '0.1'
    }
  }

  const leanConfigValue = useMemo(
    () => JSON.stringify(toLeanCfg(value)[MOC.FIDGETS]),
    [value]
  )
  const saveDisabled = useMemo(
    () =>
      JSON.stringify(mocParser?.getLeanConfig()[MOC.FIDGETS]) ===
      leanConfigValue,
    [configState, leanConfigValue]
  )
  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (mocParser && value) {
      setConfigState((oldState) => {
        const newCfg = { ...oldState }
        MocUtils.mergeObjects(newCfg, mocParser.setLeanConfig(toLeanCfg(value)))
        return newCfg
      })
    }
  }, [mocParser, value])

  const retrieveHelperText = (fId, sId) => {
    return configState?.[fId]?.[sId]?.[MOC.ISSUES]?.join(', ') || ''
  }

  const retrieveErrors = (extractedState = configState) => {
    let errorValue
    frames.map((frame) => {
      const fidgets = frame[MOC.FIDGETS]
      fidgets.map((fidget) => {
        const fId = fidget[MOC.ID]
        fidget[MOC.SETS]?.map((set) => {
          const sId = set[MOC.NAME]
          const sErrors = extractedState?.[fId]?.[sId]?.[MOC.ISSUES]?.length > 0
          if (sErrors) {
            errorValue = {
              ...errorValue,
              [fId + sId]: sErrors
            }
          }
        })
      })
    })
    return errorValue
  }

  const onSave = async () => {
    const errorValue = retrieveErrors()
    setErrors(errorValue)
    if (!mocParser?.hasIssues()) {
      const cfg = fromLeanCfg(mocParser?.getLeanConfig())
      if (cfg) {
        const text = JSON.stringify(cfg)
        let res = null
        if (item[AppDeploymentEnum.NAME]) {
          try {
            res = await fleetMgtSvcClient.uploadFile(
              item[AppDeploymentEnum.NAME],
              'APPCONFIG',
              textToBase64(text)
            )
          } catch (error) {
            showError(error)
          }
          if (res?.status === 200) {
            onChange(res.data?.id)
          }
        } else {
          onChange(text)
        }
      }
      onClose()
    }
  }

  const areErrorsPresent = (fId, sId) =>
    errors?.[fId + sId] && configState?.[fId]?.[sId]?.[MOC.ISSUES]?.length > 0

  const updateStateValue = (valueToSet) => {
    const extractedState = { ...configState }
    const updatedConfig = mocParser.setConfig(valueToSet)
    MocUtils.mergeObjects(extractedState, updatedConfig)
    MocUtils.mergeObjects(extractedState, valueToSet)
    setConfigState(extractedState)
    const errorValue = retrieveErrors(extractedState)
    setErrors(errorValue)
  }

  const isVisible = (fId, sId) => {
    return configState?.[fId]?.[sId]?.[MOC.VISIBLE]
  }

  const componentValue = (fId, sId) => {
    return configState?.[fId]?.[sId]?.[MOC.VALUES]?.[0]?.[sId]
  }

  const retrieveElement = (set, fId) => {
    const sId = set[MOC.NAME]
    if (!isVisible(fId, sId)) {
      return <></>
    }
    const disabled = !configState?.[fId]?.[sId]?.[MOC.EDITABLE]
    const error = areErrorsPresent(fId, sId)
    const helperText = error ? retrieveHelperText(fId, sId) : ''
    const required = !preview && set[MOC.REQUIRED]
    switch (set[MOC.TYPE]) {
      case MOC.TYPES.NUMBER:
      case MOC.TYPES.INPUT:
      case MOC.TYPES.PASSWORD:
        return (
          <AppDeploymentText
            fId={fId}
            sId={sId}
            set={set}
            error={error}
            preview={preview}
            disabled={disabled}
            helperText={helperText}
            required={required}
            componentValue={componentValue}
            updateStateValue={updateStateValue}
          />
        )
      case MOC.TYPES.CHECK:
        return (
          <AppDeploymentCheck
            fId={fId}
            sId={sId}
            set={set}
            error={error}
            preview={preview}
            disabled={disabled}
            helperText={helperText}
            componentValue={componentValue}
            updateStateValue={updateStateValue}
            localizationPath={localizationPath}
          />
        )
      case MOC.TYPES.LIST:
        return (
          <AppDeploymentList
            fId={fId}
            sId={sId}
            set={set}
            error={error}
            preview={preview}
            disabled={disabled}
            helperText={helperText}
            configState={configState}
            required={required}
            localizationPath={localizationPath}
            updateStateValue={updateStateValue}
          />
        )
      case MOC.TYPES.FILELIST:
        return (
          <AppDeploymentmentFilelist
            localizationPath={localizationPath}
            configState={configState}
            updateStateValue={updateStateValue}
            fId={fId}
            sId={sId}
            set={set}
            preview={preview}
            disabled={disabled}
            error={error}
            helperText={helperText}
          />
        )
    }
  }

  const onRender = (fidgets) => {
    return fidgets.map((fidget, index) => {
      const fId = fidget[MOC.ID]
      return (
        <div
          key={fId}
          className={
            index === 0 ||
            (configState?.[fId]?.[fId] &&
              !configState?.[fId]?.[fId]?.[MOC.VISIBLE])
              ? ''
              : 'paddingTop16'
          }
        >
          {fidget[MOC.SETS].map((set, setIndex) => {
            const sId = set[MOC.NAME]
            return (
              <div
                className={
                  setIndex === 0 || !configState?.[fId]?.[sId]?.[MOC.VISIBLE]
                    ? ''
                    : 'paddingTop8'
                }
                key={set[MOC.NAME]}
              >
                {retrieveElement(set, fId)}
              </div>
            )
          })}
        </div>
      )
    })
  }

  const accordionItems = useMemo(() => {
    const items = []
    for (const frame of frames) {
      const frameId = frame[MOC.ID]
      items.push({
        id: frameId,
        expanded: false,
        content: (
          <AccordionContent>{onRender(frame[MOC.FIDGETS])}</AccordionContent>
        ),
        header: {
          centralArea: <FlexRow>{frame[MOC.LABEL]}</FlexRow>
        }
      })
    }
    return items
  }, [configState, frames, errors])

  const resetHandler = () => {
    const defaultState = mocParser.setDefaults()
    const extractedState = { ...configState }
    MocUtils.mergeObjects(extractedState, defaultState)
    setConfigState(extractedState)
    return true
  }

  return (
    <>
      <Modal
        closeButton={preview}
        closeOnBlur={false}
        show={true}
        className={'large-policy-modal'}
        title={getLocalized(
          preview
            ? 'view-app-config'
            : config || configId
            ? 'edit-app-config'
            : 'set-app-config'
        )}
        data-testid={'id-edit-app-config-modal'}
        onClose={onClose}
        footer={
          !preview && (
            <ButtonGroup>
              <Button onClick={onSave} disabled={saveDisabled}>
                {getLocalized('common.save')}
              </Button>
              <Button appearance={'secondary'} onClick={onClose}>
                {getLocalized('common.cancel')}
              </Button>
            </ButtonGroup>
          )
        }
      >
        {!preview && (
          <FlexRow className={'paddingBottom16'}>
            {getLocalized('description-message')}
          </FlexRow>
        )}
        <Card
          border={'outlined'}
          content={
            <>
              <div className={'appConfigTitle'}>{tableHeader}</div>
              <Scrollbar customStyle={{ height: '488px', marginBottom: '8px' }}>
                <Accordion
                  className={'rightPaddingAccordion'}
                  items={accordionItems}
                  behavior={'singleExpand'}
                />
              </Scrollbar>
            </>
          }
        />
        {!preview && (
          <FlexRow className={'marginTop12'}>
            <Button
              disabled={resetDisabled}
              onClick={resetHandler}
              appearance={'ghost'}
            >
              {getLocalized('common.reset')}
            </Button>
          </FlexRow>
        )}
      </Modal>
    </>
  )
}
export default AppDeploymentModal

import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from '../PoliciesConfigurationEnum'

const ldapSetupCredentials = (val) => {
  return {
    value: `${PolicyAttributeEnum.Ldap_Setup}.credential.` + val,
    label: val
  }
}

const ldapABCredentials = (val) => {
  return {
    value: `${PolicyAttributeEnum.Ldap_AB_Access}.credential.` + val,
    label: 'credential-' + val
  }
}

const ldapSources = (val) => {
  return {
    value: `${PolicyAttributeEnum.Ldap_AB_Access}.name-source.` + val,
    label: 'source-' + val
  }
}

export default class LdapItems {
  static ldapSetupCredentialModes: Item[] = [
    ldapSetupCredentials('device-user'),
    ldapSetupCredentials('admin')
  ]

  static ldapABCredentialModes: Item[] = [
    ldapABCredentials('none'),
    ldapABCredentials('device-user'),
    ldapABCredentials('simple'),
    ldapABCredentials('windows')
  ]

  static ldapSourceAttributes: Item[] = [
    ldapSources('default'),
    ldapSources('exchange'),
    ldapSources('custom')
  ]
}

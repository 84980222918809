import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Table, Button } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { TrayAdministrationDescription } from 'context/policies/PoliciesConfigurationEnum'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import MediaTrayItems from 'context/policies/dropboxItems/MediaTrayItems'
import PrintMediaItems from 'context/policies/dropboxItems/PrintMediaItems'
import { FlexColumn, FlexRow, TextBlack, PreviewLabel } from 'styles/styles'
import TrayAdministrationModal from './TrayAdministrationModal'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import WarningMessage from '../WarningMessage'
import { TABLE_CONSTANTS } from 'common/utilities'
import 'styles/global.scss'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const columnId = 'id'
const columnTray = 'tray'
const columnSize = 'size'
const columnType = 'type'

const tablePreferences = {
  width: [
    { columnId: columnTray, width: 100 },
    { columnId: columnSize, width: 200 },
    { columnId: columnType, width: 200 }
  ]
}

const getTrayProps = (x) => {
  const i = x.lastIndexOf('.') // suffix of value is "tray"
  const tray = x.slice(i > 0 ? i + 1 : 0)
  return { size: tray + '.size', type: tray + '.type' }
}

const TrayAdministrationControl = (props) => {
  const description: TrayAdministrationDescription = props.description
  const {
    data: { deviceSettings },
    onAttributeChange,
    id
  } = props
  const { attribute } = description
  const { tt } = useContext(configContext)
  const { isEnabled, addError, removeError, displayAllErrors } =
    useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const [tableData, setTableData] = useState([])

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  const compliance = useMemo(
    () => PoliciesHelper.getCompliance(description, deviceSettings),
    [deviceSettings]
  )

  const getTableData = (val) => {
    const trays = []
    const cell = (text, status) => (
      <FlexColumn>
        {text}
        {status && <PreviewItem compliance={status} />}
      </FlexColumn>
    )
    MediaTrayItems.trayAdminTrays.forEach((x) => {
      const { size, type } = getTrayProps(x.value)
      const vSize = val[size]
      const vType = val[type]
      if (vSize && vType) {
        const fSize = PrintMediaItems.mediaSizes().find(
          ({ value }) => value === vSize
        )
        const fType = PrintMediaItems.mediaTypes().find(
          ({ value }) => value === vType
        )
        trays.push({
          id: x.value,
          tray: getLocalized(x.label),
          size: cell(
            fSize ? getLocalized(fSize.label) : TABLE_CONSTANTS.NO_DATA,
            compliance[size]
          ),
          type: cell(
            fType ? getLocalized(fType.label) : TABLE_CONSTANTS.NO_DATA,
            compliance[type]
          ),
          rowConfig: { value: { tray: x.value, size: vSize, type: vType } }
        })
      }
    })
    return trays
  }

  useEffect(() => setTableData(getTableData(value)), [value])

  const enabled = isEnabled(attribute)
  const showError = displayError || displayAllErrors
  const errorState = enabled && !tableData.length
  useEffect(() => {
    setError(showError && errorState)
    errorState ? addError(id, attribute, showError) : removeError(id, attribute)
  }, [errorState, showError])

  const setData = (arrayTrays) => {
    setDisplayError(true)
    const newValue = {}
    arrayTrays.forEach((x) => {
      if (x.tray && x.size && x.type) {
        const { size, type } = getTrayProps(x.tray)
        newValue[size] = x.size
        newValue[type] = x.type
      }
    })
    setTableData(getTableData(newValue))
    setValue(newValue)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: columnId, label: columnId, index },
      { id: columnTray, label: getLocalized('trays-table-column-tray') },
      { id: columnSize, label: getLocalized('trays-table-column-media-size') },
      { id: columnType, label: getLocalized('trays-table-column-media-type') }
    ]
  }, [])

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack
        className={'marginBottom12'}
        disabled={!enabled}
        required={true}
      >
        {text}
      </TextBlack>
    )

  return (
    <>
      <FlexColumn>
        {tableHeader(getLocalized('label'))}
        {onAttributeChange && (
          <FlexRow className={'marginBottom12 alignCenter'}>
            <Button
              appearance={'secondary'}
              disabled={!enabled}
              onClick={() => setShowModal(true)}
              id={'setTrayAdmin'}
            >
              {getLocalized(tableData.length ? 'change-trays' : 'select-trays')}
            </Button>
            <TextBlack className={'marginLeft12'} disabled={!enabled}>
              {getLocalized('n-trays-selected', {
                count: tableData.length
              })}
            </TextBlack>
          </FlexRow>
        )}
        <Table
          columns={tableColumns}
          data={tableData}
          className={'widthColAuto'}
          data-testid={'id-tray-admin-table'}
          preferences={tablePreferences}
          i18n={Retrievei18nItems()}
        />
        {error && (
          <WarningMessage
            id={description.attribute + '.error'}
            message={getLocalized('trays-not-selected-error')}
            type={MessageTypesEnum.ERROR}
          />
        )}
      </FlexColumn>
      {showModal && (
        <TrayAdministrationModal
          getLocalized={getLocalized}
          description={description}
          value={tableData.map((x) => x.rowConfig.value)}
          onChange={setData}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

export default memo(TrayAdministrationControl)

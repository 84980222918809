import { Item } from '../PoliciesConfigurationEnum'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

const minuteItem = (key: string, value: string) => ({
  label: 'policy.settings.time-labels.' + key,
  value
})

const minuteItemOpt = (key: string, option: string) => ({
  ...minuteItem(key, option),
  option
})

export default class MinuteItems {
  static allMinuteItems: Item[] = [
    minuteItem('no-time-out', '0'), // 0
    minuteItem('1-min', '1'), // 1
    minuteItem('5-min', '5'), // 2
    minuteItem('10-min', '10'), // 3
    minuteItem('15-min', '15'), // 4
    minuteItem('20-min', '20'), // 5
    minuteItem('30-min', '30'), // 6
    minuteItem('45-min', '45'), // 7
    minuteItemOpt('1-hr', '60'), // 8
    minuteItemOpt('2-hr', '120'), // 9
    minuteItemOpt('3-hr', '180'), // 10
    minuteItemOpt('4-hr', '240'), // 11
    minuteItemOpt('5-hr', '300'), // 12
    minuteItemOpt('6-hr', '360'), // 13
    minuteItemOpt('7-hr', '420'), // 14
    minuteItemOpt('8-hr', '480'), // 15
    minuteItem('1-day', '1440'), // 16
    minuteItem('1-week', '10080'), // 17
    minuteItem('4-week', '40320'), // 18
    minuteItem('forever', 'Forever'), // 19
    minuteItem('never', '0') // 20
  ]

  static pick = (arr: number[]) =>
    arr.map((index) => MinuteItems.allMinuteItems[index])

  static sleepInactivityItems: Item[] = MinuteItems.pick([1, 2, 4, 6, 8])
  static shutdownInactivityItems = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? MinuteItems.pick([9, 11, 15])
      : constraints.filterSettingOptions(
          MinuteItems.allMinuteItems,
          ConstraintsResourceEnum.powerCfg,
          ConstraintsSettingEnum.shutdownTimeoutInMinutes
        )

  static jobRetentionPeriods: Item[] = MinuteItems.pick([
    6, 8, 11, 16, 17, 18, 20
  ])
  static inputAutoContinueTimeoutCodes: Item[] = MinuteItems.pick([
    0, 2, 3, 5, 6, 7, 8, 19
  ])
}
